import generalModalStyles from '../../../styles/GeneralModal.module.css'
import generalStyles from '../../../styles/General.module.css'
import styles from '../NewPopup.module.css'
import { motion, AnimatePresence } from "framer-motion"
import { useState, useRef, useEffect } from 'react'
import UsersSection from '../UsersSelector/UsersSection'
import strings from '../../../assets/strings'
import moment from 'moment'
import SelectedUsersRow from '../UsersSelector/SelectedUsersRow'
import SmallFilterPopups from '../Filters/SmallFilterPopups'
import { useSelector } from 'react-redux'
import { filterAction, isFilterActive } from '../../../constants/Utils'
import { COLORS } from '../../../constants/constants'


const EditEventModal = (props) => {

    const users = useSelector((state) => state.users.users.filter(u => u.approved === true))
    const [filterPopupVisible, setFilterPopupVisible] = useState(false)
    const [activeFiltersCounter, setActiveFiltersCounter] = useState(0)
    const [presentedUsers, setPresentedUsers] = useState([])
    const [event, setEvent] = useState({ eventKey: null,
                                         eventTitle: null, 
                                         eventDescriptions: null, 
                                         eventDate: null, 
                                         eventTime: null,
                                         eventDirection: null, 
                                         eventParticipants: [{ name: '', phone: '' }] })
    const [eventTitleError, setEventTitleError] = useState(false)
    const eventTitleInputRef = useRef()
    const eventTextInputRef = useRef()
    const eventDateRef = useRef()
    const eventTimeRef = useRef()
    const eventDirectionRef = useRef()


    useEffect(() => {
        setPresentedUsers(users)
    }, [])


    const getEventTitle = () => {
        if (event.eventTitle !== null){
            return event.eventTitle
        }
        if (props.event.eventTitle !== null){
            return props.event.eventTitle
        }
        return ''
    }


    const setEventTitle = () => {
        setEventTitleError(false)
        const eventTitle = eventTitleInputRef.current.value
        setEvent({...event, eventTitle: eventTitle })
    }


    const getEventDescriptions = () => {
        if (event.eventDescriptions !== null){
            return event.eventDescriptions
        }
        if (props.event.eventDescriptions !== null){
            return props.event.eventDescriptions
        }
        return ''
    }


    const setEventDescriptions = () => {
        const eventDescriptions = eventTextInputRef.current.value
        setEvent({...event, eventDescriptions: eventDescriptions })
    }


    const setEventTime = () => {
        const time = eventTimeRef.current.value
        const tempDate = moment(time, 'HH:mm').format('HH:mm')
        setEvent({...event, eventTime: tempDate})
    }


    const getEventTimeValue = () => {
        if (event.eventTime !== null){
            const temp = moment(event.eventTime, "HH:mm")
            return temp.format("HH:mm")
        }
        if (props.event.eventDate !== null){
            const temp = moment(props.event.eventTime, "HH:mm")
            return temp.format("HH:mm")
        }
        return ''
    }


    const setEventDate = () => {
        const date = eventDateRef.current.value
        const tempDate = moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')
        setEvent({...event, eventDate: tempDate})
    }


    const getEventDateValue = () => {
        if (event.eventDate !== null){
            const temp = moment(event.eventDate, "DD/MM/YYYY")
            return temp.format("YYYY-MM-DD")
        }
        if (props.event.eventDate !== null){
            const temp = moment(props.event.eventDate, "DD/MM/YYYY")
            return temp.format("YYYY-MM-DD")
        }
        return ''
    }


    const filterActionHandler = (isLonely, isCombat, isOfficer, isStudy, isOther, birthdayFromYear, birthdayFromMonth, birthdayToYear, birthdayToMonth, dischargedFromYear, dischargedFromMonth, dischargedToYear, dischargedToMonth, currentStatus, selectedKeywords) => {
        var tempList = filterAction(users, null, null, isLonely, isCombat, isOfficer, isStudy, isOther, birthdayFromYear, birthdayFromMonth, birthdayToYear, birthdayToMonth, dischargedFromYear, dischargedFromMonth, dischargedToYear, dischargedToMonth, currentStatus, selectedKeywords)
        var tempActiveFiltersCounter = isFilterActive(users, null, isLonely, isCombat, isOfficer, isStudy, isOther, birthdayFromYear, birthdayFromMonth, birthdayToYear, birthdayToMonth, dischargedFromYear, dischargedFromMonth, dischargedToYear, dischargedToMonth, currentStatus, selectedKeywords)
        setActiveFiltersCounter(tempActiveFiltersCounter)
        setPresentedUsers(tempList)
    }


    const setupEventDirection = () => {
        const direction = eventDirectionRef.current.value
        setEvent({...event, eventDirection: direction})
    }


    const getEventDirectionValue = () => {
        if (event.eventDirection !== null){
            return event.eventDirection
        }
        if (props.event.eventDirection !== null){
            return props.event.eventDirection
        }
        return ''
    }


    const filterSectionActionHandler = (value) => {
        setFilterPopupVisible(value)
    }


    const renderShareButton = () => {
        if (event.eventParticipants != undefined){
            if (event.eventParticipants.length > 1){
                return <>{strings.NEW_EVENT_INPUT_SHARE_TO_BUTTON} {event.eventParticipants.length - 1}</>
            }
        }
        if (props.event.eventParticipants != undefined){
            if (props.event.eventParticipants !== null){
                if (props.event.eventParticipants.length > 0){
                    return <>{strings.NEW_EVENT_INPUT_SHARE_TO_BUTTON} {props.event.eventParticipants.length}</>
                }
            }
        }
        return <>{strings.NEW_EVENT_INPUT_SHARE_BUTTON}</>
    }


    const getDefaultSelectedUsers = () => {
        if (props.event.eventParticipants != undefined && event.eventParticipants != undefined){
            let tempExists = props.event.eventParticipants.map((user) => {
                return {name: user.name, phone: user.phone}
            })
            let tempNewAdded = event.eventParticipants.map((user) => {
                return {name: user.name, phone: user.phone}
            })
            return [...tempExists, ...tempNewAdded]

        }else if (props.event.eventParticipants != undefined){
            let tempExists = props.event.eventParticipants.map((user) => {
                return {name: user.name, phone: user.phone}
            })
            return [...tempExists]

        }else if (event.eventParticipants != undefined){
            let tempNewAdded = event.eventParticipants.map((user) => {
                return {name: user.name, phone: user.phone}
            })
            return [...tempNewAdded]
        }
        return []
    }


    const setEventParticipants = (users) => {
        let temp = users.map((user) => {
            return {name: user.name, phone: user.phone}
        })
        setEvent({...event, eventParticipants: temp })
    }


    const getReturnData = () => {
        if (getEventTitle() === ''){
            setEventTitleError(true)
            return
        }

        const temp = moment(getEventDateValue(), "YYYY-MM-DD").format("DD/MM/YYYY")
        const event = { eventKey: props.event.eventKey,
                        eventTitle: getEventTitle(), 
                        eventDescriptions: getEventDescriptions(), 
                        eventDate: temp, 
                        eventTime: getEventTimeValue(),
                        eventDirection: getEventDirectionValue(),
                        eventParticipants: getDefaultSelectedUsers()}
        props.doneAction(event)
    }


    return(
        <AnimatePresence>
            {props.isOpen && (
              <>
                  <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: 0.3 } }} exit={{ opacity: 0 }} onClick={() => props.closeEvent()} className={generalModalStyles.backViewStyle} />
                  <motion.div initial={{ scale: 0 }} animate={{ scale: 1, transition: { delay: 0.3, duration: 0.3 } }} exit={{ scale: 0 }} className={styles.backViewWrapperStyle}>
                    <motion.div className={styles.mainBackViewStyle}>

                            <div className={generalModalStyles.dataBackViewStyle} style={{width: filterPopupVisible ? '40%'  : '60%'}}>

                                <div className={generalModalStyles.headerBackViewStyle}>
                                    <text className={generalModalStyles.headerTextStyle}>{strings.EDIT_EVENT_POPUP_TITLE}</text>
                                </div>

                              <div className={styles.mainViewStyle}>
                                <div style={{height: '20%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                    <select value={getEventDirectionValue()} className={styles.eventDatePickerStyle} onChange={() => setupEventDirection()} ref={eventDirectionRef} placeholder={strings.NEW_EVENT_DIRECTION_TEXT}>
                                        <option value={15}>15 {strings.NEW_EVENT_DIRECTION_MIN_TEXT}</option>
                                        <option value={30}> 30 {strings.NEW_EVENT_DIRECTION_MIN_TEXT}</option>
                                        <option value={45}>45 {strings.NEW_EVENT_DIRECTION_MIN_TEXT}</option>
                                        <option value={60}>1 {strings.NEW_EVENT_DIRECTION_HOUR_TEXT}</option>
                                        <option value={90}>1.5 {strings.NEW_EVENT_DIRECTION_HOUR_TEXT}</option>
                                        <option value={120}>2 {strings.NEW_EVENT_DIRECTION_HOUR_TEXT}</option>
                                    </select>
                                    <text className={`${generalStyles.text16} ${styles.eventTextDatePickerStyle}`}>{strings.NEW_EVENT_DIRECTION_TEXT}</text>

                                    <input className={styles.eventDatePickerStyle} value={getEventDateValue()} onChange={() => setEventDate()} ref={eventDateRef} type='date' required/>
                                    <text className={`${generalStyles.text16} ${styles.eventTextDatePickerStyle}`}>{strings.NEW_EVENT_DATE_TEXT}</text>

                                    <input className={styles.eventDatePickerStyle} value={getEventTimeValue()} onChange={() => setEventTime()} ref={eventTimeRef} type='time' required/>
                                    <text className={`${generalStyles.text16} ${styles.eventTextDatePickerStyle}`}>{strings.NEW_EVENT_TIME_TEXT}</text>
                              </div>
  
                                <input style={eventTitleError ? { backgroundColor: COLORS.RED20 } : { backgroundColor: COLORS.WHITE }} placeholder={strings.NEW_EVENT_INPUT_TITLE} value={getEventTitle()} className={styles.leftSectionBackStyle_mainViewStyle_eventTitle} onChange={() => setEventTitle()} ref={eventTitleInputRef} type="text" id='search'/>
                                <textarea placeholder={strings.NEW_EVENT_INPUT_TEXT} value={getEventDescriptions()} className={styles.leftSectionBackStyle_mainViewStyle_edit_eventText} onChange={() => setEventDescriptions()} ref={eventTextInputRef} type="textarea" id='search'/>
                            </div>

                            <div className={styles.leftSectionBackStyle_selectedUsersStyle}>
                                <SelectedUsersRow 
                                    selectedUsers={getDefaultSelectedUsers()}
                                />
                            </div>

                            <div className={generalModalStyles.footerBackViewStyle}>
                                <button className={generalModalStyles.leftSectionBackStyle_footerViewStyle_buttonStyle} onClick={() => props.closeEvent()}>{strings.CLOSE_BUTTON}</button>
                                <button className={generalModalStyles.leftSectionBackStyle_footerViewStyle_buttonStyle} onClick={() => getReturnData()}>{renderShareButton()}</button>
                            </div>
                          </div>
  
                         <div style={{width: filterPopupVisible ? '40%'  : '40%', marginTop: 5 }}>
                            <UsersSection
                                users={presentedUsers}
                                activeFiltersCounter={activeFiltersCounter}
                                center={props.center}
                                defaultSelectedUsers={getDefaultSelectedUsers()}
                                filterAction={(value) => filterSectionActionHandler(value)}
                                userAdded={(users) => setEventParticipants(users)} />
                        </div>

                        {filterPopupVisible &&
                            <div style={{width: '20%', margin: 5 }}>
                                <SmallFilterPopups
                                    center={props.center}
                                    filterAction={(isLonely, isCombat, isOfficer, isStudy, isOther, birthdayFromYear, birthdayFromMonth, birthdayToYear, birthdayToMonth, dischargedFromYear, dischargedFromMonth, dischargedToYear, dischargedToMonth, currentStatus, selectedKeywords) => filterActionHandler(isLonely, isCombat, isOfficer, isStudy, isOther, birthdayFromYear, birthdayFromMonth, birthdayToYear, birthdayToMonth, dischargedFromYear, dischargedFromMonth, dischargedToYear, dischargedToMonth, currentStatus, selectedKeywords)}
                                />
                            </div>
                        }
  
                      </motion.div>
                  </motion.div>
              </>
            )}
        </AnimatePresence>
      )
}


export default EditEventModal