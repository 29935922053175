import generalModalStyles from '../../../styles/GeneralModal.module.css'
import generalStyles from '../../../styles/General.module.css'
import styles from '../Filters/FilterPopupsSuperAdmin.module.css'
import { motion, AnimatePresence } from "framer-motion"
import strings from '../../../assets/strings'
import { COLORS } from '../../../constants/constants'
import { useEffect, useRef, useState } from 'react'
import moment from 'moment'

const FilterPopupsSuperAdmin = (props) => {

    const birthdayYearRef = useRef()
    const dischargedYearRef = useRef()
    const currentStatusRef = useRef()
    const [presentation, setPresentation] = useState("ALL")
    const [isLonely, setIsLonely] = useState(false)
    const [isCombat, setIsCombat] = useState(false)
    const [isOfficer, setIsOfficer] = useState(false)
    const [isStudy, setIsStudy] = useState(false)
    const [isOther, setIsOther] = useState(false)
    const [birthdayYear, setBirthdayYear] = useState(null)
    const [dischargedYear, setDischargedYear] = useState(null)
    const [currentStatus, setCurrentStatus] = useState(null)


    useEffect(() => {
        props.filterAction(presentation, isLonely, isCombat, isOfficer, isStudy, isOther, birthdayYear, dischargedYear, currentStatus)
    },[presentation, isLonely, isCombat, isOfficer, isStudy, isOther, birthdayYear, dischargedYear, currentStatus])


    const cleanAllAction = () => {
        setPresentation("ALL")
        setIsLonely(false)
        setIsCombat(false)
        setIsOfficer(false)
        setIsStudy(false)
        setIsOther(false)
        setBirthdayYear(null)
        setDischargedYear(null)
        setCurrentStatus(null)
        props.cleanFiltersAction()
    }


    const getYears = () => {
        var tempYears = [""]
        for (var i = moment().year(); i > 1950; i--){
            tempYears.push(i)
        }
        return tempYears
    }


    const setBirthdayYearValue = () => {
        const birthdayDate = birthdayYearRef.current.value
        const year = moment(birthdayDate, 'YYYY-MM-DD').format('YYYY')
        setBirthdayYear(year)
    }


    const setDischargedYearValue = () => {
        const dischargedDate = dischargedYearRef.current.value
        const year = moment(dischargedDate, 'YYYY-MM-DD').format('YYYY')
        setDischargedYear(year)
    }


    const setCurrentStatusValue = () => {
        const currentStatus = currentStatusRef.current.value
        setCurrentStatus(currentStatus)
    }


    return(
      <AnimatePresence>
        {props.isOpen && (
            <>
                <motion.div initial={{ scale: 0 }} animate={{ scale: 1, transition: { delay: 0.1, duration: 0.2 } }} exit={{ scale: 0 }} className={styles.backViewWrapperStyle}>
                    <div className={generalModalStyles.headerBackViewStyle}>
                        <text className={generalModalStyles.headerTextStyle}>{strings.FILTER_TITLE}</text>
                    </div>

                    <div className={styles.mainBackViewStyle}>
                        <div style={{marginBottom: 5, flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
                            <text style={{margin: 5}} className={`${generalStyles.text16}`}>{strings.SUPER_ADMIN_FILTER_USERS_TOTAL_APP_USERS}</text>
                            <input style={{margin: 5}} type="radio" value={2} name="filters" checked={presentation === "ALL"} onChange={() => setPresentation("ALL")} />
                            <text style={{margin: 5}} className={`${generalStyles.text16}`}>{strings.SUPER_ADMIN_FILTER_USERS_TOTAL_ANDROID_USERS}</text>
                            <input style={{margin: 5}} type="radio" value={3} name="filters" checked={presentation === "ANDROID"} onChange={() => setPresentation("ANDROID")} />
                            <text style={{margin: 5}} className={`${generalStyles.text16}`}>{strings.SUPER_ADMIN_FILTER_USERS_TOTAL_IOS_USERS}</text>
                            <input style={{margin: 5}} type="radio" value={1} name="filters" checked={presentation === "IOS"} onChange={() => setPresentation("IOS")} />
                            <text style={{margin: 5}} className={`${generalStyles.text16}`}>{strings.SUPER_ADMIN_FILTER_USERS_TOTAL_UNKNOWN_USERS}</text>
                            <input style={{margin: 5}} type="radio" value={1} name="filters" checked={presentation === "UNKNOWN"} onChange={() => setPresentation("UNKNOWN")} />
                        </div>

                        <div style={{height: 1, marginBottom: 5, width: '100%', backgroundColor: COLORS.BLUE}}></div>
                  
                        <div style={{marginBottom: 5, flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}>

                            <div style={{marginBottom: 5, flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
                                <div style={{marginTop: 5, marginBottom: 5, flexDirection: 'row', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%'}}>
                                    <text style={{marginRight: 5}} className={`${generalStyles.text16}`}>{strings.IS_LONELY}</text>
                                    <input style={{marginRight: 20}} type="checkbox" value={1} name="filters" checked={isLonely} onChange={() => setIsLonely(!isLonely)} />
                                </div>
                                <div style={{marginTop: 5, marginBottom: 5, flexDirection: 'row', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%'}}>
                                    <text style={{marginRight: 5}} className={`${generalStyles.text16}`}>{strings.IS_COMBAT}</text>
                                    <input style={{marginRight: 20}} type="checkbox" value={1} name="filters" checked={isCombat} onChange={() => setIsCombat(!isCombat)} />
                                </div>
                                <div style={{marginTop: 5, marginBottom: 5, flexDirection: 'row', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%'}}>
                                    <text style={{marginRight: 5}} className={`${generalStyles.text16}`}>{strings.IS_OFFICER}</text>
                                    <input style={{marginRight: 20}} type="checkbox" value={1} name="filters" checked={isOfficer} onChange={() => setIsOfficer(!isOfficer)} />
                                </div>
                                <div style={{marginTop: 5, marginBottom: 5, flexDirection: 'row', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%'}}>
                                    <text style={{marginRight: 5}} className={`${generalStyles.text16}`}>{strings.IS_STUDY}</text>
                                    <input style={{marginRight: 20}} type="checkbox" value={1} name="filters" checked={isStudy} onChange={() => setIsStudy(!isStudy)} />
                                </div>
                                <div style={{marginTop: 5, marginBottom: 5, flexDirection: 'row', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%'}}>
                                    <text style={{marginRight: 5}} className={`${generalStyles.text16}`}>{strings.IS_OTHER}</text>
                                    <input style={{marginRight: 20}} type="checkbox" value={1} name="filters" checked={isOther} onChange={() => setIsOther(!isOther)} />
                                </div>
                            </div>

                        </div>

                        <div style={{height: 1, marginBottom: 5, width: '100%', backgroundColor: COLORS.BLUE}}></div>
                        
                        <div style={{marginTop: 5, marginBottom: 5, flexDirection: 'column', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%'}}>
                                
                            <div style={{marginTop: 5, marginBottom: 5, flexDirection: 'row', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%'}}>
                                <select value={currentStatus} className={styles.datePickerStyle} onChange={() => setCurrentStatusValue()} ref={currentStatusRef}>
                                    <option value={strings.NEW_USER_OPTION_1_INPUT_TEXT}>{strings.NEW_USER_OPTION_1_INPUT_TEXT}</option>
                                    <option value={strings.NEW_USER_OPTION_2_INPUT_TEXT}>{strings.NEW_USER_OPTION_2_INPUT_TEXT}</option>
                                    <option value={strings.NEW_USER_OPTION_3_INPUT_TEXT}>{strings.NEW_USER_OPTION_3_INPUT_TEXT}</option>
                                    <option value={strings.NEW_USER_OPTION_4_INPUT_TEXT}>{strings.NEW_USER_OPTION_4_INPUT_TEXT}</option>
                                </select>
                                <text style={{margin: 5}} className={`${generalStyles.text16}`}>{strings.NEW_USER_OPTION_DEFAULT_INPUT_TEXT}</text>
                            </div>

                            <div style={{marginTop: 5, marginBottom: 5, flexDirection: 'row', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%'}}>
                                <select value={birthdayYear} className={styles.datePickerStyle} onChange={() => setBirthdayYearValue()} ref={birthdayYearRef}>
                                    <option value={""}></option>
                                    {getYears().map(year => {
                                        return (
                                            <option value={year}>{year}</option>
                                        )
                                    })}
                                </select>
                                <text style={{margin: 5}} className={`${generalStyles.text16}`}>{strings.FILTER_BIRTHDAY_YEAR}</text>
                            </div>
                            
                            <div style={{marginTop: 5, marginBottom: 5, flexDirection: 'row', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%'}}>
                                <select value={dischargedYear} className={styles.datePickerStyle} onChange={() => setDischargedYearValue()} ref={dischargedYearRef}>
                                    <option value={""}></option>
                                    {getYears().map(year => {
                                        return (
                                            <option value={year}>{year}</option>
                                        )
                                    })}
                                </select>
                                <text style={{margin: 5}} className={`${generalStyles.text16}`}>{strings.FILTER_DISCHARGE_YEAR}</text>
                            </div>
                                
                        </div>

                    </div>

                    <div className={generalModalStyles.footerBackViewStyle}>
                        <button className={styles.footerViewStyle_buttonStyle} onClick={() => props.closeEventAction()}>{strings.FILTER_CLOSE_ACTION}</button>
                        <button className={styles.footerViewStyle_buttonStyle} onClick={() => cleanAllAction()}>{strings.FILTER_CLEAN_ALL}</button>
                    </div>

                </motion.div>
            </>
        )}
      </AnimatePresence>
    )
}


export default FilterPopupsSuperAdmin