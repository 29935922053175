import styles from '../../popups/UsersPopups/UserModal.module.css'
import generalModalStyles from '../../../styles/GeneralModal.module.css'
import generalStyles from '../../../styles/General.module.css'
import { motion, AnimatePresence } from "framer-motion"
import strings from '../../../assets/strings'
import { useState, useRef } from 'react'
import moment from 'moment'
import { COLORS } from '../../../constants/constants'


const EditUserModal = (props) => {

    const [updatedUser, setUpdatedUser] = useState({ idNumber: null,
                                                     firstName: null, 
                                                     lastName: null, 
                                                     birthDate: null, 
                                                     currentStatus: null,
                                                     dischargeDate: null, 
                                                     email: null,
                                                     phone: null, 
                                                     isCombat: null, 
                                                     isLonely: null, 
                                                     isOfficer: null, 
                                                     isStudy: null, 
                                                     other: null, 
                                                     notes: null, 
                                                     keywords: []})
    const firstNameInputRef = useRef()
    const lastNameInputRef = useRef()
    const birthdayDateInputRef = useRef()
    const currentStatusInputRef = useRef()
    const endArmyDateInputRef = useRef()
    const emailInputRef = useRef()
    const phoneNumberInputRef = useRef()
    const notesInputRef = useRef()
    const idNumberInputRef = useRef()
    const [illegalIDNumberError, setIllegalIDNumberError] = useState(false)
    const [illegalEmailError, setIllegalEmailError] = useState(false)



    const getFirstName = () => {
        if (updatedUser.firstName != null){
            return updatedUser.firstName
        }
        if (props.user.name !== null){
            const all = props.user.name.split(" ")
            if (all.length > 0){
                return all[0] 
            }
        }
        return ''
    }


    const setFirstName = () => {
        const firstName = firstNameInputRef.current.value
        setUpdatedUser({...updatedUser, firstName: firstName })
    }


    const getIdNumber = () => {
        if (updatedUser.idNumber != null){
            return updatedUser.idNumber
        }
        if (props.user.name !== null){
            return props.user.idNumber
        }
        return ''
    }

    
    const setIdNumber = () => {
        setIllegalIDNumberError(false)
        const idNumber = idNumberInputRef.current.value
        setUpdatedUser({...updatedUser, idNumber: idNumber })
    }


    const getLastName = () => {
        if (updatedUser.lastName != null){
            return updatedUser.lastName
        }
        if (props.user.name !== null){
            const all = props.user.name.split(" ")
            if (all.length > 1){
                return all[1] 
            }
        }
        return ''
    }

    const setLastName = () => {
        const lastName = lastNameInputRef.current.value
        setUpdatedUser({...updatedUser, lastName: lastName})
    }

    const getBirthdayDateValue = () => {
        if (updatedUser.birthDate !== null){
            const temp = moment(updatedUser.birthDate, "DD/MM/YYYY")
            return temp.format("YYYY-MM-DD")
        }
        if (props.user.birthDate !== null){
            const temp = moment(props.user.birthDate, "DD/MM/YYYY")
            return temp.format("YYYY-MM-DD")
        }
        return ''
    }


    const getBirthdayDateForFinalUserValue = () => {
        if (updatedUser.birthDate !== null){
            const temp = moment(updatedUser.birthDate, "DD/MM/YYYY")
            return temp.format("DD/MM/YYYY")
        }
        if (props.user.birthDate !== null){
            const temp = moment(props.user.birthDate, "DD/MM/YYYY")
            return temp.format("DD/MM/YYYY")
        }
        return ''
    }


    const setBirthdayDate = () => {
        const birthdayDate = birthdayDateInputRef.current.value
        const birthDate = moment(birthdayDate, 'YYYY-MM-DD').format('DD/MM/YYYY')
        setUpdatedUser({...updatedUser, birthDate: birthDate})
    }


    const getDischargeDateValue = () => {
        if (updatedUser.dischargeDate !== null){
            const temp = moment(updatedUser.dischargeDate, "DD/MM/YYYY")
            return temp.format("YYYY-MM-DD")
        }
        if (props.user.dischargeDate !== null){
            const temp = moment(props.user.dischargeDate, "DD/MM/YYYY")
            return temp.format("YYYY-MM-DD")
        }
        return ''
    }

    const getDischargeDateForFinalUserValue = () => {
        if (updatedUser.dischargeDate !== null){
            const temp = moment(updatedUser.dischargeDate, "DD/MM/YYYY")
            return temp.format("DD/MM/YYYY")
        }
        if (props.user.dischargeDate !== null){
            const temp = moment(props.user.dischargeDate, "DD/MM/YYYY")
            return temp.format("DD/MM/YYYY")
        }
        return ''
    }

    const setDischargeDate = () => {
        const endArmyDate = endArmyDateInputRef.current.value
        const endArmy = moment(endArmyDate, 'YYYY-MM-DD').format('DD/MM/YYYY')
        setUpdatedUser({...updatedUser, dischargeDate: endArmy})
    }

    const getCurrentStatus = () => {
        if (updatedUser.currentStatus !== null){
            return updatedUser.currentStatus
        }
        if (props.user.currentStatus !== null){
            return props.user.currentStatus
        }
        return strings.NEW_USER_OPTION_1_INPUT_TEXT
    }

    const setCurrentStatus = () => {
        const currentStatus = currentStatusInputRef.current.value
        setUpdatedUser({...updatedUser, currentStatus: currentStatus})
    }

    const setEmail = () => {
        setIllegalEmailError(false)
        const email = emailInputRef.current.value
        setUpdatedUser({...updatedUser, email: email})
    }

    const getEmail = () => {
        if (updatedUser.email !== null){
            return updatedUser.email
        }
        if (props.user.email !== null){
            return props.user.email
        }
        return ''
    }

    const setPhoneNUmber = () => {
        const phoneNumber = phoneNumberInputRef.current.value
        setUpdatedUser({...updatedUser, phone: phoneNumber})
    }

    const getPhoneNUmber = () => {
        if (updatedUser.phone !== null){
            return updatedUser.phone
        }
        if (props.user.phone !== null){
            return props.user.phone
        }
        return ''
    }

    const getIsLonely = () => {
        if (updatedUser.isLonely !== null){
            return updatedUser.isLonely
        }
        if (props.user.isLonely !== null){
            return props.user.isLonely
        }
        return false
    }

    const getIsCombat = () => {
        if (updatedUser.isCombat !== null){
            return updatedUser.isCombat
        }
        if (props.user.isCombat !== null){
            return props.user.isCombat
        }
        return false
    }

    const getIsOfficer = () => {
        if (updatedUser.isOfficer !== null){
            return updatedUser.isOfficer
        }
        if (props.user.isOfficer !== null){
            return props.user.isOfficer
        }
        return false
    }

    const getIsStudy = () => {
        if (updatedUser.isStudy !== null){
            return updatedUser.isStudy
        }
        if (props.user.isStudy !== null){
            return props.user.isStudy
        }
        return false
    }

    const getIsOther = () => {
        if (updatedUser.other !== null){
            return updatedUser.other
        }
        if (props.user.other !== null){
            return props.user.other
        }
        return false
    }

    const getNotes = () => {
        if (updatedUser.notes !== null){
            return updatedUser.notes
        }
        if (props.user.notes !== null){
            return props.user.notes
        }
        return ''
    }

    const setNotes = () => {
        const notes = notesInputRef.current.value
        setUpdatedUser({...updatedUser, notes: notes})
    }

    const keywordIsSelected = (keyword) => {
        if (updatedUser != null){
            return updatedUser.keywords.includes(keyword)
        }
        if (props.user !== null){
            return props.user.keyword.includes(keyword)
        }
    }


    const getKeywords = () => {
        return (
          props.center.centerKeywords.map((centerKeyword) => {

            var isSelected = false
            if (updatedUser.keywords.length > 0){
                for (var i = 0; i < updatedUser.keywords.length; i++){
                    if (updatedUser.keywords[i] === centerKeyword[1]){
                        isSelected = true
                    }
                }
            }else if (props.user !== null){
                if (props.user.keywords !== undefined){
                    for (var i = 0; i < props.user.keywords.length; i++){
                        if (props.user.keywords[i] === centerKeyword[1]){
                            isSelected = true
                        }
                    }
                }
            }

            return (
              <p style={{marginRight: 10}}>
                <text className={generalStyles.text20}>{centerKeyword[0]}{' '}</text>
                  <text style={{textDecoration: 'underline'}} className={generalStyles.text20}>{centerKeyword[1]}</text>{' '}
                  <text className={generalStyles.text20}>{centerKeyword[2]}</text>
                  <input type="checkbox" value={"FILTERS.ALL"} name="filters" checked={keywordIsSelected(centerKeyword[1])} onChange={() => addKeyword(centerKeyword[1])} />
              </p>
            )
          })
        )
    }


    const getChangedDataMessage = () => {
        var tempMessage = ""
        if (updatedUser.idNumber !== null){
            tempMessage = tempMessage + " "  + strings.EDIT_USER_LOG_MESSAGE_ID_NUMBER
        }
        if (updatedUser.firstName !== null){
            tempMessage = tempMessage + " "  + strings.EDIT_USER_LOG_MESSAGE_FIRST_NAME
        }
        if (updatedUser.lastName != null){
            tempMessage = tempMessage + " " + strings.EDIT_USER_LOG_MESSAGE_LAST_NAME
        }
        if (updatedUser.birthDate !== null){
            tempMessage = tempMessage + " " + strings.EDIT_USER_LOG_MESSAGE_BIRTHDAY
        }
        if (updatedUser.dischargeDate !== null){
            tempMessage = tempMessage + " " + strings.EDIT_USER_LOG_MESSAGE_DISCHARGE_DATE
        }
        if (updatedUser.currentStatus !== null){
            tempMessage = tempMessage + " " + strings.EDIT_USER_LOG_MESSAGE_CURRENT_STATUS
        }
        if (updatedUser.email !== null){
            tempMessage = tempMessage + " " + strings.EDIT_USER_LOG_MESSAGE_EMAIL
        }
        if (updatedUser.phone !== null){
            tempMessage = tempMessage + " " + strings.EDIT_USER_LOG_MESSAGE_PHONE
        }
        if (updatedUser.isLonely !== null || updatedUser.isCombat !== null || updatedUser.isOfficer !== null || updatedUser.isStudy !== null || updatedUser.other !== null){
            tempMessage = tempMessage + " " + strings.EDIT_USER_LOG_MESSAGE_FLAGS
        }
        if (updatedUser.notes !== null){
            tempMessage = tempMessage + " " + strings.EDIT_USER_LOG_MESSAGE_NOTES
        }
        //EDIT_USER_LOG_MESSAGE_KEWWORDS: "מולות מפתח שונו"
        return tempMessage
    }


    const getFinalUser = () => {
        return { firstName: getFirstName(), 
                 lastName: getLastName(), 
                 birthDate: getBirthdayDateForFinalUserValue(), 
                 currentStatus: getCurrentStatus(),
                 dischargeDate: getDischargeDateForFinalUserValue(), 
                 email: getEmail(),
                 idNumber: getIdNumber(),
                 phone: getPhoneNUmber(), 
                 isCombat: getIsCombat(), 
                 isLonely: getIsLonely(), 
                 isOfficer: getIsOfficer(), 
                 isStudy: getIsStudy(), 
                 other: getIsOther(), 
                 notes: getNotes(),
                 keywords: updatedUser.keywords.length > 0 ? updatedUser.keywords : (props.user.keywords !== null ? props.user.keywords : []) }
    }


    const addKeyword = (keyword) => {
        if (keywordIsSelected(keyword)){
            const temp = updatedUser.keywords.filter(item => item !== keyword)
            setUpdatedUser({...updatedUser, keywords: temp})
        }else{
            const temp = [...updatedUser.keywords, keyword]
            setUpdatedUser({...updatedUser, keywords: temp})
        }
    }


    const renderDeleteButton = () => {
        // if (!props.user.approved){
        //     return <button className={styles.leftSectionBackStyle_footerViewStyle_warningButtonStyle} onClick={() => props.deleteUserAction(getFinalUser())}>{strings.NEW_USER_INPUT_DELETE_BUTTON}</button>
        // }
    }


    const saveActionHandler = () => {
        let email = getEmail()
        let idNumber = getIdNumber()

        let isIDNumberNum = /^\d+$/.test(idNumber)
        var errorExist = false
        
        if (idNumber != "") {
            if (!isIDNumberNum) {
                errorExist = true
                setIllegalIDNumberError(true)
            }
            if (idNumber.length != 9) {
                errorExist = true
                setIllegalIDNumberError(true)
            }
        }
    
        if (email != undefined && email != "" && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)){
            errorExist = true
            setIllegalEmailError(true)
        }

        if (!errorExist){
            props.updateUserAction(getFinalUser(), getChangedDataMessage())
            setUpdatedUser({ firstName: null, 
                             lastName: null, 
                             birthDate: null, 
                             currentStatus: null,
                             dischargeDate: null, 
                             email: null,
                             idNumber: null,
                             phone: null, 
                             isCombat: null, 
                             isLonely: null, 
                             isOfficer: null, 
                             isStudy: null, 
                             other: null, 
                             notes: null, 
                             keywords: []})
        }
    }


    const closeEventHandler = () => {
        props.closeEvent()
        setUpdatedUser({ firstName: null, 
                         lastName: null, 
                         birthDate: null, 
                         currentStatus: null,
                         dischargeDate: null, 
                         email: null,
                         phone: null,
                         idNumber: null,
                         isCombat: null, 
                         isLonely: null, 
                         isOfficer: null, 
                         isStudy: null, 
                         other: null, 
                         notes: null, 
                         keywords: []})
    }


    return(
        <AnimatePresence>
            {props.isOpen && (
            <>
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: 0.3 } }} exit={{ opacity: 0 }} onClick={() => props.closeEvent()} className={generalModalStyles.backViewStyle} />
                    <motion.div initial={{ scale: 0 }} animate={{ scale: 1, transition: { delay: 0.3, duration: 0.3 } }} exit={{ scale: 0 }} className={styles.backViewWrapperStyle}>
                        <motion.div className={styles.mainViewStyle}>
                            <div className={styles.mainSectionBackStyle}>

                                <div className={styles.headerStyle}>
                                    <text className={generalModalStyles.headerTextStyle}>{strings.EDIT_USER_POPUP_TITLE}</text>
                                </div>

                                <div className={styles.mainSectionStyle}>
                                    <input
                                        type='email'
                                        style={illegalEmailError ? { backgroundColor: COLORS.RED20 } : { backgroundColor: COLORS.WHITE }}
                                        placeholder={strings.NEW_USER_EMAIL_PLACEHOLDER} 
                                        value={getEmail()} 
                                        className={styles.inputStyle} 
                                        onChange={() => setEmail()} 
                                        ref={emailInputRef}
                                    />

                                    <input
                                        type='text'
                                        disabled
                                        placeholder={strings.NEW_USER_PHONE_NUMBER_PLACEHOLDER} 
                                        value={getPhoneNUmber()}
                                        className={styles.inputStyle} 
                                        onChange={() => setPhoneNUmber()} 
                                        ref={phoneNumberInputRef}
                                    />

                                    <input 
                                        placeholder={strings.NEW_USER_LAST_NAME_PLACEHOLDER}
                                        className={styles.inputStyle} 
                                        value={getLastName()} 
                                        onChange={() => setLastName()} 
                                        ref={lastNameInputRef} 
                                        type='text'
                                    />
                                    
                                    <input 
                                        placeholder={strings.NEW_USER_FIRST_NAME_PLACEHOLDER} 
                                        className={styles.inputStyle} 
                                        onChange={() => setFirstName()} 
                                        value={getFirstName()} 
                                        ref={firstNameInputRef} 
                                        type='text'
                                    />

                                    <input 
                                        placeholder={strings.NEW_USER_ID_PLACEHOLDER} 
                                        style={illegalIDNumberError ? { backgroundColor: COLORS.RED20 } : { backgroundColor: COLORS.WHITE }}
                                        className={styles.inputStyle}
                                        maxLength={9}
                                        onChange={() => setIdNumber()} 
                                        value={getIdNumber()} 
                                        ref={idNumberInputRef} 
                                        type='text'
                                    />
                                </div>

                                <div className={styles.mainSectionStyle}>
                                    <input 
                                        className={styles.inputStyle} 
                                        value={getDischargeDateValue()} 
                                        onChange={() => setDischargeDate()} 
                                        ref={endArmyDateInputRef} 
                                        type='date'
                                    />
                                    <text 
                                        className={`${generalStyles.text16}`} 
                                        style={{justifyContent: 'center', alignItems: 'center', textAlign: 'center', alignSelf: 'center'}}>
                                        {strings.NEW_USER_DISCHARGE_DATE_TITLE}
                                    </text>

                                    <input 
                                        className={styles.inputStyle} 
                                        value={getBirthdayDateValue()} 
                                        onChange={() => setBirthdayDate()} 
                                        ref={birthdayDateInputRef} 
                                        type='date'
                                    />
                                    
                                    <text
                                        className={`${generalStyles.text16}`} 
                                        style={{justifyContent: 'center', alignItems: 'center', textAlign: 'center', alignSelf: 'center'}}>
                                        {strings.NEW_USER_BIRTHDAY_DATE_TITLE}
                                    </text>
                               
                                    <select value={getCurrentStatus()} style={{height: '40px'}} className={styles.inputStyle} onChange={() => setCurrentStatus()} ref={currentStatusInputRef} placeholder={strings.NEW_USER_OPTION_DEFAULT_INPUT_TEXT}>
                                        <option value={strings.NEW_USER_OPTION_1_INPUT_TEXT}>{strings.NEW_USER_OPTION_1_INPUT_TEXT}</option>
                                        <option value={strings.NEW_USER_OPTION_2_INPUT_TEXT}>{strings.NEW_USER_OPTION_2_INPUT_TEXT}</option>
                                        <option value={strings.NEW_USER_OPTION_3_INPUT_TEXT}>{strings.NEW_USER_OPTION_3_INPUT_TEXT}</option>
                                        <option value={strings.NEW_USER_OPTION_4_INPUT_TEXT}>{strings.NEW_USER_OPTION_4_INPUT_TEXT}</option>
                                    </select>
                                    <text className={`${generalStyles.text16}`} style={{justifyContent: 'center', alignItems: 'center', textAlign: 'center', alignSelf: 'center'}}>{strings.NEW_USER_OPTION_DEFAULT_INPUT_TEXT}</text>
                                </div>

                                <div className={styles.mainSectionStyle} style={{flexDirection: 'row', minHeight: '50px', alignItems: 'center', justifyContent: 'center'}}>
                                    <div style={{marginRight: '10px', marginLeft: '10px', width: '20%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row'}}>
                                        <text className={`${generalStyles.text16}`} style={{margin: '5px'}}>{strings.NEW_USER_LONELY_INPUT_TEXT}</text>
                                        <input type="checkbox" value={"FILTERS.ALL"} name="filters" checked={getIsLonely()} onChange={() => setUpdatedUser({...updatedUser, isLonely: !updatedUser.isLonely})} />
                                    </div>
                                    
                                    <div style={{marginRight: '10px', marginLeft: '10px', width: '20%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row'}}>
                                        <text className={`${generalStyles.text16}`} style={{margin: '5px'}}>{strings.NEW_USER_COMBAT_INPUT_TEXT}</text>
                                        <input type="checkbox" value={"FILTERS.ALL"} name="filters" checked={getIsCombat()} onChange={() => setUpdatedUser({...updatedUser, isCombat: !updatedUser.isCombat})} />
                                    </div>

                                    <div style={{marginRight: '10px', marginLeft: '10px', width: '20%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row'}}>
                                        <text className={`${generalStyles.text16}`} style={{margin: '5px'}}>{strings.NEW_USER_OFFICER_INPUT_TEXT}</text>
                                        <input type="checkbox" value={"FILTERS.ALL"} name="filters" checked={getIsOfficer()} onChange={() => setUpdatedUser({...updatedUser, isOfficer: !updatedUser.isOfficer})} />
                                    </div>

                                    <div style={{marginRight: '10px', marginLeft: '10px', width: '20%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row'}}>
                                        <text className={`${generalStyles.text16}`} style={{margin: '5px'}}>{strings.NEW_USER_ACADEMY_INPUT_TEXT}</text>
                                        <input type="checkbox" value={"FILTERS.ALL"} name="filters" checked={getIsStudy()} onChange={() => setUpdatedUser({...updatedUser, isStudy: !updatedUser.isStudy})} />
                                    </div>

                                    <div style={{marginRight: '10px', marginLeft: '10px', width: '20%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row'}}>
                                        <text className={`${generalStyles.text16}`} style={{margin: '5px'}}>{strings.NEW_USER_OTHER_INPUT_TEXT}</text>
                                        <input type="checkbox" value={"FILTERS.ALL"} name="filters" checked={getIsOther()} onChange={() => setUpdatedUser({...updatedUser, other: !updatedUser.other})} />
                                    </div>
                                </div>

                                <div className={styles.mainSectionStyle}>
                                    <div style={{width: '100%', height: '220px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row'}}>
                                        <textarea placeholder={strings.NEW_USER_NOTES_INPUT_TEXT} value={getNotes()} className={styles.controlStyle} onChange={() => setNotes()} ref={notesInputRef} type="input" id='search'/>
                                    </div>
                                </div>

                                <div className={styles.mainSectionStyle} style={{flexDirection: 'column'}}>
                                    {getKeywords()}
                                </div>

                            </div>

                            <div className={styles.footerViewStyle}>
                                <button className={styles.leftSectionBackStyle_footerViewStyle_buttonStyle} onClick={() => closeEventHandler()}>{strings.CLOSE_BUTTON}</button>
                                <button className={styles.leftSectionBackStyle_footerViewStyle_buttonStyle} onClick={() => saveActionHandler()}>{strings.NEW_USER_INPUT_CREATE_BUTTON}</button>
                                {renderDeleteButton()}
                            </div>

                        </motion.div>
                    </motion.div>
                </>
            )}
        </AnimatePresence>
    )
}


export default EditUserModal