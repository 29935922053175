import { useState } from 'react'
import { ScrollMenu } from 'react-horizontal-scrolling-menu'
import styles from './DashboardPage.module.css'
import generalStyles from '../../styles/General.module.css'
import SectionsList from '../../components/dashboard_section/SectionsList'
import DashboardItem from '../../components/dashboard_section/DashboardItem'
import { motion, AnimatePresence } from "framer-motion"
import { IC_EMPLOYMENTS, IC_STUDY, IC_RIGHTS, IC_MORE, UAPP_LOGO } from '../../assets/images'
import { DEFAULT_MESSAGE_DOWNLOAD_LINK, MESSAGE_TYPES, POPUPS_TYPES, SECTIONS_ITEMS } from '../../constants/constants'
import strings from '../../assets/strings'
import RingLoader from "react-spinners/RingLoader"
import SystemMessage from '../../components/system_messages/SystemMessage'
import { showSuccessMessage, showErrorMessage } from '../SystemMessagesManager'
import { getDashboardItemsByCategoryAPI } from '../../managers/ServerDashboardManager'
import { sendPushMessageAPI, sendSmsMessageAPI } from '../../managers/ServerMessagesManager'
import NewPopup from '../../components/popups/NewPopup'
import { useSelector } from 'react-redux'


const DashboardPage = () => {

  const center = useSelector((state) => state.center.center)
  const job = useSelector((state) => state.dashboard.jobs)
  const studySections = useSelector((state) => state.dashboard.studies)
  const moreSections = useSelector((state) => state.dashboard.more)
  const rightsSections = useSelector((state) => state.dashboard.rights)
  const [selectedSection, setSelectedSection] = useState(SECTIONS_ITEMS.WORK)
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [selectedDashboardItem, setSelectedDashboardItem] = useState(null)
  const [mainData, setMainData] = useState([])
  const [loader, setLoader] = useState(false)


  const getSectionsList = () => {
    if (selectedSection === SECTIONS_ITEMS.WORK){
      var jobsList = []
      for (var i = 0; i < job.publishJob.length; i++){
        var mainData = job.publishJob[i]
        const tempItem = { title: mainData.Category.replace('[%02-37-9a-bdf]', '') }
        jobsList = jobsList.concat(tempItem)
      }
      return jobsList
    }
    if (selectedSection === SECTIONS_ITEMS.STUDY){
      return studySections
    }
    if (selectedSection === SECTIONS_ITEMS.MORE){
      return moreSections
    }
    if (selectedSection === SECTIONS_ITEMS.RIGHTS){
      return rightsSections
    }
  }


  const sendPushMessageEventHandler = async (users, message, link) => {
    await sendPushMessageAPI(users, message, MESSAGE_TYPES.SHARING, center.centerName)
    setSelectedDashboardItem(null)
    showSuccessMessage(strings.SYSTEM_MESSAGE_DASHBOARD_ITEM_SHARED_SUCCESSFULLY)
  }


  const sendSmsMessageEventHandler = async (users, message, link) => {
    let mes = message + DEFAULT_MESSAGE_DOWNLOAD_LINK
    await sendSmsMessageAPI(users, mes, MESSAGE_TYPES.SHARING, center.centerName)
    setSelectedDashboardItem(null)
    showSuccessMessage(strings.SYSTEM_MESSAGE_DASHBOARD_ITEM_SHARED_SUCCESSFULLY)
  }


  const onItemClickHandler = async (dataRoute, isJob) => {
    setSelectedCategory(dataRoute)
    setLoader(true)

    if (isJob){
      var tempList = []
      for (var i = 0; i < job.publishJob.length; i++){
        var mainData = job.publishJob[i]
        const tempSection = mainData.Category.replace('[%02-37-9a-bdf]', '')

        if (decodeURIComponent(tempSection) === dataRoute){

          for (var j = 0; j < mainData.SubCategories.length; j++){
            var subCategoryItem = mainData.SubCategories[j]

            var tempItemsList = []
            for (var k = 0; k < subCategoryItem.Jobs.length; k++){

              try {
                var jobItem = subCategoryItem.Jobs[k]
                const tempItem = { title: decodeURIComponent(jobItem.Position), text: decodeURIComponent(jobItem.Dbjobpubtext)}
                tempItemsList = tempItemsList.concat(tempItem)
              } catch (error) {
                var jobItem = subCategoryItem.Jobs[k]
                const tempItem = { title: jobItem.Position, text: jobItem.Dbjobpubtext}
                tempItemsList = tempItemsList.concat(tempItem)
              }
            }
          
            const tempItem = { category: decodeURIComponent(subCategoryItem.SubCategory), items: tempItemsList }
            tempList = tempList.concat(tempItem)
          }
        }
      }
      setMainData(tempList)
      setLoader(false)

    }else{

      const items = await getDashboardItemsByCategoryAPI(dataRoute)
      setLoader(false)

      if (items.data != null){
        setMainData(items.data)
      }else{
        showErrorMessage(items.error)
      }
    }
  }


  const onSelectedMainItemHandler = (item) => {
    setSelectedCategory(null)
    setSelectedDashboardItem(null)
    setSelectedSection(item)
  }


  const onSelectedDashboardItemHandler = (item) => {
    setSelectedDashboardItem(item)
  }


  const renderDashboardItemsBoard = () => {
    if (selectedCategory != null && mainData.length > 0){
      return (
        <AnimatePresence>
          <motion.div className={`${generalStyles.blueViewWithShadowStyle} ${styles.bottomRightSectionStyle}`} initial={{ width: '69%' }} animate={{ width: '84%' }} exit={{ width: '69%' }}>
            
          {mainData.map(item => (
            <>
              <div className={`${generalStyles.whiteViewWithShadowStyle} ${styles.sectionTitleBackViewStyle} ${generalStyles.text20}`}>
                <text className={styles.sectionTitleTextStyle}>{item.category}</text>
              </div>

              <ScrollMenu className={styles.sectionBackViewStyle} RTL={true}>
                {item.items !== undefined &&
                  item.items.map(item => (
                    <DashboardItem item={item} selectedSection={selectedSection} onClickEvent={(item) => onSelectedDashboardItemHandler(item)} />
                  ))}
              </ScrollMenu>
            </>
          ))}
          </motion.div>
        </AnimatePresence>
      )
    }else{
      return (
        <div className={`${generalStyles.blueViewWithShadowStyle} ${styles.bottomRightEmptySectionStyle}`}>
            <img className={styles.emptyStateImageStyle} src={UAPP_LOGO} />
        </div>
      )
    }
  }


  const renderCategoriesList = () => {
    if (selectedCategory != null){
        return (
          <AnimatePresence>
              {selectedCategory != null && (
                  <motion.div className={`${generalStyles.blueViewWithShadowStyle} ${styles.bottomLeftSectionStyle}`} initial={{ width: '30%' }} animate={{ width: '15%' }} exit={{ width: '30%' }}>
                    <SectionsList items={getSectionsList()} selectedSection={selectedSection} selectedCategory={selectedCategory} onItemClick={(dataRoute, isJob) => onItemClickHandler(dataRoute, isJob)} />
                  </motion.div>
              )}
          </AnimatePresence>
        )
    }else{
      return (
        <div className={`${generalStyles.blueViewWithShadowStyle} ${styles.bottomLeftSectionStyle}`}>
           <SectionsList items={getSectionsList()} selectedSection={selectedSection} selectedCategory={selectedCategory} onItemClick={(id, isJob) => onItemClickHandler(id, isJob)} />
        </div>
      )
    }
  }


  return (
    <div className={generalStyles.mainBackViewStyle}>
      
        <div className={`${generalStyles.blueViewWithShadowStyle} ${styles.topSectionStyle}`}>
          <button className={styles.topButtonStyle} style={{backgroundImage: `url(${IC_EMPLOYMENTS})`}} onClick={() => onSelectedMainItemHandler(SECTIONS_ITEMS.WORK)}></button>
          <button className={styles.topButtonStyle} style={{backgroundImage: `url(${IC_STUDY})`}} onClick={() => onSelectedMainItemHandler(SECTIONS_ITEMS.STUDY)}></button>
          <button className={styles.topButtonStyle} style={{backgroundImage: `url(${IC_RIGHTS})`}} onClick={() => onSelectedMainItemHandler(SECTIONS_ITEMS.RIGHTS)}></button>
          <button className={styles.topButtonStyle} style={{backgroundImage: `url(${IC_MORE})`}} onClick={() => onSelectedMainItemHandler(SECTIONS_ITEMS.MORE)}></button>
        </div>

        <div className={styles.bottomSectionStyle}>
          {renderCategoriesList()}
          {renderDashboardItemsBoard()}
        </div>

        <NewPopup
          item={selectedDashboardItem}
          center={center}
          type={POPUPS_TYPES.DASHBOARD_DETAILS}
          isOpen={selectedDashboardItem != null ? true : false}
          sendPushMessageEvent={(users, message, link) => sendPushMessageEventHandler(users, message, link)} 
          sendSmsMessageEvent={(users, message, link) => sendSmsMessageEventHandler(users, message, link)} 
          closeEvent={() => setSelectedDashboardItem(null)} />
      
        <div className={generalStyles.loaderBackView}>
            {loader && <RingLoader color="#176ED0" />}
        </div>

        <SystemMessage />

    </div>
  )
}
  
  
  
export default DashboardPage