import React from 'react'
import styles from './MessagesListDefaultItem.module.css'
import generalStyles from '../../styles/General.module.css'
import strings from '../../assets/strings';


const MessagesListDefaultItem = props => {

    return (
        <div className={`${generalStyles.whiteViewWithShadowStyle} ${styles.sectionItemStyle}`} onClick={() => props.onClickEvent()}>
            <text className={styles.textTextStyle}>{strings.MESSAGES_DEFAULT_BUTTON}</text>
        </div>
  );
};


export default MessagesListDefaultItem