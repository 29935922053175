import React from 'react'
import styles from './CalendarEventView.module.css'
import { DOTS_WHITE_ICON, GROUP_ICON } from '../../assets/images'


const CalendarEventView = (props) => {

  
    const renderUsersIconIfNeed = (event) => {
        if (event.eventParticipants != undefined){
          if (event.eventParticipants.length > 0){
            return <img className={styles.eventIconStyle} src={GROUP_ICON}></img>
          }
        }
    }


    return (
        <div className={styles.eventViewStyle} style={{ top: props.positionY, width: props.width, height: props.height }}>
            <img
                className={styles.dotsIconStyle} 
                src={DOTS_WHITE_ICON}
                onClick={() => props.editEventAction(props.event)}
            />
            <text className={styles.eventTitleTextStyle}>{props.event.eventTitle}</text>
            {renderUsersIconIfNeed(props.event)}
        </div>
    )
}


export default CalendarEventView
