import { MESSAGE_TYPES } from "../constants/constants"
import strings from '../assets/strings'


export async function sendPushNotificationAPI(phoneNumber, text, type){
    var notificationTitle = ""
    if (type === MESSAGE_TYPES.MESSAGE){
        notificationTitle = strings.NOTIFICATION_TITLE_MESSAGE_TYPES_MESSAGE
    }else if (type === MESSAGE_TYPES.INVITE){
        notificationTitle = strings.NOTIFICATION_TITLE_MESSAGE_TYPES_INVITE
    }else if (type === MESSAGE_TYPES.SHARING){
        notificationTitle = strings.NOTIFICATION_TITLE_MESSAGE_TYPES_SHARING
    }else if (type === MESSAGE_TYPES.EVENT){
        notificationTitle = strings.NOTIFICATION_TITLE_MESSAGE_TYPES_EVENT
    }

    fetch('https://us-central1-uapp-c2c68.cloudfunctions.net/api/contactUser/sendNotification', {
        method: 'POST',
        body: JSON.stringify({
          "userPhoneNumber": phoneNumber,
          "notificationTitle": notificationTitle,
          "notificationBody": text
        }),
        headers: {
            "Content-Type": "application/json",
            "cache-control": "no-cache",
        },
    })
    .then((response) => response.json())
    .then((data) => {
        console.log("SERGEY(sendPushNotificationAPI) >>>>> OK: " + phoneNumber);
    })
    .catch((err) => {
        console.log("SERGEY(sendPushNotificationAPI) >>>>> ERROR: " + err.message);
    });
}




export async function sendSmsAPI(phoneNumber, text, type, centerKey){
    fetch('https://us-central1-uapp-c2c68.cloudfunctions.net/api/contactUser/sendSms', {
        method: 'POST',
        body: JSON.stringify({
          "userPhoneNumber": phoneNumber,
          "notificationBody": text,
          "centerKey": centerKey
        }),
        headers: {
            "Content-Type": "application/json",
            "cache-control": "no-cache",
        },
    })
    .then((response) => response.json())
    .then((data) => {
        console.log("SERGEY(sendSmsAPI) >>>>> OK: " + phoneNumber);
    })
    .catch((err) => {
        console.log("SERGEY(sendSmsAPI) >>>>> ERROR: " + err.message);
    });
}




