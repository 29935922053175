import { useState } from 'react'
import styles from './MainPage.module.css'
import { UAPP_LOGO, SM_NEW, SM_USERS, SM_LOGOUT, SM_DASHBOARD, SM_MESSAGES } from '../assets/images'
import strings from '../assets/strings'
import SuperAdminUsersPage from './SuperAdmin/SuperAdminUsersPage'
import SuperAdminDashboard from './SuperAdmin/SuperAdminDashboard'
import SuperAdminCentersPage from './SuperAdmin/SuperAdminCentersPage'
import SuperAdminMessagesPage from './SuperAdmin/SuperAdminMessagesPage'


const SuperAdminMainPage = (props) => {

    const SUPER_ADMIN_MENU_ITEMS = { DASHBOARD: 'Dashboard', USERS: "Users", CENTERS: "Centers", MESSAGES: 'Messages' }
    const [presentedMenu, setPresentedMenu] = useState(SUPER_ADMIN_MENU_ITEMS.USERS)


    const switchMenu = (SUPER_ADMIN_MENU_ITEMS) => {
        setPresentedMenu(SUPER_ADMIN_MENU_ITEMS)
    }


    const renderMainView = () => {
        if (presentedMenu == SUPER_ADMIN_MENU_ITEMS.DASHBOARD){
            return (
                <SuperAdminDashboard />
            )
        }else if (presentedMenu == SUPER_ADMIN_MENU_ITEMS.USERS){
            return (
                <SuperAdminUsersPage />
            )
        }else if (presentedMenu == SUPER_ADMIN_MENU_ITEMS.CENTERS){
            return (
                <SuperAdminCentersPage />
            )
        }else if (presentedMenu == SUPER_ADMIN_MENU_ITEMS.MESSAGES){
            return (
                <SuperAdminMessagesPage />
            )
        }
    }


    return (
        <>
            <div className={styles.menuBackViewStyle}>

                <div className={styles.menuButtonsBackViewStyle}>
                    <img className={styles.logoImageStyle} src={UAPP_LOGO} alt="Logo" />
                    <text className={styles.logoTextStyle}></text>
                    
                    <div className={styles.menuButtonStyle} onClick={() => switchMenu(SUPER_ADMIN_MENU_ITEMS.DASHBOARD)}>
                        <img className={styles.menuButtonIconStyle} src={SM_DASHBOARD} />
                        <text className={styles.menuButtonTextStyle}>{strings.SIDE_MENU_DASHBOARD}</text>
                    </div>
                    <div className={styles.menuButtonStyle} onClick={() => switchMenu(SUPER_ADMIN_MENU_ITEMS.USERS)}>
                        <img className={styles.menuButtonIconStyle} src={SM_USERS} />
                        <text className={styles.menuButtonTextStyle}>{strings.SIDE_MENU_USERS}</text>
                    </div>
                    <div className={styles.menuButtonStyle} onClick={() => switchMenu(SUPER_ADMIN_MENU_ITEMS.CENTERS)}>
                        <img className={styles.menuButtonIconStyle} src={SM_USERS} />
                        <text className={styles.menuButtonTextStyle}>{strings.SIDE_MENU_CENTERS}</text>
                    </div>
                    <div className={styles.menuButtonStyle} onClick={() => switchMenu(SUPER_ADMIN_MENU_ITEMS.MESSAGES)}>
                        <img className={styles.menuButtonIconStyle} src={SM_MESSAGES} />
                        <text className={styles.menuButtonTextStyle}>{strings.SIDE_MENU_MESSAGES}</text>
                    </div>
                </div>
                
                <div className={styles.menuLogoutBackViewStyle}>
                    <div className={styles.menuButtonStyle} onClick={() => props.onLogout()}>
                        <img className={styles.menuButtonIconStyle} src={SM_LOGOUT} />
                        <text className={styles.menuButtonTextStyle}>{strings.SIDE_MENU_LOGOUT}</text>
                    </div>
                </div>

            </div>

            <div className={styles.mainBackViewStyle}>
                {renderMainView()}
            </div>
        </>
    )
}


export default SuperAdminMainPage