import React from 'react'
import styles from './FullUsersListItem.module.css'
import generalStyle from '../../styles/General.module.css'
import strings from '../../assets/strings';


const FullUsersListItem = props => {

    const getStatusData = () => {
        var tempText = ""
        if (props.item.isLonely){
            tempText = tempText + " " + strings.REPORT_TOTAL_LONELY_USERS
        }
        if (props.item.isStudy){
            tempText = tempText + " " + strings.REPORT_TOTAL_STUDY_USERS
        }
        if (props.item.isCombat){
            tempText = tempText + " " + strings.REPORT_TOTAL_COMBAT_USERS
        }
        if (props.item.isOfficer){
            tempText = tempText + " " + strings.REPORT_TOTAL_OFFICER_USERS
        }
        if (props.item.isOther){
            tempText = tempText + " " + strings.REPORT_TOTAL_DRIVER_USERS
        }
        return tempText
    }


  
    return (        
        <div className={`${generalStyle.whiteViewWithShadowStyle} ${styles.sectionItemStyle}`} style={props.isSelected ? { background: '#C2DAEE' } : { background: '#FFFFFF' }} onClick={() => props.onItemClick()}>
            
            <div className={styles.textBackStyle}>
                <text className={`${generalStyle.text14} ${styles.textStyle}`}>{getStatusData()}</text>
            </div>
            
            <div className={styles.textBackStyle}>
                <text className={`${generalStyle.text14} ${styles.textStyle}`}>{props.item.currentStatus}</text>
            </div>

            <div className={styles.textBackStyle}>
                <text className={`${generalStyle.text14} ${styles.textStyle}`}>{props.item.city}</text>
            </div>

            <div className={styles.textBackStyle}>
                <text className={`${generalStyle.text14} ${styles.textStyle}`}>{props.item.email}</text>
            </div>

            <div className={styles.textBackStyle}>
                <text className={`${generalStyle.text14} ${styles.textStyle}`}>{props.item.phone}</text>
            </div>

            <div className={styles.textBackStyle}>
                <text className={`${generalStyle.text20} ${styles.textStyle}`}>{props.item.name}</text>
            </div>

        </div>
  );
};


export default FullUsersListItem