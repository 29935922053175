import styles from './NewPopup.module.css'
import generalStyles from '../../styles/General.module.css'
import generalModalStyles from '../../styles/GeneralModal.module.css'
import { motion, AnimatePresence } from "framer-motion"
import strings from '../../assets/strings'
import UsersSection from './UsersSelector/UsersSection'
import { useState, useEffect, useRef } from 'react'
import SelectedUsersRow from './UsersSelector/SelectedUsersRow'
import SmallFilterPopups from './Filters/SmallFilterPopups'
import moment from 'moment'
import { DEFAULT_MESSAGE_DOWNLOAD_LINK, POPUPS_TYPES } from '../../constants/constants'
import { useSelector } from 'react-redux'
import { filterAction, isFilterActive } from '../../constants/Utils'
import { COLORS } from '../../constants/constants'
import { NOTIFICATIONS_ICON } from '../../assets/images'


const initialStateEvent = { eventKey: null,
                            eventTitle: '', 
                            eventDescriptions: '', 
                            eventDate: '', 
                            eventTime: '',
                            eventDirection: '', 
                            eventParticipants: [{ name: '', phone: '' }] }


const NewPopup = (props) => {

    const users = useSelector((state) => state.users.users.filter(u => u.approved === true))
    const [selectedUsers, setSelectedUsers] = useState([])
    const [filterPopupVisible, setFilterPopupVisible] = useState(false)
    const [filtersActive, setFiltersActive] = useState(false)
    const [presentedUsers, setPresentedUsers] = useState([])
    const [messageText, setMessageText] = useState("")
    const [messageLink, setMessageLink] = useState("")
    const [messageTextError, setMessageTextError] = useState(false)
    const [eventDateError, setEventDateError] = useState(false)
    const [eventTimeError, setEventTimeError] = useState(false)
    const [eventDirectionError, setEventDirectionError] = useState(false)
    const [eventTitleError, setEventTitleError] = useState(false)
    const [newEvent, setNewEvent] = useState(initialStateEvent)
    const eventTitleInputRef = useRef()
    const eventTextInputRef = useRef()
    const eventDateRef = useRef()
    const eventTimeRef = useRef()
    const eventDirectionRef = useRef()
    const messageInputRef = useRef()
    const messageLinkInputRef = useRef()

    
    useEffect(() => {
        setPresentedUsers(users)
    }, [])
    


    const renderWhatsAppActionButton = () => {
        const counter = props.type === POPUPS_TYPES.NEW_EVENT ? newEvent.eventParticipants.length : selectedUsers.length
        if (counter == 1){
            return <button className={styles.leftSectionBackStyle_footerViewStyle_buttonStyle} onClick={() => renderReturnData(strings.SHARE_WHATSAPP_BUTTON)}>{strings.SHARE_WHATSAPP_BUTTON}</button>
        }
    }


    const renderActionButtons = () => {
        if (props.type === POPUPS_TYPES.DASHBOARD_DETAILS){
            return (
                <div className={styles.leftSectionBackStyle_footerViewStyle}>    
                    <button className={styles.leftSectionBackStyle_footerViewStyle_buttonStyle} onClick={() => closeEventHandler()}>{strings.CLOSE_BUTTON}</button>
                    <button className={styles.leftSectionBackStyle_footerViewStyle_buttonStyle} onClick={() => renderReturnData(strings.SHARE_PUSH_BUTTON)}>{strings.SHARE_PUSH_BUTTON}</button>
                    <button className={styles.leftSectionBackStyle_footerViewStyle_buttonStyle} onClick={() => renderReturnData(strings.SHARE_SMS_BUTTON)}>{strings.SHARE_SMS_BUTTON}</button>
                    {renderWhatsAppActionButton()}
                </div>
            )

        }else if (props.type === POPUPS_TYPES.NEW_MESSAGE){
            return (
                <div className={styles.leftSectionBackStyle_footerViewStyle}>    
                    <button className={styles.leftSectionBackStyle_footerViewStyle_buttonStyle} onClick={() => closeEventHandler()}>{strings.CLOSE_BUTTON}</button>
                    <button className={styles.leftSectionBackStyle_footerViewStyle_buttonStyle} onClick={() => renderReturnData(strings.SHARE_PUSH_BUTTON)}>{strings.SHARE_PUSH_BUTTON}</button>
                    <button className={styles.leftSectionBackStyle_footerViewStyle_buttonStyle} onClick={() => renderReturnData(strings.SHARE_SMS_BUTTON)}>{strings.SHARE_SMS_BUTTON}</button>
                    {renderWhatsAppActionButton()}
                </div>
            )
        
        }else if (props.type === POPUPS_TYPES.NEW_EVENT){
            return (
                <div className={styles.leftSectionBackStyle_footerViewStyle}>    
                    <button className={styles.leftSectionBackStyle_footerViewStyle_buttonStyle} onClick={() => closeEventHandler()}>{strings.CLOSE_BUTTON}</button>
                    <button className={styles.leftSectionBackStyle_footerViewStyle_buttonStyle} onClick={() => renderReturnData(strings.SHARE_PUSH_BUTTON)}>{strings.SHARE_PUSH_BUTTON}</button>
                    <button className={styles.leftSectionBackStyle_footerViewStyle_buttonStyle} onClick={() => renderReturnData(strings.SHARE_SMS_BUTTON)}>{strings.SHARE_SMS_BUTTON}</button>
                </div>
            )
        }
    }
    

    const setEventTitle = () => {
        setEventTitleError(false)
        const title = eventTitleInputRef.current.value
        setNewEvent({...newEvent, eventTitle: title})
    }


    const setEventDescriptions = () => {
        const description = eventTextInputRef.current.value
        setNewEvent({...newEvent, eventDescriptions: description})
    }


    const setupEventTime = () => {
        setEventTimeError(false)
        const time = eventTimeRef.current.value
        const tempDate = moment(time, 'HH:mm').format('HH:mm')
        setNewEvent({...newEvent, eventTime: tempDate})
    }


    const setupEventDate = () => {
        setEventDateError(false)
        const date = eventDateRef.current.value
        const tempDate = moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')
        setNewEvent({...newEvent, eventDate: tempDate})
    }


    const setupMessageText = () => {
        setMessageTextError(false)
        const text = messageInputRef.current.value
        setMessageText(text)
    }


    const setEventDirection = () => {
        setEventDirectionError(false)
        const direction = eventDirectionRef.current.value
        setNewEvent({...newEvent, eventDirection: direction})
    }

    
    const getDefaultEventDateValue = () => {
        const temp = moment(newEvent.eventDate, "DD/MM/YYYY")
        return temp.format("YYYY-MM-DD")
    }


    const getDefaultEventTimeValue = () => {
        const temp = moment(newEvent.eventTime, "hh:mm")
        return temp.format("HH:mm")
    }


    const setEventParticipants = (users) => {
        let temp = users.map((user) => {
            return {name: user.name, phone: user.phone}
        })
        setNewEvent({...newEvent, eventParticipants: temp})
    }


    const filterActionHandler = (isLonely, isCombat, isOfficer, isStudy, isOther, birthdayFromYear, birthdayFromMonth, birthdayToYear, birthdayToMonth, dischargedFromYear, dischargedFromMonth, dischargedToYear, dischargedToMonth, currentStatus, selectedKeywords) => {
        var tempList = filterAction(users, null, null, isLonely, isCombat, isOfficer, isStudy, isOther, birthdayFromYear, birthdayFromMonth, birthdayToYear, birthdayToMonth, dischargedFromYear, dischargedFromMonth, dischargedToYear, dischargedToMonth, currentStatus, selectedKeywords)
        var tempActiveFiltersCounter = isFilterActive(users, null, isLonely, isCombat, isOfficer, isStudy, isOther, birthdayFromYear, birthdayFromMonth, birthdayToYear, birthdayToMonth, dischargedFromYear, dischargedFromMonth, dischargedToYear, dischargedToMonth, currentStatus, selectedKeywords)
        setFiltersActive(tempActiveFiltersCounter)
        setPresentedUsers(tempList)
    }


    const filterSectionActionHandler = (value) => {
        setFilterPopupVisible(value)
    }


    const renderMainSection = () => {
        if (props.type === POPUPS_TYPES.DASHBOARD_DETAILS){
            return (
                <div className={styles.leftSectionBackStyle_mainViewStyle} style={{ justifyContent: 'flex-start' }}>
                    <text className={generalModalStyles.bodyTextStyle}>{props.item.text.replace(/(<([^>]+)>)/ig, '')}</text>
                </div>
            )

        }else if (props.type === POPUPS_TYPES.NEW_MESSAGE){
            return (
                <div className={styles.leftSectionBackStyle_mainViewStyle}>
                    <textarea style={messageTextError ? { backgroundColor: COLORS.RED20 } : { backgroundColor: COLORS.WHITE }} placeholder={strings.NEW_MESSAGE_INPUT_TEXT} className={styles.leftSectionBackStyle_mainViewStyle_messageControl} onChange={() => setupMessageText()} ref={messageInputRef} type="input" id='search'/>
                    <input placeholder={strings.NEW_MESSAGE_INPUT_LINK} className={styles.leftSectionBackStyle_mainViewStyle_linkControl} onChange={() => setMessageLink(messageLinkInputRef.current.value)} ref={messageLinkInputRef} type="input" id='search'/>
                </div>
            )
        
        }else if (props.type === POPUPS_TYPES.NEW_EVENT){
            return (
                    <div className={styles.leftSectionBackStyle_mainViewStyle}>
                        <div style={{ width: '100%', height: '20%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                            <select style={eventDirectionError ? { backgroundColor: COLORS.RED20 } : { backgroundColor: COLORS.WHITE }} value={newEvent.eventDirection} className={styles.eventDatePickerStyle} onChange={() => setEventDirection(eventDirectionRef.current.value)} ref={eventDirectionRef} placeholder={strings.NEW_EVENT_DIRECTION_TEXT}>
                                <option value={15}>15 {strings.NEW_EVENT_DIRECTION_MIN_TEXT}</option>
                                <option value={30}> 30 {strings.NEW_EVENT_DIRECTION_MIN_TEXT}</option>
                                <option value={45}>45 {strings.NEW_EVENT_DIRECTION_MIN_TEXT}</option>
                                <option value={60}>1 {strings.NEW_EVENT_DIRECTION_HOUR_TEXT}</option>
                                <option value={90}>1.5 {strings.NEW_EVENT_DIRECTION_HOUR_TEXT}</option>
                                <option value={120}>2 {strings.NEW_EVENT_DIRECTION_HOUR_TEXT}</option>
                            </select>
                            <text className={`${generalStyles.text16} ${styles.eventTextDatePickerStyle}`}>{strings.NEW_EVENT_DIRECTION_TEXT}</text>

                            <input style={eventDateError ? { backgroundColor: COLORS.RED20 } : { backgroundColor: COLORS.WHITE }} className={styles.eventDatePickerStyle} value={getDefaultEventDateValue()} onChange={() => setupEventDate()} ref={eventDateRef} type='date' required/>
                            <text className={`${generalStyles.text16} ${styles.eventTextDatePickerStyle}`}>{strings.NEW_EVENT_DATE_TEXT}</text>

                            <input style={eventTimeError ? { backgroundColor: COLORS.RED20 } : { backgroundColor: COLORS.WHITE }} className={styles.eventDatePickerStyle} value={getDefaultEventTimeValue()} onChange={() => setupEventTime()} ref={eventTimeRef} type='time' required/>
                            <text className={`${generalStyles.text16} ${styles.eventTextDatePickerStyle}`}>{strings.NEW_EVENT_TIME_TEXT}</text>
                        </div>
                        
                        <input 
                            placeholder={strings.NEW_EVENT_INPUT_TITLE} 
                            value={newEvent.eventTitle} 
                            style={eventTitleError ? { backgroundColor: COLORS.RED20 } : { backgroundColor: COLORS.WHITE }}
                            className={styles.leftSectionBackStyle_mainViewStyle_eventTitle} 
                            onChange={() => setEventTitle()} 
                            ref={eventTitleInputRef} 
                            type="text" 
                            id='search'/>

                        <textarea placeholder={strings.NEW_EVENT_INPUT_TEXT} value={newEvent.eventDescriptions} className={styles.leftSectionBackStyle_mainViewStyle_eventText} onChange={() => setEventDescriptions()} ref={eventTextInputRef} type="textarea" id='search'/>
                        
                    </div>
            )
        }
    }

    
    const renderReturnData = (text) => {
        if (props.type === POPUPS_TYPES.DASHBOARD_DETAILS){

            if (text === strings.SHARE_PUSH_BUTTON){
                props.sendPushMessageEvent(selectedUsers, props.item.text.replace(/(<([^>]+)>)/ig, ''), "")
            }else if (text === strings.SHARE_SMS_BUTTON){
                props.sendSmsMessageEvent(selectedUsers, props.item.text.replace(/(<([^>]+)>)/ig, ''), "")
            }else if (text === strings.SHARE_WHATSAPP_BUTTON){

                const message = props.item.text.replace(/(<([^>]+)>)/ig, '')
                const mes = message + DEFAULT_MESSAGE_DOWNLOAD_LINK

                let url = `https://web.whatsapp.com/send?phone=+972` + selectedUsers[0].phone
                url += `&text=${encodeURI(mes)}&app_absent=0`;
                window.open(url)
            }

        }else if (props.type === POPUPS_TYPES.NEW_MESSAGE){
            
            if (messageText === ''){
                setMessageTextError(true)
                return
            }
            if (selectedUsers.length === 0){
                return
            }

            if (text === strings.SHARE_PUSH_BUTTON){
                props.sendPushMessageEvent(selectedUsers, messageText, messageLink)
            }else if (text === strings.SHARE_SMS_BUTTON){
                props.sendSmsMessageEvent(selectedUsers, messageText, messageLink)
            }else if (text === strings.SHARE_WHATSAPP_BUTTON){

                const message = messageText.replace(/(<([^>]+)>)/ig, '')
                let url = `https://web.whatsapp.com/send?phone=+972` + selectedUsers[0].phone
                url += `&text=${encodeURI(message)}&app_absent=0`;
                window.open(url)
            }
            setMessageText("")
            setMessageLink("")
            setSelectedUsers([])

        }else if (props.type === POPUPS_TYPES.NEW_EVENT){

            if (newEvent.eventDate === ''){
                setEventDateError(true)
                return
            }
            if (newEvent.eventTime === ''){
                setEventTimeError(true)
                return
            }
            if (newEvent.eventDirection === ''){
                setEventDirectionError(true)
                return
            }
            if (newEvent.eventTitle === ''){
                setEventTitleError(true)
                return
            }

            if (text === strings.SHARE_PUSH_BUTTON){
                props.donePushAction(newEvent, newEvent.eventParticipants.length > 0)
            }else if (text === strings.SHARE_SMS_BUTTON){
                props.doneSmsAction(newEvent, newEvent.eventParticipants.length > 0)
            }
            setNewEvent(initialStateEvent)
            setSelectedUsers([])
        }
    }


    const renderTitle = () => {
        if (props.type === POPUPS_TYPES.DASHBOARD_DETAILS){
            return (
                <text className={generalModalStyles.headerTextStyle}>{props.item.title}</text>
            )

        }else if (props.type === POPUPS_TYPES.NEW_MESSAGE){
            return (
                <text className={generalModalStyles.headerTextStyle}>{strings.NEW_MESSAGE_POPUP_TITLE}</text>
            )

        }else if (props.type === POPUPS_TYPES.NEW_EVENT){
            return (
                <text className={generalModalStyles.headerTextStyle}>{strings.NEW_EVENT_POPUP_TITLE}</text>
            )
        }
    }


    const closeEventHandler = () => {
        if (props.type === POPUPS_TYPES.DASHBOARD_DETAILS){
            props.closeEvent()

        }else if (props.type === POPUPS_TYPES.NEW_MESSAGE){
            setMessageText("")
            setMessageLink("")
            props.closeEvent()

        }else if (props.type === POPUPS_TYPES.NEW_EVENT){
            setNewEvent(initialStateEvent)
            props.closeEvent()
        }
    }


    return(
        <AnimatePresence>
            {props.isOpen && (
                <>
                    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: 0.3 } }} exit={{ opacity: 0 }} onClick={() => props.closeEvent()} className={generalModalStyles.backViewStyle} />
                    <motion.div initial={{ scale: 0 }} animate={{ scale: 1, transition: { delay: 0.3, duration: 0.3 } }} exit={{ scale: 0 }} className={styles.backViewWrapperStyle}>
                        <motion.div className={styles.mainBackViewStyle}>
                            
                            <div className={styles.leftSectionBackStyle} style={{ width: filterPopupVisible ? '40%'  : '60%' }}>
                                <div className={styles.leftSectionBackStyle_headerViewStyle}>
                                    {renderTitle()}
                                </div>

                                {renderMainSection()}

                                <div className={styles.leftSectionBackStyle_selectedUsersStyle}>
                                    <SelectedUsersRow 
                                        selectedUsers={props.type === POPUPS_TYPES.NEW_EVENT ? newEvent.eventParticipants : selectedUsers}
                                    />
                                </div>
                                
                                {renderActionButtons()}
                                
                            </div>

                            <div className={styles.middleSectionBackStyle} style={{ width: filterPopupVisible ? '40%'  : '40%' }}>
                                <UsersSection
                                    users={presentedUsers}
                                    filtersActive={filtersActive}
                                    center={props.center}
                                    defaultSelectedUsers={selectedUsers}
                                    filterAction={(value) => filterSectionActionHandler(value)}
                                    userAdded={(users) => {props.type === POPUPS_TYPES.NEW_EVENT ? setEventParticipants(users) : setSelectedUsers(users)}} />
                            </div>
                                
                            {filterPopupVisible &&
                                <div className={styles.rightSectionBackStyle} style={{ width: filterPopupVisible ? '20%'  : '0%' }}>
                                    <SmallFilterPopups
                                        center={props.center}
                                        filterAction={(isLonely, isCombat, isOfficer, isStudy, isOther, birthdayFromYear, birthdayFromMonth, birthdayToYear, birthdayToMonth, dischargedFromYear, dischargedFromMonth, dischargedToYear, dischargedToMonth, currentStatus, selectedKeywords) => filterActionHandler(isLonely, isCombat, isOfficer, isStudy, isOther, birthdayFromYear, birthdayFromMonth, birthdayToYear, birthdayToMonth, dischargedFromYear, dischargedFromMonth, dischargedToYear, dischargedToMonth, currentStatus, selectedKeywords)}
                                    />
                                </div>
                            }

                        </motion.div>
                    </motion.div>
                </>
            )}
         </AnimatePresence>
    )
}


export default NewPopup