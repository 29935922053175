import generalModalStyles from '../../../styles/GeneralModal.module.css'
import styles from '../GeneralPopups/SureModal.module.css'
import { motion, AnimatePresence } from "framer-motion"
import strings from '../../../assets/strings'


const SureModal = (props) => {

    return(
      <AnimatePresence>
        {props.isOpen && (
            <>
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: 0.3 } }} exit={{ opacity: 0 }} onClick={() => props.noActionEvent()} className={generalModalStyles.backViewStyle} />

                <motion.div initial={{ scale: 0 }} animate={{ scale: 1, transition: { delay: 0.3, duration: 0.3 } }} exit={{ scale: 0 }} className={styles.backViewWrapperStyle}>
                    <motion.div className={styles.modalContentStyle}>
                        <div className={styles.headerBackViewStyle}>
                            <text className={styles.headerTextStyle}>{strings.ARE_YOU_SURE_POPUP_TITLE}</text>
                        </div>

                        <div className={generalModalStyles.footerBackViewStyle}>
                            <button className={generalModalStyles.modalButtonStyle} onClick={() => props.yesActionEvent()}>{strings.ARE_YOU_SURE_POPUP_YES}</button>
                            <button className={generalModalStyles.modalButtonStyle} onClick={() => props.noActionEvent()}>{strings.ARE_YOU_SURE_POPUP_NO}</button>
                        </div>
                    </motion.div>
                </motion.div>
            </>
        )}
      </AnimatePresence>
    )
}


export default SureModal