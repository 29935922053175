import { createSlice } from "@reduxjs/toolkit"



const usersSlice = createSlice({
    name: 'users',
    initialState: {
        users: []
    },
    reducers: {
        saveUsers: (state, action) => {
            state.users = action.payload.users
        },
        addUser: (state, action) => {
            state.users.push(action.payload.user)
        },
        cleanAllUsers: (state, action) => {
            state.users = []
        }
    }
})



export const saveUsers = usersSlice.actions.saveUsers
export const cleanAllUsers = usersSlice.actions.cleanAllUsers
export default usersSlice.reducer