import styles from '../../popups/UsersPopups/UserNotesModal.module.css'
import generalModalStyles from '../../../styles/GeneralModal.module.css'
import { motion, AnimatePresence } from "framer-motion"
import strings from '../../../assets/strings'
import { useState, useRef } from 'react'


const UserNotesModal = (props) => {

    const [updatedUser, setUpdatedUser] = useState({ notes: null })
    const notesInputRef = useRef()


    const getNotes = () => {
        if (updatedUser.notes !== null){
            return updatedUser.notes
        }
        if (props.user.notes !== null){
            return props.user.notes
        }
        return ''
    }

    const setNotes = () => {
        const notes = notesInputRef.current.value
        setUpdatedUser({...updatedUser, notes: notes})
    }


    const getFinalUser = () => {
        return { notes: getNotes() }
    }


    const getChangedDataMessage = () => {
        var tempMessage = ""
        if (updatedUser.notes !== null){
            tempMessage = tempMessage + " " + strings.EDIT_USER_LOG_MESSAGE_NOTES
        }
        return tempMessage
    }


    const saveActionHandler = () => {
        props.updateUserAction(getFinalUser(), getChangedDataMessage())
        setUpdatedUser({ notes: null })
    }


    const closeEventHandler = () => {
        props.closeEvent()
        setUpdatedUser({ notes: null })
    }


    return(
        <AnimatePresence>
            {props.isOpen && (
            <>
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: 0.3 } }} exit={{ opacity: 0 }} onClick={() => props.closeEvent()} className={generalModalStyles.backViewStyle} />
                    <motion.div initial={{ scale: 0 }} animate={{ scale: 1, transition: { delay: 0.3, duration: 0.3 } }} exit={{ scale: 0 }} className={styles.backViewWrapperStyle}>
                        <motion.div className={styles.mainViewStyle}>
                            <div className={styles.mainSectionBackStyle}>

                                <div className={styles.headerStyle}>
                                    <text className={generalModalStyles.headerTextStyle}>{strings.EDIT_USER_NOTES_POPUP_TITLE}</text>
                                </div>

                                <div className={styles.mainSectionStyle}>
                                    <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row'}}>
                                        <textarea placeholder={strings.NEW_USER_NOTES_INPUT_TEXT} value={getNotes()} className={styles.controlStyle} onChange={() => setNotes()} ref={notesInputRef} type="input" id='search'/>
                                    </div>
                                </div>

                            </div>

                            <div className={styles.footerViewStyle}>
                                <button className={styles.leftSectionBackStyle_footerViewStyle_buttonStyle} onClick={() => closeEventHandler()}>{strings.CLOSE_BUTTON}</button>
                                <button className={styles.leftSectionBackStyle_footerViewStyle_buttonStyle} onClick={() => saveActionHandler()}>{strings.NEW_USER_INPUT_CREATE_BUTTON}</button>
                            </div>

                        </motion.div>
                    </motion.div>
                </>
            )}
        </AnimatePresence>
    )
}


export default UserNotesModal