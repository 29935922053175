import React, { useState, useEffect, useRef } from 'react'
import UsersSelectorList from '../../lists/UsersSelectorList'
import generalStyles from '../../../styles/General.module.css'
import styles from './UsersSection.module.css'
import strings from '../../../assets/strings'
import { COLORS } from '../../../constants/constants'


const UsersSection = (props) => {

    const [selectedUsers, setSelectedUsers] = useState([])
    const [selectAll, setSelectAll] = useState(false)
    const [filter, setFilter] = useState(false)
    const [searchText, setSearchText] = useState("")
    const searchInputRef = useRef()


    useEffect(() => {
        props.userAdded(selectedUsers)
    }, [selectedUsers])


    const getUsersList = () => {
        var tempList = props.users
        if (searchText !== null){
          tempList = props.users.filter(user => user.name !== undefined && user.name.includes(searchText))
        }
        return tempList
    }


    const selectAllEventHandler = () => {
        if (selectAll){
            setSelectAll(false)
            setSelectedUsers([])
        }else{
            setSelectAll(true)
            setSelectedUsers(props.users)
        }
    }


    const isUserAlreadyAdded = (user) => {
        if (selectedUsers.length === 0){
            return false
        }
        const temp = selectedUsers.filter(u => u.phone == user.phone)
        return temp.length > 0
    }


    const onItemClickHandler = (user) => {
        if (isUserAlreadyAdded(user)){
            setSelectedUsers(selectedUsers.filter(u => u.phone !== user.phone))
        }else{
            setSelectedUsers([...selectedUsers, user])
        }
    }


    const filterActionHandler = () => {
        setFilter(!filter)
        props.filterAction(filter)
    }


    return (
        <div className={styles.mainBackViewStyle}>
            <div className={styles.mainBackViewStyle_topSection}>

                <div className={styles.mainBackViewStyle_topSection_top}>
                    <input placeholder={strings.SEARCH_PLACEHOLDER} className={styles.controlStyle} onChange={() => setSearchText(searchInputRef.current.value)} ref={searchInputRef} type='text' id='search'/>
                    <text className={`${generalStyles.text16}`} style={{ width: 50, margin: 5, cursor: 'pointer', color: props.filtersActive ? COLORS.RED : COLORS.BLUE, textDecoration: 'underline' }} onClick={() => filterActionHandler()}>{strings.FILTER_BUTTON}</text>
                </div>
                <div className={styles.mainBackViewStyle_topSection_bottom}>
                    <text className={`${generalStyles.text16}`}>{strings.SELECT_ALL_CHECKBOX}</text>
                    <input type="checkbox" value={selectAll} name="filters" checked={selectAll} onChange={() => selectAllEventHandler()} />
                </div>
            </div>

            <div className={styles.mainBackViewStyle_middleSection}>
                <UsersSelectorList items={getUsersList()} selectedUsers={selectedUsers} onItemClick={(selectedUser) => onItemClickHandler(selectedUser)} />
            </div>
        </div>
    )
}


export default UsersSection