import { getDatabase, ref, child, get, set } from "firebase/database"
import moment from 'moment'
import { sendPushNotificationAPI, sendSmsAPI } from "./ServerPushesManager"
import { MESSAGE_TYPES } from '../constants/constants'


const CENTER_MESSAGES = "centerMessages/"


export async function saveMessageAPI(users, message, type, centerName){
  const db = getDatabase()
  const tempCenterName = centerName === "ADMIN" ? "ADMIN" : centerName
  set(ref(db, CENTER_MESSAGES + tempCenterName + "/" + moment().valueOf()), {messageStatus: type, messageText: message, users: users.length }).then(() => {

  }).catch((error) => {

  })
}


export async function sendPushMessageAPI(users, message, type, centerName){
    const db = getDatabase()

    if (centerName === "ADMIN"){
      for (var i = 0; i < users.length; i++){
        await sendPushNotificationAPI(users[i].phone, message, type)
      }
      await saveMessageAPI(users, message, type, centerName)

    }else{
      for (var i = 0; i < users.length; i++){
        await sendPushNotificationAPI(users[i].phone, message, type)
        await saveMessageAPI(users, message, type, centerName)
      }
    }
}



export async function sendSmsMessageAPI(users, message, type, centerName){
  const db = getDatabase()

  if (centerName === "ADMIN"){
    for (var i = 0; i < users.length; i++){
      await sendSmsAPI(users[i].phone, message, type, centerName)
    }
    await saveMessageAPI(users, message, type, centerName)

  }else{
    for (var i = 0; i < users.length; i++){
      await sendSmsAPI(users[i].phone, message, type, centerName)
      await saveMessageAPI(users, message, type, centerName)
    }
  }
}



export async function sendDefaultMessageAPI(user, message, type, centerName){
  const db = getDatabase()

  await sendSmsAPI(user, message, type, centerName)

  set(ref(db, CENTER_MESSAGES + centerName + "/" + user.phone + "/" + moment().valueOf()), {messageStatus: type, messageText: message}).then(() => {
    
  }).finally(() => {

      return { status: true, error: null }

  }).catch((error) => {

      return { status: false, error: error }

  })
}



export async function fetchUserMessagesAPI(user, centerName){
    const dbRef = ref(getDatabase())
    return get(child(dbRef, CENTER_MESSAGES + centerName + "/" + `${user.phone}`))
      .then((snapshot) => {
        if (snapshot.exists()) {

          var tempList = []
          snapshot.forEach((jobSnapshot) => {
            var obj = jobSnapshot.val()
            const tempItem = { messageStatus: obj.messageStatus,
                               messageDate: jobSnapshot.key,
                               messageText: obj.messageText }

            tempList = tempList.concat(tempItem)
          })
          return { data: tempList, error: null }
        }
      
      }).catch((error) => {
          return { data: null, error: error }
      }
    )
}


