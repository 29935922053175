import { getDatabase, ref, child, get, set } from "firebase/database"
import strings from '../assets/strings'

const JOBS_URL = "https://us-central1-uapp-c2c68.cloudfunctions.net/api/jobs/preferred"
const STUDY = "study/data"
const MORE = "more/data"
const RIGHTS = "rights/data"


export async function fetchStudyCategoriesAPI(){
    const dbRef = ref(getDatabase())

    return get(child(dbRef, STUDY)).then((snapshot) => {
      if (snapshot.exists()) {
          return { data: snapshot.val(), error: null }
      }
    }).catch((error) => {
        return { data: null, error: error }
    })
}


export async function fetchMoreCategoriesAPI(){
    const dbRef = ref(getDatabase())

   return get(child(dbRef, MORE)).then((snapshot) => {
      if (snapshot.exists()) {
          return { data: snapshot.val(), error: null }
      }
    }).catch((error) => {
        return { data: null, error: error }
    })
}


export async function fetchRightsCategoriesAPI(){
    const dbRef = ref(getDatabase())

    return get(child(dbRef, RIGHTS)).then((snapshot) => {
      if (snapshot.exists()) {
          return { data: snapshot.val(), error: null }
      }
    }).catch((error) => {
        return { data: null, error: error }
    })
}


export async function fetchJobsAPI(){
    const response = await fetch(JOBS_URL);
    const data = await response.json()
    return data
}


//STUDY, MORE, RIGHTS
export async function getDashboardItemsByCategoryAPI(dataRoute){
    
    const dbRef = ref(getDatabase())
    return get(child(dbRef, dataRoute)).then((snapshot) => {
      if (snapshot.exists()) {

        var tempList = []
        snapshot.forEach((jobSnapshot) => {
          var obj = jobSnapshot.val()

          if (dataRoute === "grants" || dataRoute === "lonelySoldiers" || dataRoute === "newcomers" || dataRoute === "scholarships" || dataRoute === "housing"  || dataRoute === "specialOffers" || dataRoute === "benefits"){
            if (jobSnapshot.key === "1" || (dataRoute === "newcomers" && jobSnapshot.key !== "newcomers0") || (dataRoute === "housing" && jobSnapshot.key !== "0_housing") || (dataRoute === "specialOffers" && jobSnapshot.key !== "offer_0")){
              var tempItemsList = []

              Object.values(obj.data).forEach((jobSnapshot) => {
                const tempItem = parseDataHelper(jobSnapshot)
                tempItemsList = tempItemsList.concat(tempItem)
              })

              let categoryName = ""
              if (dataRoute === "grants"){
                categoryName = strings.GRANTS
              }
              if (dataRoute === "lonelySoldiers"){
                categoryName = strings.LONELY_SOLDIERS
              }
              if (dataRoute === "newcomers"){
                categoryName = strings.NEW_COMERS
              }
              if (dataRoute === "scholarships"){
                categoryName = strings.SCHOLARSHIPS
              }
              if (dataRoute === "housing"){
                categoryName = strings.HOUSING
              }
              if (dataRoute === "specialOffers"){
                categoryName = strings.SPECIAL_OFFERS
              }
              if (dataRoute === "benefits"){
                categoryName = strings.BENEFITS
              }

              const tempItem = { category: categoryName, items: tempItemsList }
              tempList = tempList.concat(tempItem)
            }
          }else{
            if (obj.filter !== undefined && obj.data !== undefined){
              var tempItemsList = []
              Object.values(obj.data).forEach((jobSnapshot) => {
                const tempItem = parseDataHelper(jobSnapshot)
                tempItemsList = tempItemsList.concat(tempItem)
              })

              const tempItem = { category: obj.filter, items: tempItemsList }
              tempList = tempList.concat(tempItem)
            }
          }
        })
        return { data: tempList, error: null } 
      }

    }).catch((error) => {

        return { data: null, error: error } 
    })
}


const parseDataHelper = (jobSnapshot) => {
    let tempTitle = ""
    let tempText = ""
    let tempImage = ""

    if (jobSnapshot.title !== undefined){
      tempTitle = jobSnapshot.title
    }
    if (jobSnapshot.text !== undefined){
      tempText = jobSnapshot.text
    }
    if (jobSnapshot.image !== undefined){
      tempImage = jobSnapshot.image
    }
    return { title: tempTitle, text: tempText, image: tempImage }
}





