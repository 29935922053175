import React from 'react'
import styles from './CalendarDayCell.module.css'
import generalStyle from '../../styles/General.module.css'


const CalendarDayCell = (props) => {

    return (
        <div className={styles.backStyle} style={props.styles}>
            <text className={`${styles.dateTextStyle} ${generalStyle.text20}`}>{props.day}</text>
        </div>
    )
}


export default CalendarDayCell
