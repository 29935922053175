import generalStyles from '../../../styles/General.module.css'
import styles from '../Filters/SmallFilterPopups.module.css'
import strings from '../../../assets/strings'
import { COLORS, KEYWORDS } from '../../../constants/constants'
import { useEffect, useRef, useState } from 'react'
import moment from 'moment'


const SmallFilterPopups = (props) => {

    const birthdayFromYearRef = useRef()
    const birthdayFromMonthRef = useRef()
    const birthdayToYearRef = useRef()
    const birthdayToMonthRef = useRef()
    const dischargedFromYearRef = useRef()
    const dischargedFromMonthRef = useRef()
    const dischargedToYearRef = useRef()
    const dischargedToMonthRef = useRef()
    const currentStatusRef = useRef()
    const [isLonely, setIsLonely] = useState(false)
    const [isCombat, setIsCombat] = useState(false)
    const [isOfficer, setIsOfficer] = useState(false)
    const [isStudy, setIsStudy] = useState(false)
    const [isOther, setIsOther] = useState(false)
    const [birthdayFromYear, setBirthdayFromYear] = useState(null)
    const [birthdayFromMonth, setBirthdayFromMonth] = useState(null)
    const [birthdayToYear, setBirthdayToYear] = useState(null)
    const [birthdayToMonth, setBirthdayToMonth] = useState(null)
    const [dischargedFromYear, setDischargedFromYear] = useState(null)
    const [dischargedFromMonth, setDischargedFromMonth] = useState(null)
    const [dischargedToYear, setDischargedToYear] = useState(null)
    const [dischargedToMonth, setDischargedToMonth] = useState(null)
    const [currentStatus, setCurrentStatus] = useState(null)
    const [selectedKeywords, setSelectedKeywords] = useState([])


    useEffect(() => {
        props.filterAction(isLonely, isCombat, isOfficer, isStudy, isOther, birthdayFromYear, birthdayFromMonth, birthdayToYear, birthdayToMonth, dischargedFromYear, dischargedFromMonth, dischargedToYear, dischargedToMonth, currentStatus, selectedKeywords)
    },[isLonely, isCombat, isOfficer, isStudy, isOther, birthdayFromYear, birthdayFromMonth, birthdayToYear, birthdayToMonth, dischargedFromYear, dischargedFromMonth, dischargedToYear, dischargedToMonth, currentStatus, selectedKeywords])


    const getYears = () => {
        var tempYears = [""]
        for (var i = moment().year(); i > 1950; i--){
            tempYears.push(i)
        }
        return tempYears
    }


    const getMonth = () => {
        var tempYears = [""]
        for (var i = 1; i < 12; i++){
            tempYears.push(i)
        }
        return tempYears
    }


    const setCurrentStatusValue = () => {
        const currentStatus = currentStatusRef.current.value
        setCurrentStatus(currentStatus)
    }


    const keywordIsSelected = (keyword) => {
        return selectedKeywords.includes(keyword)
    }


    const addKeyword = (keyword) => {
        if (keywordIsSelected(keyword)){
            const temp = selectedKeywords.filter(item => item !== keyword)
            setSelectedKeywords(temp)
        }else{
            const temp = [...selectedKeywords, keyword]
            setSelectedKeywords(temp)
        }
    }


    const cleanAllAction = () => {
        setIsLonely(false)
        setIsCombat(false)
        setIsOfficer(false)
        setIsStudy(false)
        setIsOther(false)
        setBirthdayFromYear("")
        setBirthdayToYear("")
        setBirthdayFromMonth("")
        setBirthdayToMonth("")
        setDischargedFromYear("")
        setDischargedFromMonth("")
        setDischargedToYear("")
        setDischargedToMonth("")
        setCurrentStatus(null)
        setSelectedKeywords([])
    }


    const renderKeywordsAndSentences = () => {
        if (props.center !== undefined){
            return (
            props.center.centerKeywords.map((centerKeyword) => {
                return (
                    <div style={{marginTop: 5, marginBottom: 5, flexDirection: 'row', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%'}}>
                        <text style={{marginRight: 5}} className={`${generalStyles.text16}`}>{centerKeyword[1]}</text>
                        <input style={{marginRight: 20}} type="checkbox" name="filters" checked={keywordIsSelected(centerKeyword[1])} onChange={() => addKeyword(centerKeyword[1])} />
                    </div>
                )
            })
            )
        }
    }
    

    return(
        <div className={styles.mainBack}>
            <div style={{flexDirection: 'column', marginTop: 10, marginBottom: 10, display: 'flex'}}>
                <div style={{marginBottom: 5, flexDirection: 'row', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%'}}>
                    <text style={{marginRight: 5}} className={`${generalStyles.text16}`}>{strings.IS_LONELY}</text>
                    <input style={{marginRight: 20}} type="checkbox" value={1} name="filters" checked={isLonely} onChange={() => setIsLonely(!isLonely)} />
                </div>
                <div style={{marginTop: 5, marginBottom: 5, flexDirection: 'row', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%'}}>
                    <text style={{marginRight: 5}} className={`${generalStyles.text16}`}>{strings.IS_COMBAT}</text>
                    <input style={{marginRight: 20}} type="checkbox" value={1} name="filters" checked={isCombat} onChange={() => setIsCombat(!isCombat)} />
                </div>
                <div style={{marginTop: 5, marginBottom: 5, flexDirection: 'row', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%'}}>
                    <text style={{marginRight: 5}} className={`${generalStyles.text16}`}>{strings.IS_OFFICER}</text>
                    <input style={{marginRight: 20}} type="checkbox" value={1} name="filters" checked={isOfficer} onChange={() => setIsOfficer(!isOfficer)} />
                </div>
                <div style={{marginTop: 5, marginBottom: 5, flexDirection: 'row', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%'}}>
                    <text style={{marginRight: 5}} className={`${generalStyles.text16}`}>{strings.IS_STUDY}</text>
                    <input style={{marginRight: 20}} type="checkbox" value={1} name="filters" checked={isStudy} onChange={() => setIsStudy(!isStudy)} />
                </div>
                <div style={{marginTop: 5, flexDirection: 'row', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%'}}>
                    <text style={{marginRight: 5}} className={`${generalStyles.text16}`}>{strings.IS_OTHER}</text>
                    <input style={{marginRight: 20}} type="checkbox" value={1} name="filters" checked={isOther} onChange={() => setIsOther(!isOther)} />
                </div>
            </div>

            <div style={{marginBottom: 5, minHeight: 1, display: 'flex', minWidth: '100%', backgroundColor: COLORS.BLUE}}></div>

            <div style={{marginBottom: 5, flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '50%'}}>
                {renderKeywordsAndSentences()}
            </div>

            <div style={{marginBottom: 5, minHeight: 1, display: 'flex', minWidth: '100%', backgroundColor: COLORS.BLUE}}></div>
        
            <div style={{marginTop: 5, marginBottom: 5, flexDirection: 'column', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%'}}>
                    
                <div style={{margin: 5, flexDirection: 'column', display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                    <text style={{margin: 5}} className={`${generalStyles.text16}`}>{strings.NEW_USER_OPTION_DEFAULT_INPUT_TEXT}</text>
                    <select value={currentStatus} className={styles.datePickerStyle} onChange={() => setCurrentStatusValue()} ref={currentStatusRef}>
                        <option value={""}>{}</option>
                        <option value={strings.NEW_USER_OPTION_1_INPUT_TEXT}>{strings.NEW_USER_OPTION_1_INPUT_TEXT}</option>
                        <option value={strings.NEW_USER_OPTION_2_INPUT_TEXT}>{strings.NEW_USER_OPTION_2_INPUT_TEXT}</option>
                        <option value={strings.NEW_USER_OPTION_3_INPUT_TEXT}>{strings.NEW_USER_OPTION_3_INPUT_TEXT}</option>
                        <option value={strings.NEW_USER_OPTION_4_INPUT_TEXT}>{strings.NEW_USER_OPTION_4_INPUT_TEXT}</option>
                    </select>
                </div>

                <div style={{margin: 5, minHeight: 50, flexDirection: 'row', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%'}}>
                    <div style={{flexDirection: 'column', display: 'flex' }}>

                        <div style={{margin: 5, flexDirection: 'row', display: 'flex' }}>
                            <select value={birthdayFromMonth} className={styles.datePickerStyle} onChange={() => setBirthdayFromMonth(birthdayFromMonthRef.current.value)} ref={birthdayFromMonthRef}>
                                <option value={""}></option>
                                {getMonth().map(month => {
                                    return (
                                        <option value={month}>{month}</option>
                                    )
                                })}
                            </select>
                            <select value={birthdayFromYear} className={styles.datePickerStyle} onChange={() => setBirthdayFromYear(birthdayFromYearRef.current.value)} ref={birthdayFromYearRef}>
                                <option value={""}></option>
                                {getYears().map(year => {
                                    return (
                                        <option value={year}>{year}</option>
                                    )
                                })}
                            </select>
                            <text style={{margin: 5}} className={`${generalStyles.text16}`}>{strings.FROM_KEY}</text>
                        </div>

                        <div style={{margin: 5, flexDirection: 'row', display: 'flex' }}>
                            <select value={birthdayToMonth} className={styles.datePickerStyle} onChange={() => setBirthdayToMonth(birthdayToMonthRef.current.value)} ref={birthdayToMonthRef}>
                                {getMonth().map(month => {
                                    return (
                                        <option value={month}>{month}</option>
                                    )
                                })}
                            </select>
                            <select value={birthdayToYear} className={styles.datePickerStyle} onChange={() => setBirthdayToYear(birthdayToYearRef.current.value)} ref={birthdayToYearRef}>
                                {getYears().map(year => {
                                    return (
                                        <option value={year}>{year}</option>
                                    )
                                })}
                            </select>
                            <text style={{margin: 5}} className={`${generalStyles.text16}`}>{strings.TO_KEY}</text>
                        </div>

                    </div>
                    <text style={{margin: 5}} className={`${generalStyles.text16}`}>{strings.FILTER_BIRTHDAY_YEAR}</text>
                </div>
                
                <div style={{margin: 5, minHeight: 50, flexDirection: 'row', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%'}}>
                    <div style={{flexDirection: 'column', display: 'flex' }}>

                        <div style={{margin: 5, flexDirection: 'row', display: 'flex' }}>
                            <select value={dischargedFromMonth} className={styles.datePickerStyle} onChange={() => setDischargedFromMonth(dischargedFromMonthRef.current.value)} ref={dischargedFromMonthRef}>
                                <option value={""}></option>
                                {getMonth().map(month => {
                                    return (
                                        <option value={month}>{month}</option>
                                    )
                                })}
                            </select>
                            <select value={dischargedFromYear} className={styles.datePickerStyle} onChange={() => setDischargedFromYear(dischargedFromYearRef.current.value)} ref={dischargedFromYearRef}>
                                <option value={""}></option>
                                {getYears().map(year => {
                                    return (
                                        <option value={year}>{year}</option>
                                    )
                                })}
                            </select>
                            <text style={{margin: 5}} className={`${generalStyles.text16}`}>{strings.FROM_KEY}</text>
                        </div>

                        <div style={{margin: 5, flexDirection: 'row', display: 'flex' }}>
                            <select value={dischargedToMonth} className={styles.datePickerStyle} onChange={() => setDischargedToMonth(dischargedToMonthRef.current.value)} ref={dischargedToMonthRef}>
                                <option value={""}></option>
                                {getMonth().map(month => {
                                    return (
                                        <option value={month}>{month}</option>
                                    )
                                })}
                            </select>
                            <select value={dischargedToYear} className={styles.datePickerStyle} onChange={() => setDischargedToYear(dischargedToYearRef.current.value)} ref={dischargedToYearRef}>
                                <option value={""}></option>
                                {getYears().map(year => {
                                    return (
                                        <option value={year}>{year}</option>
                                    )
                                })}
                            </select>
                            <text style={{margin: 5}} className={`${generalStyles.text16}`}>{strings.TO_KEY}</text>
                        </div>

                    </div>
                    <text style={{margin: 5}} className={`${generalStyles.text16}`}>{strings.FILTER_DISCHARGE_YEAR}</text>
                </div>

                <div style={{marginBottom: 5, marginTop: 5, minHeight: 1, display: 'flex', minWidth: '100%', backgroundColor: COLORS.BLUE}}></div>

                <div style={{margin: 5, minHeight: 50, textDecoration: 'underline', cursor: 'pointer', flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
                    <text onClick={() => cleanAllAction()} style={{margin: 5}} className={`${generalStyles.text16}`}>{strings.FILTER_CLEAN_ALL}</text>
                </div>
                    
            </div>

        </div>
    )
}


export default SmallFilterPopups