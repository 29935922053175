import React from 'react'
import UserSelectorListItem from '../popups/UsersSelector/UserSelectorListItem'
import generalStyles from '../../styles/GeneralModal.module.css'


const UsersSelectorList = (props) => {

    
    const isUserAlreadyAdded = (user) => {
        if (props.selectedUsers.length === 0){
            return false
        }
        const temp = props.selectedUsers.filter(u => u.phone === user.phone)
        if (temp.length > 0){
            return true
        }else{
            return false
        }
    }


    return (
        <div className={generalStyles.listStyle}>
            {props.items.map(item => (
                <UserSelectorListItem
                    isSelected={isUserAlreadyAdded(item)}
                    key={item.phone} 
                    id={item.phone}
                    onItemClick={() => props.onItemClick(item)}>
                    {item.name}
                </UserSelectorListItem>
            ))}
        </div>
    )
}


export default UsersSelectorList