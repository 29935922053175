import React from 'react'
import styles from '../../popups/UsersPopups/DragAndDropModal.module.css'
import generalModalStyles from '../../../styles/GeneralModal.module.css'
import { motion, AnimatePresence } from "framer-motion"
import strings from '../../../assets/strings'
import { useState, useRef } from 'react'
import moment from 'moment'
import { createUserAPI } from '../../../managers/ServerUsersManager'
import { showErrorMessage, showSuccessMessage } from '../../../pages/SystemMessagesManager'
import { RingLoader } from 'react-spinners'
import generalStyles from '../../../styles/General.module.css'


const DragAndDropModal = (props) => {

    const defaultHeaders = ["phone", "email", "name", "surname", "birthDate"]
    const defaultValues = ["(05XXXXXXXX)", "XXX@XXX.XXX", "(text)", "(text)", "(dd/MM/YYYY)"]
    const [file, setFile] = useState(null)
    const [array, setArray] = useState([])
    const [users, setUsers] = useState([])
    const fileReader = new FileReader()
    const headerKeys = Object.keys(Object.assign({}, ...array))
    const hiddenFileInput = React.useRef(null);
    const [loader, setLoader] = useState(false)


    const handleOnChange = (e) => {
        setFile(e.target.files[0]);
    };


    const handleClick = event => {
        hiddenFileInput.current.click();
    };    


    const handleOnSubmit = (e) => {
        e.preventDefault()
    
        if (file) {
          fileReader.onload = function (event) {
            const text = event.target.result
            csvFileToArray(text)
          }
          fileReader.readAsText(file)
        }
    }


    const csvFileToArray = string => {
        const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
        const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");
    
        const tempArray = csvRows.map(i => {
            const values = i.split(",")
  
            const obj = csvHeader.reduce((object, header, index) => {
              object[header] = values[index]
                return object
            }, {})
            return obj
          })

        const tempUsers = csvRows.map(i => {
          const values = i.split(",")

          const user = { phone: null, 
                         email: null,
                         firstName: null, 
                         lastName: null,
                         currentStatus: null,
                         birthDate: null, 
                         dischargeDate: null, 
                         isCombat: null, 
                         isLonely: null, 
                         isOfficer: null, 
                         isStudy: null,
                         other: null,
                         notes: null }
          
          const obj1 = csvHeader.reduce((object, header, index) => {
            object[header] = values[index]

            if (header.toLowerCase() === defaultHeaders[0].toLowerCase()){
                user.phone = values[index]
            }
            if (header.toLowerCase() === defaultHeaders[1].toLowerCase()){
                user.email = values[index]
            }
            if (header.toLowerCase() === defaultHeaders[2].toLowerCase()){
                user.firstName = values[index]
            }
            if (header.toLowerCase() === defaultHeaders[3].toLowerCase()){
                user.lastName = values[index]
            }
            if (header.toLowerCase() === defaultHeaders[4].toLowerCase()){
                const birthDate = moment(values[index], 'YYYY-MM-DD').format('DD/MM/YYYY')
                user.birthDate = birthDate
            }
            return object
            
          }, {})
          return user
        })

        setArray(tempArray)
        setUsers(tempUsers)
    }



    const handleOnSave = async () => {
        setLoader(true)
        var tempUploadedCounter = 0
        var tempUnUploadedCounter = 0
        for (var i = 0; i < users.length; i++){
            if (users[i].phone !== null && users[i].phone !== ""){
                const responseStatus = await createUserAPI(props.center.centerName, users[i])
                if (responseStatus){
                    tempUploadedCounter = tempUploadedCounter + 1
                }else{
                    tempUnUploadedCounter = tempUnUploadedCounter + 1
                }
            }
        }
        setLoader(false)

        if (tempUploadedCounter > 0){
            showSuccessMessage("UPLOADED: " + tempUploadedCounter)
        }
        if (tempUnUploadedCounter > 0){
            showErrorMessage("NOT UPLOADED: " + tempUnUploadedCounter)
        }

        setArray([])
        setFile()
        setUsers([])
        props.doneEvent()
    };


    const handleOnClose = () => {
        setArray([])
        setFile()
        setUsers([])
        props.closeEvent()
    }


    return(
        <AnimatePresence>
            {props.isOpen && (
            <>
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: 0.3 } }} exit={{ opacity: 0 }} onClick={handleOnClose} className={generalModalStyles.backViewStyle} />
                    <motion.div initial={{ scale: 0 }} animate={{ scale: 1, transition: { delay: 0.3, duration: 0.3 } }} exit={{ scale: 0 }} className={styles.backViewWrapperStyle}>
                        <motion.div className={styles.mainSectionBackStyle}>
                            
                            <div className={styles.headerStyle}>
                                <text className={generalModalStyles.headerTextStyle}>{strings.DRAG_AND_DROP_POPUP_TITLE}</text>
                            </div>

                            <div className={styles.mainDraggableSectionStyle}>

                            {(array.length == 0) && 
                                <table className={styles.tableStyle}>
                                    <thead>
                                        <tr key={"header"}>
                                            {defaultHeaders.map((key) => (
                                                <th>{key}</th>
                                            ))}
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr key={"row"}>
                                        {defaultValues.map((val) => (
                                            <td style={{textAlign: 'center'}} >{val}</td>
                                        ))}
                                        </tr>
                                    </tbody>
                                </table>
                            }
                            	
                            {(array.length > 0) && 
                                <table className={styles.tableStyle}>
                                    <thead>
                                    <tr key={"header"}>
                                        {headerKeys.map((key) => (
                                        <th>{key}</th>
                                        ))}
                                    </tr>
                                    </thead>
                            
                                    <tbody>
                                    {array.map((item) => (
                                        <tr key={item.id}>
                                        {Object.values(item).map((val) => (
                                            <td style={{textAlign: 'center'}} >{val}</td>
                                        ))}
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            }
                            </div>
                            	
                            <div className={styles.footerViewStyle}>
                                <button className={styles.leftSectionBackStyle_footerViewStyle_buttonStyle} onClick={handleOnClose}>{strings.DRAG_AND_DROP_POPUP_CLOSE}</button>
                                
                                {!file && 
                                    <>
                                        <button className={styles.leftSectionBackStyle_footerViewStyle_buttonStyle} onClick={handleClick}>
                                            {strings.DRAG_AND_DROP_POPUP_UPLOAD}
                                        </button>

                                        <input
                                            type={"file"}
                                            ref={hiddenFileInput}
                                            id={"csvFileInput"}
                                            accept={".csv"}
                                            onChange={handleOnChange}
                                            style={{display: 'none'}}
                                        />
                                    </>
                                }

                                {(file && array.length == 0) && 
                                   <button
                                        className={styles.leftSectionBackStyle_footerViewStyle_buttonStyle}
                                        onClick={(e) => {
                                            handleOnSubmit(e)
                                        }}>{strings.DRAG_AND_DROP_POPUP_PARSE}
                                    </button>
                                }

                                {(file && array.length > 0) && 
                                   <button
                                        className={styles.leftSectionBackStyle_footerViewStyle_buttonStyle}
                                        onClick={(e) => {
                                            setFile()
                                            setArray([])
                                        }}>{strings.DRAG_AND_DROP_POPUP_REMOVE}
                                    </button>
                                }
                                
                                {array.length > 0 && 
                                   <button
                                        className={styles.leftSectionBackStyle_footerViewStyle_buttonStyle}
                                        onClick={(e) => {
                                            handleOnSave(e)
                                        }}>{strings.DRAG_AND_DROP_POPUP_SAVE}
                                    </button>
                                }
                            </div>

                        </motion.div>
                    </motion.div>
                </>
            )}

            <div className={generalStyles.loaderBackView}>
                {loader && <RingLoader color="#176ED0" />}
            </div>

        </AnimatePresence>
    )
}


export default DragAndDropModal
