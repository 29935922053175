export default {
    MONTH_JAN: "ינואר",
    MONTH_FEB: "פברואר",
    MONTH_MAR: "מרץ",
    MONTH_APR: "אפריל",
    MONTH_MAY: "מאי",
    MONTH_JUN: "יוני",
    MONTH_JUL: "יולי",
    MONTH_AUG: "אוגוסט",
    MONTH_SEP: "ספטמבר",
    MONTH_OCT: "אוקטובר",
    MONTH_NOV: "נובמבר",
    MONTH_DEC: "דצמבר",

    LOGIN_PAGE_TITLE: "ברוכים הבאים",
    LOGIN_EMAIL: "מייל",
    LOGIN_PASSWORD: "סיסמה",
    LOGIN_BUTTON_ACTION: "כניסה",
    LOGIN_FORGOT_PASSWORD: "שכחתי סיסמה",
    LOGIN_LOGIN_BUTTON: "כניסה",
    LOGIN_FORGOT_PASSWORD_BUTTON: "שלח",

    SIDE_MENU_DASHBOARD: "ראשי",
    SIDE_MENU_USERS: "משתמשים",
    SIDE_MENU_EVENTS: "אירועים",
    SIDE_MENU_REPORTS: "דוחות",
    SIDE_MENU_SETTINGS: "הגדרות",
    SIDE_MENU_LOGOUT: "התנתקות",
    SIDE_MENU_CENTERS: "מרכזים",
    SIDE_MENU_MESSAGES: "הודעות",

    USERS_FILTERS_ALL: "כולם",
    USERS_FILTERS_APPROVED: "משתמשים",
    USERS_FILTERS_NOT_APPROVED: "לא משתמשים",

    DETAILS_BUTTON: "פרטים",
    GRANTS: "מענקים",
    LONELY_SOLDIERS: "חיילים בודדים",
    NEW_COMERS: "עולים חדשים",
    SCHOLARSHIPS: "מלגות",
    HOUSING: "דיור",
    SPECIAL_OFFERS: "מבצעים חמים",
    BENEFITS: "פיננסי",

    CLOSE_BUTTON: "סגור",
    SHARE_PUSH_BUTTON: "נוטיפקציה",
    SHARE_SMS_BUTTON: "סמס",
    SHARE_WHATSAPP_BUTTON: "ווטסאפ",
    SHARE_EMAIL_BUTTON: "מייל",

    IS_LONELY: "בודד/ת",
    IS_COMBAT: "לוחם/ת",
    IS_OFFICER: "קצין/ה",
    IS_STUDY: "עתודאי/ת",
    IS_OTHER: "נהג",

    MESSAGES_DEFAULT_BUTTON: "לשלוח זימון לאפליקציה",
    MESSAGES_DEFAULT_TEXT: "נשלחה הודעה שכוללת הזמנה להורדה של אפליקציה",

    NEW_SELECTOR_NEW_MESSAGE_1: "הודעה",
    NEW_SELECTOR_NEW_USER_1: "משתמש",
    NEW_SELECTOR_UPLOAD_FILE_1: "לעלות",
    NEW_SELECTOR_NEW_MESSAGE_2: "חדשה",
    NEW_SELECTOR_NEW_USER_2: "חדש",
    NEW_SELECTOR_UPLOAD_FILE_2: "מקובץ",

    NEW_MESSAGE_POPUP_TITLE: "הודעה חדשה",
    NEW_MESSAGE_INPUT_TEXT: "הודעה",
    NEW_MESSAGE_INPUT_LINK: "קישור",
    NEW_MESSAGE_INPUT_SEND_BUTTON: "שלח",
    NEW_MESSAGE_INPUT_SEND_TO_BUTTON: "שלח ל ",

    DRAG_AND_DROP_POPUP_TITLE: "לעלות משתמשים מקובץ",
    DRAG_AND_DROP_POPUP_CLOSE: "סגור",
    DRAG_AND_DROP_POPUP_UPLOAD: "לעלות קובץ",
    DRAG_AND_DROP_POPUP_PARSE: "תצוגה",
    DRAG_AND_DROP_POPUP_SAVE: "שמור",
    DRAG_AND_DROP_POPUP_REMOVE: "מחק",

    NEW_USER_POPUP_TITLE: "משתמש חדש",
    EDIT_USER_POPUP_TITLE: "עדכון פרטים",
    EDIT_USER_NOTES_POPUP_TITLE: "הערות",
    NEW_USER_INPUT_CREATE_BUTTON: "שמור",
    NEW_USER_INPUT_DELETE_BUTTON: "מחק",
    NEW_USER_ID_PLACEHOLDER: "ת.ז.",
    NEW_USER_FIRST_NAME_PLACEHOLDER: "שם פרטי",
    NEW_USER_CITY_PLACEHOLDER: "עיר",
    NEW_USER_LAST_NAME_PLACEHOLDER: "שם משפחה",
    NEW_USER_PHONE_NUMBER_PLACEHOLDER: "מספר טלפון",
    NEW_USER_EMAIL_PLACEHOLDER: "מייל",
    NEW_USER_NOTES_INPUT_TEXT: "טקסט חופשי",
    NEW_USER_LONELY_INPUT_TEXT: "בודד/ת",
    NEW_USER_COMBAT_INPUT_TEXT: "לוחם/ת",
    NEW_USER_OFFICER_INPUT_TEXT: "קצין/ה",
    NEW_USER_ACADEMY_INPUT_TEXT: "עתודאי/ת",
    NEW_USER_OTHER_INPUT_TEXT: "נהג/ת",
    DELETE_USER_ARE_YOU_SURE_TITLE: "מחיקת משתמש",
    DELETE_USER_ARE_YOU_SURE_SUB_TITLE: "בטוח למחוק משתמש ",
    DELETE_USER_ARE_YOU_SURE_YES_BUTTON: "כן",
    DELETE_USER_ARE_YOU_SURE_NO_BUTTON: "לא",

    NEW_USER_OPTION_1_INPUT_TEXT: "חייל/ת בשירות צבאי",
    NEW_USER_OPTION_2_INPUT_TEXT: "ביצעתי שירות צבאי",
    NEW_USER_OPTION_3_INPUT_TEXT: "ביצעתי שירות לאומי",
    NEW_USER_OPTION_4_INPUT_TEXT: "ביצעתי שירות אזרחי",
    NEW_USER_OPTION_DEFAULT_INPUT_TEXT: "שירות בצבאי",

    NEW_USER_BIRTHDAY_DATE_TITLE: "תאריך יום הולדת",
    NEW_USER_DISCHARGE_DATE_TITLE: "תאריך שיחרור",

    NEW_EVENT_POPUP_TITLE: "אירוע חדש",
    EDIT_EVENT_POPUP_TITLE: "עריכת אירוע",
    NEW_EVENT_INPUT_TITLE: "כותרת",
    NEW_EVENT_INPUT_TEXT: "טקסט",
    NEW_EVENT_INPUT_SHARE_BUTTON: "שלח זימון",
    NEW_EVENT_INPUT_SAVE_BUTTON: "שמור",
    NEW_EVENT_INPUT_SHARE_TO_BUTTON: "שלח זימון ל",
    NEW_EVENT_DIRECTION_TEXT: "משך",
    NEW_EVENT_TIME_TEXT: "שעה",
    NEW_EVENT_DATE_TEXT: "תאריך",
    NEW_EVENT_DIRECTION_MIN_TEXT: "דקות",
    NEW_EVENT_DIRECTION_HOUR_TEXT: "שעות",

    SEARCH_PLACEHOLDER: "חיפוש",
    SELECT_ALL_CHECKBOX: "לסמן את כולם",
    FILTER_BUTTON: "מיון",
    FILTER_TITLE: "מיון",
    FILTER_CLEAN_ALL: "נקה הכל",
    FILTER_CLOSE_ACTION: "סגור",
    FILTER_BIRTHDAY_YEAR: "שנת לידה",
    FILTER_DISCHARGE_YEAR: "שנת שיחרור",

    SYSTEM_MESSAGE_MESSAGE_SENDED_SUCCESSFULLY: "הודעה לשלחה בהצלחה",
    SYSTEM_MESSAGE_MESSAGE_SENDED_ERROR: "שגיאה בשליחת הודעה",
    SYSTEM_MESSAGE_EVENT_CREATED_SUCCESSFULLY: "אירוע נוזר בהצלחה",
    SYSTEM_MESSAGE_EVENT_CREATED_ERROR: "שגיאה ביצירת אירוע",
    SYSTEM_MESSAGE_DASHBOARD_ITEM_SHARED_SUCCESSFULLY: "משרה נשלחה בהצלחה",
    SYSTEM_MESSAGE_DASHBOARD_ITEM_SHARED__ERROR: "שגיאה בשיתוף משרה",
    SYSTEM_MESSAGE_GENERAL_ERROR: "שגיאה כללית",
    SYSTEM_MESSAGE_USER_ADDED_SUCCESSFULLY: "משתמש נוסף בהצלחה",
    SYSTEM_MESSAGE_USER_ADDED_ERROR: "שגיאה ביצירת משתמש",

    SYSTEM_MESSAGE_LOGIN_ERROR: "שגיאה בהתחברות",
    SYSTEM_MESSAGE_RESTORE_PASSWORD_SUCCESS: "סיסמה נשלחה למייל",
    SYSTEM_MESSAGE_RESTORE_PASSWORD_ERROR: "שגיאה בשליחת סיסמה",
    SYSTEM_MESSAGE_SETTINGS_SAVED_SUCCESSFULLY: "שינויים נשמרו בהצלחה",
    SYSTEM_MESSAGE_SETTINGS_SAVED_ERROR: "שגיאה כללית",

    KEYWORD_1_HELP_TEXT_START: "רוצה לקבל",
    KEYWORD_1_HELP_TEXT_END: "עבור לימודים",
    KEYWORD_2_HELP_TEXT_START: "מחפש",
    KEYWORD_2_HELP_TEXT_END: "בתחומים שונים",
    KEYWORD_3_HELP_TEXT_START: "מעוניים להתחיל",
    KEYWORD_3_HELP_TEXT_END: "",
    KEYWORD_4_HELP_TEXT_START: "זקוק ל",
    KEYWORD_4_HELP_TEXT_END: "מקצועית",
    KEYWORD_5_HELP_TEXT_START: "צריך",
    KEYWORD_5_HELP_TEXT_END: "בשכר דירה",
    KEYWORD_6_HELP_TEXT_START: "מחפש",
    KEYWORD_6_HELP_TEXT_END: "מקצועיים",
    KEYWORD_7_HELP_TEXT_START: "רוצה להתחיל ללמוד",
    KEYWORD_7_HELP_TEXT_END: "ראשון באוניברסיטה",

    NEW_CENTER_TITLE: "יצירת מרכז חדש",
    NEW_CENTER_KEY: "מפתח",
    NEW_CENTER_NAME: "שם",
    NEW_CENTER_CITY: "עיר",
    NEW_CENTER_LOGO: "לוגו",
    NEW_CENTER_KEYWORDS: "מילות מפתח",
    NEW_CENTER_MANAGERS: "מנהלים",

    SETTINGS_EMAIL: "מייל",
    SETTINGS_CITY: "עיר",
    SETTINGS_NAME: "מרכז",
    SETTINGS_PASSWORD: "סיסמה",
    SETTINGS_CONFIRM_PASSWORD: "סיסמה",
    SETTINGS_DEFAULT_MESSAGE: "הודעת הזמנה",
    SETTINGS_KEYWORDS_MESSAGE: "מילות מפתח",
    SETTINGS_BUTTON_SAVE_DATA_CHANGES: "שמור שינויים",
    SETTINGS_BUTTON_SAVE_PASSWORD_CHANGES: "שנה סיסמה",
    SETTINGS_BUTTON_SAVE_MESSAGE_CHANGES: "שמור שינויים",

    ARE_YOU_SURE_POPUP_TITLE: "האם אתה בטוח",
    ARE_YOU_SURE_POPUP_YES: "כן",
    ARE_YOU_SURE_POPUP_NO: "לא",

    FILTER_MESSAGES_ALL: "הכל",
    NOTIFICATION_TITLE_MESSAGE_TYPES_MESSAGE: "הודעה",
    NOTIFICATION_TITLE_MESSAGE_TYPES_INVITE: "הזמנה",
    NOTIFICATION_TITLE_MESSAGE_TYPES_SHARING: "שיתוף",
    NOTIFICATION_TITLE_MESSAGE_TYPES_EVENT: "אירוע",
    NOTIFICATION_TITLE_MESSAGE_TYPES_EDIT: "עדכון",

    REPORT_TOTAL_USERS: "סה״כ",
    REPORT_TOTAL_APP_USERS: "עם אפליקציה",
    REPORT_TOTAL_NOT_APP_USERS: "ללא אפליקציה",
    REPORT_TOTAL_LONELY_USERS: "בודד/ת",
    REPORT_TOTAL_STUDY_USERS: "עתודאי/ת",
    REPORT_TOTAL_COMBAT_USERS: "לוחם/ת",
    REPORT_TOTAL_OFFICER_USERS: "קצין/ה",
    REPORT_TOTAL_DRIVER_USERS: "נהגֿ/ת",
    REPORT_TOTAL_STATUS_1_USERS: "חייל/ת בשירות צבאי",
    REPORT_TOTAL_STATUS_2_USERS: "ביצעתי שירות צבאי",
    REPORT_TOTAL_STATUS_3_USERS: "ביצעתי שירות לאומי",
    REPORT_TOTAL_STATUS_4_USERS: "ביצעתי שירות אזרחי",

    DEFAULT_DOWNLOAD_LINK_TEXT: "להורדת האפליקציה בחינם",

    SUPER_ADMIN_USERS_TOTAL_APP_USERS: "סה״כ",
    SUPER_ADMIN_USERS_TOTAL_ANDROID_USERS: "אנדרויד",
    SUPER_ADMIN_USERS_TOTAL_IOS_USERS: "אייפון",
    SUPER_ADMIN_USERS_TOTAL_UNKNOWN_USERS: "ללא מקור",
    SUPER_ADMIN_FILTER_USERS_TOTAL_APP_USERS: "כולם",
    SUPER_ADMIN_FILTER_USERS_TOTAL_ANDROID_USERS: "אנדרויד",
    SUPER_ADMIN_FILTER_USERS_TOTAL_IOS_USERS: "אייפון",
    SUPER_ADMIN_FILTER_USERS_TOTAL_UNKNOWN_USERS: "ללא מקור",
    SUPER_ADMIN_USERS_TOTAL_LONELY_USERS: "בודד/ת",
    SUPER_ADMIN_USERS_TOTAL_STUDY_USERS: "עתודאי/ת",
    SUPER_ADMIN_USERS_TOTAL_COMBAT_USERS: "לוחם/ת",
    SUPER_ADMIN_USERS_TOTAL_OFFICER_USERS: "קצין/ה",
    SUPER_ADMIN_USERS_TOTAL_DRIVER_USERS: "נהגֿ/ת",
    SUPER_ADMIN_USERS_TOTAL_STATUS_1_USERS: "חייל/ת בשירות צבאי",
    SUPER_ADMIN_USERS_TOTAL_STATUS_2_USERS: "ביצעתי שירות צבאי",
    SUPER_ADMIN_USERS_TOTAL_STATUS_3_USERS: "ביצעתי שירות לאומי",
    SUPER_ADMIN_USERS_TOTAL_STATUS_4_USERS: "ביצעתי שירות אזרחי",

    EMPTY_STATE_TEXT: "אין נתונים לתצוגה",

    EDIT_USER_LOG_MESSAGE_ID_NUMBER: "מספר ת.ז. שונה",
    EDIT_USER_LOG_MESSAGE_FIRST_NAME: "שם פרטי שונה",
    EDIT_USER_LOG_MESSAGE_LAST_NAME: "שם משפחה שונה",
    EDIT_USER_LOG_MESSAGE_BIRTHDAY: "תאריך יום הולדת שונה",
    EDIT_USER_LOG_MESSAGE_CURRENT_STATUS: "סטטוס שונה",
    EDIT_USER_LOG_MESSAGE_DISCHARGE_DATE: "תאריך שיחרור שונה",
    EDIT_USER_LOG_MESSAGE_EMAIL: "מייל שונה",
    EDIT_USER_LOG_MESSAGE_PHONE: "מספר טלפון שונה",
    EDIT_USER_LOG_MESSAGE_FLAGS: "אחד מסטטוסים שונה",
    EDIT_USER_LOG_MESSAGE_NOTES: "הערות שונו",
    EDIT_USER_LOG_MESSAGE_KEWWORDS: "מולות מפתח שונו",

    USER_SUBSECTION_DETAILS: "פרטים",
    USER_SUBSECTION_MESSAGES: "הודעות",
    USER_SUBSECTION_NOTES: "הערות",
    USER_SUBSECTION_OTHERS: "שונות",

    FROM_KEY: "מ",
    TO_KEY: "עד",

    REPORT_ONE: "דוח ביצוע חודשי שמי",
    REPORT_TWO: "דוח הכנה לאזרחות לפריפריה",
    REPORT_THREE: "דוח פעילויות פרויקטאליות",
    REPORT_FORE: "דוח פרויקטים  במרכז",
}