import React from 'react'
import AdminMessagesListItem from '../items/AdminMessagesListItem'
import generalStyles from '../../styles/General.module.css'


const AdminMessagesList = (props) => {

  return (
    <div className={generalStyles.listStyle}>
      {props.items.map(item => (
        <AdminMessagesListItem key={item.date} item={item} />
      ))}
    </div>
  )
}


export default AdminMessagesList