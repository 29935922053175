import React, { useState, useEffect } from 'react'
import LoginPage from './pages/LoginPage'
import MainPage from './pages/MainPage'
import { initializeApp } from "firebase/app"
import { getAuth } from 'firebase/auth'
import SuperAdminMainPage from './pages/SuperAdminMainPage'
import { Provider } from 'react-redux'
import { store } from '../src/redux/Store'


const firebaseConfig = {
    apiKey: "AIzaSyBoP_6HoDqFjnXWWmcyhleWOQ11gJXaUhw",
    authDomain: "uapp-c2c68.firebaseapp.com",
    databaseURL: "https://uapp-c2c68.firebaseio.com",
    projectId: "uapp-c2c68",
    storageBucket: "uapp-c2c68.appspot.com",
    messagingSenderId: "691126193804",
    appId: "1:691126193804:web:4601c33a9b1ffc87a275a9",
    measurementId: "G-PQDRHQTQ5C"
};


function App() {

    const [isLogged, setIsLogged] = useState(false)
    const [isAdmin, setIsAdmin] = useState(false)
    const [localAuth, setLocalAuth] = useState(null)
    

    useEffect(() => {
        const app = initializeApp(firebaseConfig)
        const auth = getAuth(app)
        setLocalAuth(auth)
    },[])


    const loginHandler = (admin) => {
        setIsAdmin(admin)
        setIsLogged(true)
    }


    const logoutHandler = () => {
        setIsLogged(false)
    }


    const renderMainScreen = () => {
        if (isLogged){
            if (isAdmin){
                return (
                    <SuperAdminMainPage auth={localAuth} onLogout={() => logoutHandler()} />
                )
            }else{
                return (
                    <MainPage auth={localAuth} onLogout={() => logoutHandler()} />
                )
            }
        }else{
            return (
                <LoginPage auth={localAuth} onLogin={(isAdmin) => loginHandler(isAdmin)}/>
            )
        }
    }


    return (
        <Provider store={store}>
            {renderMainScreen()}
        </Provider>
    );
}



export default App;
