import React from 'react'
import styles from './SuperAdminCentersPage.module.css'
import generalStyles from '../../styles/General.module.css'
import { useState, useRef, useEffect } from 'react'
import strings from '../../assets/strings'
import RingLoader from "react-spinners/RingLoader"
import SystemMessage from '../../components/system_messages/SystemMessage'
import { fetchAllCentersAPI } from '../../managers/ServerSuperAdminManager'
import { showErrorMessage } from '../SystemMessagesManager'
import CentersList from '../../components/lists/CentersList'
import { PLUS_ICON } from '../../assets/images'
import NewCenterModal from '../../components/popups/Centers/NewCenterModal'


const SuperAdminCentersPage = (props) => {

  const [selectedCenter, setSelectedCenter] = useState(null)
  //const [newCenterPopupVisible ,setNewCenterPopupVisible] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [centers, setCenters] = useState([])
  const [presentedCenters, setPresentedCenters] = useState([])
  const searchInputRef = useRef()
  const [loader, setLoader] = useState(false)


  useEffect(() => {
    fetchCenters()
  }, [])


  useEffect(() => {
    var tempList = centers

    tempList = tempList.filter(user => user.centerName !== undefined && user.centerName.includes(searchText))
    setPresentedCenters(tempList)

  }, [searchText])


  const fetchCenters = async () => {
    setLoader(true)
    
    const response = await fetchAllCentersAPI()
    if (response.data !== null){

      setCenters(response.data)
      setPresentedCenters(response.data)
      setLoader(false)

    }else{
      showErrorMessage(response.error)
    }
  }


  const renderSearchSection = () => {
    return (
      <div className={`${generalStyles.blueViewWithShadowStyle} ${styles.searchBackViewStyle}`}>
          <input placeholder={strings.SEARCH_PLACEHOLDER} className={styles.controlStyle} onChange={() => setSearchText(searchInputRef.current.value)} ref={searchInputRef} type='text' id='search'/>
      </div>
    )
  }


  const renderCentersListSection = () => {
    return (
      <div className={`${generalStyles.blueViewWithShadowStyle} ${styles.usersListBackViewStyle}`}>
        <CentersList items={presentedCenters} selectedCenter={selectedCenter} onItemClick={(selectedCenter) => setSelectedCenter(selectedCenter)} />
      </div>
    )
  }


  return (
    <div className={generalStyles.mainBackViewStyle}>
        <div className={styles.mainBackViewLeftSide}>
            {renderSearchSection()}
            {renderCentersListSection()}
        </div>

        <div className={generalStyles.loaderBackView}>
            {loader && <RingLoader color="#176ED0" />}
        </div>

        {/* <div 
            className={styles.newMessageButtonStyle} 
            onClick={() => {
                setNewCenterPopupVisible(true)
            }}>
            <img className={styles.newIconsStyle} src={PLUS_ICON} alt="Logo" />
        </div> */}

      <SystemMessage />

        {/* <NewCenterModal
            isOpen={newCenterPopupVisible}
            center={props.center}
            saveNewCenter={(user) => {
                setNewCenterPopupVisible(false)
                //saveOrUpdateUser(user)
            }}
            closeEvent={() => setNewCenterPopupVisible(false)} /> */}

    </div>
  )
}


export default SuperAdminCentersPage
