import React from 'react'
import generalStyles from '../../styles/General.module.css'
import CentersListItem from '../items/CentersListItem'


const CentersList = (props) => {

  
  const renderCenterItem = (item) => {
    return (
        <CentersListItem
            isSelected={false}
            approved={item.approved}
            key={item.centerName}
            id={item.centerName}
            item={item}
            onItemClick={() => props.onItemClick(item)}>
        </CentersListItem>
      )
  }

 
  return (
    <div className={generalStyles.listStyle}>
      {props.items.map(item => (
        <>
          {renderCenterItem(item)}
        </>
      ))}
    </div>
  )
}


export default CentersList