import { useState, useEffect } from 'react'
import styles from './MainPage.module.css'
import DashboardPage from './Dashboard/DashboardPage'
import UsersPage from './Users/UsersPage'
import EventsPage from './Events/EventsPage'
import ReportsPage from './Reports/ReportsPage'
import SettingsPage from './Settings/SettingsPage'
import { UAPP_LOGO, SM_SETTINGS, SM_DASHBOARD, SM_USERS, SM_MESSAGES, SM_ANALYTICS, SM_LOGOUT } from '../assets/images'
import strings from '../assets/strings'
import { useSelector } from 'react-redux'
import { SETTINGS } from '../constants/constants'


const MainPage = (props) => {

    const center = useSelector((state) => state.center.center)
    const MENU_ITEMS = { DASHBOARD: 'Dashboard', USERS: 'Users', EVENTS: 'Events', REPORTS: 'Reports', SETTINGS: 'Settings' }
    const [presentedMenu, setPresentedMenu] = useState(MENU_ITEMS.DASHBOARD)



    const switchMenu = (MENU_ITEMS) => {
        setPresentedMenu(MENU_ITEMS)
    }


    const renderMainView = () => {
        if (presentedMenu == MENU_ITEMS.DASHBOARD){
            return (
                <DashboardPage />
            )
        }else if (presentedMenu == MENU_ITEMS.USERS){
            return (
                <UsersPage />
            )
        }else if (presentedMenu == MENU_ITEMS.EVENTS){
            return (
                <EventsPage />
            )
        }else if (presentedMenu == MENU_ITEMS.REPORTS){
            return (
                <ReportsPage />
            )
        }else if (presentedMenu == MENU_ITEMS.SETTINGS){
            return (
                <SettingsPage auth={props.auth} />
            )
        }
    }


    const renderCompanyLogo = () => {
        if (center !== null){
            if (center.centerLogoUrl !== null){
                return <img className={styles.logoImageStyle} src={center.centerLogoUrl} alt="Logo" />
            }else{
                return <img className={styles.logoImageStyle} src={UAPP_LOGO} alt="Logo" />
            }
        }
    }


    return (
        <>
            <div className={styles.menuBackViewStyle}>

                <div className={styles.menuButtonsBackViewStyle}>
                    {renderCompanyLogo()}
                    <text className={styles.logoTextStyle}>{center !== null ? center.centerCity : ""}</text>
                    
                    <div className={styles.menuButtonStyle} onClick={() => switchMenu(MENU_ITEMS.DASHBOARD)}>
                        <img className={styles.menuButtonIconStyle} src={SM_DASHBOARD} />
                        <text className={styles.menuButtonTextStyle}>{strings.SIDE_MENU_DASHBOARD}</text>
                    </div>
                    <div className={styles.menuButtonStyle} onClick={() => switchMenu(MENU_ITEMS.USERS)}>
                        <img className={styles.menuButtonIconStyle} src={SM_USERS} />
                        <text className={styles.menuButtonTextStyle}>{strings.SIDE_MENU_USERS}</text>
                    </div>
                    <div className={styles.menuButtonStyle} onClick={() => switchMenu(MENU_ITEMS.EVENTS)}>
                        <img className={styles.menuButtonIconStyle} src={SM_MESSAGES} />
                        <text className={styles.menuButtonTextStyle}>{strings.SIDE_MENU_EVENTS}</text>
                    </div>
                    <div className={styles.menuButtonStyle} onClick={() => switchMenu(MENU_ITEMS.REPORTS)}>
                        <img className={styles.menuButtonIconStyle} src={SM_ANALYTICS} />
                        <text className={styles.menuButtonTextStyle}>{strings.SIDE_MENU_REPORTS}</text>
                    </div>
                    {/* <div className={styles.menuButtonStyle} onClick={() => switchMenu(MENU_ITEMS.SETTINGS)}>
                        <img className={styles.menuButtonIconStyle} src={SM_SETTINGS} />
                        <text className={styles.menuButtonTextStyle}>{strings.SIDE_MENU_SETTINGS}</text>
                    </div> */}
                    <div className={styles.menuButtonStyle} onClick={() => props.onLogout()}>
                        <img className={styles.menuButtonIconStyle} src={SM_LOGOUT} />
                        <text className={styles.menuButtonTextStyle}>{strings.SIDE_MENU_LOGOUT}</text>
                    </div>
                </div>

                <div className={styles.versionNumberStyle}>
                    {SETTINGS.VERSION}
                </div>

            </div>

            <div className={styles.mainBackViewStyle}>
                {renderMainView()}
            </div>
        </>
    )
}


export default MainPage