import React from 'react'
import styles from './AdminMessagesListItem.module.css'
import { MESSAGE_TYPE_EVENT_ICON, MESSAGE_TYPE_INVITE_ICON, MESSAGE_TYPE_SHARE_ICON, MESSAGE_TYPE_MESSAGE_ICON, MESSAGE_TYPE_EDIT_ICON } from '../../assets/images'
import moment from 'moment'
import { MESSAGE_TYPES } from '../../constants/constants';
import strings from '../../assets/strings';


const AdminMessagesListItem = props => {


    const renderDate = () => {
        if (props.item.messageDate !== undefined){
            const messageDate = moment.unix(props.item.messageDate/1000)
            var today = moment()

            if (today.diff(messageDate, 'days') === 0){
                return(
                    <text className={styles.dateTextStyle}>{messageDate.format("hh:mm")}</text>
                )
            }else{
                return(
                    <text className={styles.dateTextStyle}>{messageDate.format("DD MMM hh:mm")}</text>
                )
            }
        }
    }
   

    const renderIcon = () => {
        if (props.item.messageStatus == MESSAGE_TYPES.EVENT){
            return <img className={styles.iconStyle} src={MESSAGE_TYPE_EVENT_ICON} alt="smsIcon"/>

        }else if (props.item.messageStatus == MESSAGE_TYPES.INVITE){
            return <img className={styles.iconStyle} src={MESSAGE_TYPE_INVITE_ICON} alt="smsIcon"/>

        }else if (props.item.messageStatus == MESSAGE_TYPES.SHARING){
            return <img className={styles.iconStyle} src={MESSAGE_TYPE_SHARE_ICON} alt="smsIcon"/>

        }else if (props.item.messageStatus == MESSAGE_TYPES.EDIT){
            return <img className={styles.iconStyle} src={MESSAGE_TYPE_EDIT_ICON} alt="smsIcon"/>

        }else{
            return <img className={styles.iconStyle} src={MESSAGE_TYPE_MESSAGE_ICON} alt="smsIcon"/>
        }
    }


    const renderMessageText = () => {
        if (props.item.messageStatus == MESSAGE_TYPES.INVITE){
            return(
                <text className={styles.textTextStyle}>{strings.MESSAGES_DEFAULT_TEXT}</text>
            )
        }else{
            return(
                <text className={styles.textTextStyle}>{props.item.messageText}</text>
            )
        }
    }


    return (
        <div className={styles.sectionItemStyle}>
            {renderIcon()}

            <div className={styles.counterTextStyle}>
                <text className={styles.counterTextStyle}>[{props.item.messageUsersCounter}]</text>
            </div>

            <div className={styles.textSectionItemStyle}>
                {renderMessageText()}
            </div>
            

            <div className={styles.datesSectionItemStyle}>
                {renderDate()}
            </div>
        </div>
  );
};


export default AdminMessagesListItem