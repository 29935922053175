import React from 'react'
import styles from './SuperAdminDashboard.module.css'
import generalStyles from '../../styles/General.module.css'
import { useState, useRef, useEffect } from 'react'
import UsersList from '../../components/lists/UsersList'
import strings from '../../assets/strings'
import RingLoader from "react-spinners/RingLoader"
import SystemMessage from '../../components/system_messages/SystemMessage'
import { fetchAllAppUsersAPI } from '../../managers/ServerSuperAdminManager'
import { COLORS } from '../../constants/constants'
import moment from 'moment'
import { showErrorMessage } from '../SystemMessagesManager'
import FilterPopupsSuperAdmin from '../../components/popups/Filters/FilterPopupsSuperAdmin'
import ReactECharts from 'echarts-for-react'
import { useSelector } from 'react-redux'


const SuperAdminDashboard = (props) => {

  const users = useSelector((state) => state.users.users)
  const [totalUsersCounter, setTotalUsersCounter] = useState(0)
  const [totalAndroidUsersCounter, setTotalAndroidUsersCounter] = useState(0)
  const [totalIOSUsersCounter, setTotalIOSUsersCounter] = useState(0)
  const [totalUnknownUsersCounter, setTotalUnknownUsersCounter] = useState(0)
  const [totalUsersStatusLonely, setTotalUsersStatusLonely] = useState(0)
  const [totalUsersStatusCombat, setTotalUsersStatusCombat] = useState(0)
  const [totalUsersStatusOfficer, setTotalUsersStatusOfficer] = useState(0)
  const [totalUsersStatusOther, setTotalUsersStatusOther] = useState(0)
  const [totalUsersStatusStudy, setTotalUsersStatusStudy] = useState(0)
  const [totalUsersStatusOne, setTotalUsersStatusOne] = useState(0)
  const [totalUsersStatusTwo, setTotalUsersStatusTwo] = useState(0)
  const [totalUsersStatusThree, setTotalUsersStatusThree] = useState(0)
  const [totalUsersStatusFore, setTotalUsersStatusFore] = useState(0)
  const [androidAppVersionsValues, setAndroidAppVersionsValues] = useState([])
  const [iOSAppVersionsValues, setIOSAppVersionsValues] = useState([])
  const [loader, setLoader] = useState(false)


  const optionsPlatforms = {
    grid: { top: 8, right: 8, bottom: 24, left: 36 },
    series: [
      {
        data:[
          {value:totalAndroidUsersCounter, name: strings.SUPER_ADMIN_USERS_TOTAL_ANDROID_USERS},
          {value:totalIOSUsersCounter, name:strings.SUPER_ADMIN_USERS_TOTAL_IOS_USERS},
          {value:totalUnknownUsersCounter, name: strings.SUPER_ADMIN_USERS_TOTAL_UNKNOWN_USERS}
        ],
        type: 'pie',
        smooth: true,
      },
    ],
    tooltip: {
      trigger: 'axis',
    },
  };


  const optionsStatuses = {
    grid: { top: 8, right: 8, bottom: 24, left: 36 },
    xAxis: {
      type: 'category',
      data: [strings.SUPER_ADMIN_USERS_TOTAL_LONELY_USERS,
             strings.SUPER_ADMIN_USERS_TOTAL_STUDY_USERS,
             strings.SUPER_ADMIN_USERS_TOTAL_COMBAT_USERS,
             strings.SUPER_ADMIN_USERS_TOTAL_OFFICER_USERS,
             strings.SUPER_ADMIN_USERS_TOTAL_DRIVER_USERS,
             strings.SUPER_ADMIN_USERS_TOTAL_STATUS_1_USERS,
             strings.SUPER_ADMIN_USERS_TOTAL_STATUS_2_USERS,
             strings.SUPER_ADMIN_USERS_TOTAL_STATUS_3_USERS,
             strings.SUPER_ADMIN_USERS_TOTAL_STATUS_4_USERS],
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        data: [totalUsersStatusLonely, 
               totalUsersStatusStudy, 
               totalUsersStatusCombat, 
               totalUsersStatusOfficer, 
               totalUsersStatusOther, 
               totalUsersStatusOne, 
               totalUsersStatusTwo, 
               totalUsersStatusThree, 
               totalUsersStatusFore],
        type: 'bar',
        smooth: true,
      },
    ],
    tooltip: {
      trigger: 'axis',
    },
  };


  useEffect(() => {
    setTotalUsersCounter(users.length)
    setTotalAndroidUsersCounter(users.filter(user => user.platform === "Android").length)
    setTotalIOSUsersCounter(users.filter(user => user.platform === "iOS").length)
    setTotalUnknownUsersCounter(users.filter(user => user.platform === undefined).length)
    setTotalUsersStatusLonely(users.filter(user => user.isLonely === true).length)
    setTotalUsersStatusCombat(users.filter(user => user.isCombat === true).length)
    setTotalUsersStatusOfficer(users.filter(user => user.isOfficer === true).length)
    setTotalUsersStatusOther(users.filter(user => user.isOther === true).length)
    setTotalUsersStatusStudy(users.filter(user => user.isStudy === true).length)
    setTotalUsersStatusOne(users.filter(user => user.currentStatus === strings.SUPER_ADMIN_USERS_TOTAL_STATUS_1_USERS).length)
    setTotalUsersStatusTwo(users.filter(user => user.currentStatus === strings.SUPER_ADMIN_USERS_TOTAL_STATUS_2_USERS).length)
    setTotalUsersStatusThree(users.filter(user => user.currentStatus === strings.SUPER_ADMIN_USERS_TOTAL_STATUS_3_USERS).length)
    setTotalUsersStatusFore(users.filter(user => user.currentStatus === strings.SUPER_ADMIN_USERS_TOTAL_STATUS_4_USERS).length)

    const androidVersions = [...new Set(users.map((user) => {
      if (user.platform === "Android"){
        return user.version
      }
    }))]
    setAndroidAppVersionsValues(androidVersions)

    const iosVersions = [...new Set(users.map((user) => {
      if (user.platform === "iOS"){
        return user.version
      }
    }))]
    setIOSAppVersionsValues(iosVersions)
  }, [])

  

  const renderAndroidVersions = () => {
    if (androidAppVersionsValues.length > 1){
      return androidAppVersionsValues.map((versionName) => {
        return <text className={styles.subTitleTextStyle}>{versionName}</text>
      })
    }
    return <></>
  }


  const renderiOSVersions = () => {
    if (iOSAppVersionsValues.length > 1){
      return iOSAppVersionsValues.map((versionName) => {
        return <text className={styles.subTitleTextStyle}>{versionName}</text>
      })
    }
    return <></>
  }


  return (
    <div className={styles.mainBackViewStyle}>
          <div className={`${generalStyles.blueViewWithShadowStyle} ${styles.platformDataBackViewStyle}`}>
            <div className={styles.platformItemBackViewStyle}>
                <text className={styles.titleTextStyle}>{strings.SUPER_ADMIN_USERS_TOTAL_APP_USERS}</text>
                <text className={styles.subTitleTextStyle}>{totalUsersCounter}</text>
            </div>

            <div className={styles.platformItemBackViewStyle}>
                <text className={styles.titleTextStyle}>{strings.SUPER_ADMIN_USERS_TOTAL_ANDROID_USERS}</text>
                <text className={styles.subTitleTextStyle}>{totalAndroidUsersCounter}</text>
            </div>

            <div className={styles.platformItemBackViewStyle}>
                <text className={styles.titleTextStyle}>{strings.SUPER_ADMIN_USERS_TOTAL_IOS_USERS}</text>
                <text className={styles.subTitleTextStyle}>{totalIOSUsersCounter}</text>
            </div>

            <div className={styles.platformItemBackViewStyle}>
                <text className={styles.titleTextStyle}>{strings.SUPER_ADMIN_USERS_TOTAL_UNKNOWN_USERS}</text>
                <text className={styles.subTitleTextStyle}>{totalUnknownUsersCounter}</text>
            </div>

            <div className={styles.platformItemBackGraphViewStyle}>
              <ReactECharts
                option={optionsPlatforms}
                style={{ marginLeft: 10, width: '98%', height: 180 }}
              />
            </div>
          </div>

          <div className={`${generalStyles.blueViewWithShadowStyle} ${styles.platformVersionsStatusesBackViewStyle}`}>
              <div style={{ width: '49%', height: '98%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <text className={styles.titleTextStyle}>{strings.SUPER_ADMIN_USERS_TOTAL_ANDROID_USERS}</text>
                <div style={{overflowY: 'scroll', width: '100%', height: '90%', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                  {renderAndroidVersions()}
                </div>
              </div>

              <div style={{ width: '49%', height: '98%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <text className={styles.titleTextStyle}>{strings.SUPER_ADMIN_USERS_TOTAL_IOS_USERS}</text>
                <div style={{overflowY: 'scroll', height: '90%', width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                  {renderiOSVersions()}
                </div>
              </div>
              
          </div>

          <div className={`${generalStyles.blueViewWithShadowStyle} ${styles.platformDataStatusesBackViewStyle}`}>
            <ReactECharts
              option={optionsStatuses}
              style={{ marginLeft: 10, width: '98%', height: 200 }}
            />
          </div>

          <div className={generalStyles.loaderBackView}>
              {loader && <RingLoader color="#176ED0" />}
          </div>
    </div>
  )
}


export default SuperAdminDashboard
