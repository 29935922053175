import ReactECharts from 'echarts-for-react'
import generalStyles from '../../styles/General.module.css'
import { useEffect, useState } from 'react'
import strings from '../../assets/strings'
import styles from './ReportsPage.module.css'
import RingLoader from "react-spinners/RingLoader"
import SystemMessage from '../../components/system_messages/SystemMessage'
import { useSelector } from 'react-redux'


const ReportsPage = (props) => {

  const users = useSelector((state) => state.users.users)
  const [totalUsersCounter, setTotalUsersCounter] = useState(0)
  const [totalAppUsersCounter, setTotalAppUsersCounter] = useState(0)
  const [totalNotAppUsersCounter, setTotalNotAppUsersCounter] = useState(0)
  const [totalUsersStatusLonely, setTotalUsersStatusLonely] = useState(0)
  const [totalUsersStatusCombat, setTotalUsersStatusCombat] = useState(0)
  const [totalUsersStatusOfficer, setTotalUsersStatusOfficer] = useState(0)
  const [totalUsersStatusOther, setTotalUsersStatusOther] = useState(0)
  const [totalUsersStatusStudy, setTotalUsersStatusStudy] = useState(0)
  const [totalUsersStatusOne, setTotalUsersStatusOne] = useState(0)
  const [totalUsersStatusTwo, setTotalUsersStatusTwo] = useState(0)
  const [totalUsersStatusThree, setTotalUsersStatusThree] = useState(0)
  const [totalUsersStatusFore, setTotalUsersStatusFore] = useState(0)
  const [loader, setLoader] = useState(false)


  const optionsPlatforms = {
    grid: { top: 8, right: 8, bottom: 24, left: 36 },
    series: [
      {
        data:[
          {value:totalAppUsersCounter, name: strings.REPORT_TOTAL_APP_USERS},
          {value:totalNotAppUsersCounter, name:strings.REPORT_TOTAL_NOT_APP_USERS}
        ],
        type: 'pie',
        smooth: true,
      },
    ],
    tooltip: {
      trigger: 'axis',
    },
  }


  const optionsStatuses = {
    grid: { top: 8, right: 8, bottom: 24, left: 36 },
    xAxis: {
      type: 'category',
      data: [strings.REPORT_TOTAL_LONELY_USERS,
             strings.REPORT_TOTAL_STUDY_USERS,
             strings.REPORT_TOTAL_COMBAT_USERS,
             strings.REPORT_TOTAL_OFFICER_USERS,
             strings.REPORT_TOTAL_DRIVER_USERS,
             strings.REPORT_TOTAL_STATUS_1_USERS,
             strings.REPORT_TOTAL_STATUS_2_USERS,
             strings.REPORT_TOTAL_STATUS_3_USERS,
             strings.REPORT_TOTAL_STATUS_4_USERS],
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        data: [totalUsersStatusLonely, 
               totalUsersStatusStudy, 
               totalUsersStatusCombat, 
               totalUsersStatusOfficer, 
               totalUsersStatusOther, 
               totalUsersStatusOne, 
               totalUsersStatusTwo, 
               totalUsersStatusThree, 
               totalUsersStatusFore],
        type: 'bar',
        smooth: true,
      },
    ],
    tooltip: {
      trigger: 'axis',
    },
  }


  useEffect(() => {
    setTotalUsersCounter(users.length)
    setTotalAppUsersCounter(users.filter(u => u.approved === true).length)
    setTotalNotAppUsersCounter(users.filter(u => u.approved === false).length)

    setTotalUsersStatusLonely(users.filter(user => user.isLonely === true).length)
    setTotalUsersStatusCombat(users.filter(user => user.isCombat === true).length)
    setTotalUsersStatusOfficer(users.filter(user => user.isOfficer === true).length)
    setTotalUsersStatusOther(users.filter(user => user.isOther === true).length)
    setTotalUsersStatusStudy(users.filter(user => user.isStudy === true).length)
    setTotalUsersStatusOne(users.filter(user => user.currentStatus === strings.REPORT_TOTAL_STATUS_1_USERS).length)
    setTotalUsersStatusTwo(users.filter(user => user.currentStatus === strings.REPORT_TOTAL_STATUS_2_USERS).length)
    setTotalUsersStatusThree(users.filter(user => user.currentStatus === strings.REPORT_TOTAL_STATUS_3_USERS).length)
    setTotalUsersStatusFore(users.filter(user => user.currentStatus === strings.REPORT_TOTAL_STATUS_4_USERS).length)
  }, [])
  

  const reportEventHandler = () => {

  }


  return (
    <div className={generalStyles.mainBackViewStyle}>
        <div className={styles.mainBackViewLeftSide}>
          <div className={`${generalStyles.blueViewWithShadowStyle} ${styles.platformDataBackViewStyle}`}>
            <div className={styles.platformItemBackViewStyle}>
                <text className={styles.titleTextStyle}>{strings.REPORT_TOTAL_USERS}</text>
                <text className={styles.subTitleTextStyle}>{totalUsersCounter}</text>
            </div>

            <div className={styles.platformItemBackViewStyle}>
                <text className={styles.titleTextStyle}>{strings.REPORT_TOTAL_APP_USERS}</text>
                <text className={styles.subTitleTextStyle}>{totalAppUsersCounter}</text>
            </div>

            <div className={styles.platformItemBackViewStyle}>
                <text className={styles.titleTextStyle}>{strings.REPORT_TOTAL_NOT_APP_USERS}</text>
                <text className={styles.subTitleTextStyle}>{totalNotAppUsersCounter}</text>
            </div>

            <div className={styles.platformItemBackGraphViewStyle}>
              <ReactECharts
                option={optionsPlatforms}
                style={{ marginLeft: 10, width: '98%', height: 200 }}
              />
            </div>
          </div>

          <div className={`${generalStyles.blueViewWithShadowStyle} ${styles.platformDataStatusesBackViewStyle}`}>
            <ReactECharts
              option={optionsStatuses}
              style={{ marginLeft: 10, width: '98%', height: 200 }}
            />
          </div>

          <div className={`${generalStyles.blueViewWithShadowStyle} ${styles.reportsBackViewStyle}`}>
            <div className={styles.reportButtonBackViewStyle}>
              <button className={styles.leftSectionBackStyle_footerViewStyle_buttonStyle} onClick={() => reportEventHandler()}>{strings.REPORT_ONE}</button>
            </div>
            <div className={styles.reportButtonBackViewStyle}>
              <button className={styles.leftSectionBackStyle_footerViewStyle_buttonStyle} onClick={() => reportEventHandler()}>{strings.REPORT_TWO}</button>
            </div>
            <div className={styles.reportButtonBackViewStyle}>
              <button className={styles.leftSectionBackStyle_footerViewStyle_buttonStyle} onClick={() => reportEventHandler()}>{strings.REPORT_THREE}</button>
            </div>
            <div className={styles.reportButtonBackViewStyle}>
              <button className={styles.leftSectionBackStyle_footerViewStyle_buttonStyle} onClick={() => reportEventHandler()}>{strings.REPORT_FORE}</button>
            </div>
          </div>
      </div>


      <div className={generalStyles.loaderBackView}>
          {loader && <RingLoader color="#176ED0" />}
      </div>

      <SystemMessage />
    </div>
  )
}
  
  
  
export default ReportsPage