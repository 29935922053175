import React from 'react'
import SectionsListItem from './SectionsListItem'
import generalStyles from '../../styles/General.module.css'
import { SECTIONS_ITEMS } from '../../constants/constants'

const SectionsList = (props) => {

  return (
      <div className={generalStyles.listStyle}>
        {props.items.map(item => (
          <SectionsListItem 
            selectedSection={props.selectedSection} 
            isSelected={props.selectedCategory === item.dataRoute} 
            key={item.dataRoute} id={item.dataRoute} 
            onItemClick={() => {
              if (props.selectedSection === SECTIONS_ITEMS.WORK){
                props.onItemClick(decodeURIComponent(item.title), props.selectedSection === SECTIONS_ITEMS.WORK)
              }else{
                props.onItemClick(item.dataRoute, props.selectedSection === SECTIONS_ITEMS.WORK)
              }
            }}>
            {decodeURIComponent(item.title)}
          </SectionsListItem>
        ))}
      </div>
  )
}


export default SectionsList