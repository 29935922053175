import { getDatabase, ref, child, get, set } from "firebase/database"


const USERS = "user/"
const CENTER_USERS = "centerUsers/"



export async function fetchAppUsersAPI(city){
    const dbRef = ref(getDatabase())
    return get(child(dbRef, USERS)).then((snapshot) => {
        if (snapshot.exists()) {
                   
          var tempAllUsersList = []
          snapshot.forEach((jobSnapshot) => {
            var obj = jobSnapshot.val()

            if (city === obj.city){
                const tempItem = { name: obj.name,
                                   phone: jobSnapshot.key,
                                   birthDate: obj.birthDate,
                                   currentStatus: obj.currentStatus,
                                   dischargeDate: obj.dischargeDate,
                                   isLonely: obj.isLonely,
                                   isCombat: obj.isCombat,
                                   isOfficer: obj.isOfficer,
                                   idNumber: obj.idNumber,
                                   fcm_token: obj.fcm_token,
                                   isStudy: obj.isStudy,
                                   other: obj.other,
                                   email: obj.email, 
                                   city: obj.city,
                                   approved: true }

                tempAllUsersList = tempAllUsersList.concat(tempItem)
            }
          })
          return { data: tempAllUsersList, error: null }
        }
        }).catch((error) => {
    
            return { data: null, error: error }
        })
}



export async function fetchCenterUsersAPI(centerName){

    const dbRef = ref(getDatabase())
    
    return get(child(dbRef, CENTER_USERS + centerName)).then((snapshot) => {
        if (snapshot.exists()) {

          var tempList = []
          snapshot.forEach((jobSnapshot) => {
            var obj = jobSnapshot.val()
            const tempItem = { name: obj.name,
                               phone: jobSnapshot.key,
                               birthDate: obj.birthDate,
                               currentStatus: obj.currentStatus,
                               dischargeDate: obj.dischargeDate,
                               isLonely: obj.isLonely,
                               isCombat: obj.isCombat,
                               isOfficer: obj.isOfficer,
                               idNumber: obj.idNumber,
                               fcm_token: obj.fcm_token,
                               isStudy: obj.isStudy,
                               other: obj.other,
                               email: obj.email, 
                               city: obj.city,
                               notes: obj.notes,
                               keywords: obj.keywords,
                               approved: false }
            tempList = tempList.concat(tempItem)
          })
          return { data: tempList, error: null }
        }
        }).catch((error) => {

            return { data: null, error: error }
    })
}




export async function createUserAPI(centerName, user){
  const db = getDatabase()
  return set(ref(db, CENTER_USERS + centerName + "/" + user.phone),
          {
            name: (user.firstName ? user.firstName : "") + " " + (user.lastName ? user.lastName : ""), 
            birthDate: user.birthDate ? user.birthDate : null,
            currentStatus: user.currentStatus ? user.currentStatus : "", 
            dischargeDate: user.dischargeDate ? user.dischargeDate : null,
            email: user.email ? user.email : null,
            idNumber: user.idNumber ? user.idNumber : null,
            fcm_token: user.fcm_token ? user.fcm_token : null,
            isCombat: user.isCombat ? user.isCombat : false,
            isLonely: user.isLonely ? user.isLonely : false,
            isOfficer: user.isOfficer ? user.isOfficer : false,
            isStudy: user.isStudy ? user.isStudy : false, 
            keywords: user.keywords ? user.keywords: [], 
            other: user.other ? user.other : false,
            notes: user.notes ? user.notes : null 
          }
  ).then((response) => {

    return true

  }).catch((error) => {

    return false
  })
}



export async function updateUserNotesAPI(centerName, user, notes){
  const db = getDatabase()
  return set(ref(db, CENTER_USERS + centerName + "/" + user.phone),
          {
            name: user.name, 
            birthDate: user.birthDate ? user.birthDate : null,
            currentStatus: user.currentStatus ? user.currentStatus : "", 
            dischargeDate: user.dischargeDate ? user.dischargeDate : null,
            email: user.email ? user.email : null,
            idNumber: user.idNumber ? user.idNumber : null,
            fcm_token: user.fcm_token ? user.fcm_token : null,
            isCombat: user.isCombat ? user.isCombat : false,
            isLonely: user.isLonely ? user.isLonely : false,
            isOfficer: user.isOfficer ? user.isOfficer : false,
            isStudy: user.isStudy ? user.isStudy : false, 
            keywords: user.keywords ? user.keywords: [], 
            other: user.other ? user.other : false,
            notes: notes
          }
  ).then((response) => {

    return true

  }).catch((error) => {

    return false
  })
}
