import React from 'react'
import generalStyle from '../../../styles/General.module.css'
import styles from './UserSelectorListItem.module.css'


const UserSelectorListItem = props => {
  
    return (        
        <div className={`${generalStyle.whiteViewWithShadowStyle} ${styles.sectionItemStyle}`} style={props.isSelected ? { background: '#C2DAEE' } : { background: '#FFFFFF' }} onClick={() => props.onItemClick()}>
            <div className={styles.textStyle}>
                <text className={generalStyle.text16}>{props.children}</text>
            </div>
        </div>
  );
};


export default UserSelectorListItem