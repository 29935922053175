import { getDatabase, ref, child, get, set } from "firebase/database"
import moment from "moment"


const CENTER_EVENTS = "centerEvents/"


export async function fetchEventsAPI(centerName){

    const dbRef = ref(getDatabase())
    return  get(child(dbRef, CENTER_EVENTS + `${centerName}`)).then((snapshot) => {
        if (snapshot.exists()) {

          var tempList = []
          snapshot.forEach((jobSnapshot) => {
            var obj = jobSnapshot.val()

            const tempItem = { eventKey: jobSnapshot.key,
                               eventDescriptions: obj.eventDescriptions,
                               eventDate: obj.eventDate,
                               eventTime: obj.eventTime,
                               eventDirection: obj.eventDirection,
                               eventParticipants: obj.eventParticipants,
                               eventTitle: obj.eventTitle }
                               
            tempList = tempList.concat(tempItem)
          })

          return { data: tempList, error: null }

        }else{
          return { data: null, error: null }
        }

        }).catch((error) => {

            return { data: null, error: error }
    })
}



export async function createEventAPI(event ,centerName){
  const db = getDatabase()
  var newKey = moment().valueOf()
  return set(ref(db, CENTER_EVENTS + `${centerName}` + '/' + newKey),
    {
      eventTitle: event.eventTitle,
      eventDescriptions: event.eventDescriptions,
      eventDate: event.eventDate,
      eventTime: event.eventTime,
      eventDirection: event.eventDirection,
      eventParticipants: event.eventParticipants.filter(eventParticipant => eventParticipant.name !== "")

    }).then(() => {
      
      return true
      
    }).catch((error) => {
      
      return false

    })
}



export async function editEventAPI(event ,centerName){
  const db = getDatabase()

  var newKey = moment().valueOf()
  if (event.eventKey !== null){
    newKey = event.eventKey
  }

  return set(ref(db, CENTER_EVENTS + `${centerName}` + '/' + event.eventKey),
    {
      eventTitle: event.eventTitle,
      eventDescriptions: event.eventDescriptions,
      eventDate: event.eventDate,
      eventTime: event.eventTime,
      eventDirection: event.eventDirection,
      eventParticipants: event.eventParticipants.filter(eventParticipant => eventParticipant.name !== "")

    }).then(() => {
      
      return true
      
    }).catch((error) => {
      
      return false

    })
}

