import { useState, useRef } from 'react'
import classes from './LoginPage.module.css'
import generalStyles from '../styles/General.module.css'
import { BURD_LOGO, UAPP_BACKGROUND } from '../assets/images'
import strings from '../assets/strings'
import SystemMessage from '../components/system_messages/SystemMessage'
import { showErrorMessage, showSuccessMessage } from './SystemMessagesManager'
import RingLoader from "react-spinners/RingLoader"
import { loginAPI, sendPasswordResetAPI } from '../managers/ServerAccountManager'
import { useDispatch } from 'react-redux'
import { fetchAppUsersAPI, fetchCenterUsersAPI } from '../managers/ServerUsersManager'
import { saveUsers } from '../redux/UserReducer'
import { getAllUsers } from '../constants/Utils'
import { fetchAllAppUsersAPI } from '../managers/ServerSuperAdminManager'
import { saveCenter } from '../redux/CenterReducer'
import { fetchJobsAPI, fetchMoreCategoriesAPI, fetchRightsCategoriesAPI, fetchStudyCategoriesAPI } from '../managers/ServerDashboardManager'
import { saveJobs, saveMore, saveRights, saveStudies } from '../redux/DashboardReducer'


const LoginPage = (props) => {

    const [isLogin, setIsLogin] = useState(true)
    const [loader, setLoader] = useState(false)
    const emailInputRef = useRef()
    const passwordInputRef = useRef()
    const dispatchUsers = useDispatch()
    const dispatchCenter = useDispatch()
    const dispatchDashboard = useDispatch()
    

    const switchModeHandler = () => {
        emailInputRef.current.value = ""
        setIsLogin((prevState) => !prevState)
    }


    const submitLoginHandler = async () => {
        const enteredEmail = emailInputRef.current.value
        const passwordEmail = passwordInputRef.current.value
        
        if (enteredEmail.length > 0 && passwordEmail.length > 0){

            setLoader(true)
            const response = await loginAPI(props.auth, enteredEmail, passwordEmail)
            
            if (response.isAdmin){
                
                const users = await fetchAllAppUsersAPI()
                dispatchUsers(saveUsers({ users: users.data }))
                setLoader(false)
                props.onLogin(response.isAdmin)

            }else{
                if (response.data !== null){

                    const users = await fetchAppUsersAPI(response.data.centerCity)
                    const centerUsers = await fetchCenterUsersAPI(response.data.centerName)
                    await fetchDataHandler()

                    if (centerUsers !== undefined){
                        const allUsers = getAllUsers(users.data, centerUsers.data)
                        dispatchUsers(saveUsers({ users: allUsers }))
                    }else{
                        dispatchUsers(saveUsers({ users: users.data }))
                    }
                    dispatchCenter(saveCenter({ center: response.data }))

                    setLoader(false)
                    props.onLogin(response.isAdmin)

                }else{
                    showErrorMessage(response.error)
                }
            }
        }else{
            showErrorMessage(strings.SYSTEM_MESSAGE_LOGIN_ERROR)
        }
    }


    const fetchDataHandler = async () => {
        const study = await fetchStudyCategoriesAPI()
        if (study.data !== null){
            dispatchDashboard(saveStudies({ studies: study.data }))
        }
  
        const more = await fetchMoreCategoriesAPI()
        if (more.data !== null){
            dispatchDashboard(saveMore({ more: more.data }))
        }
  
        const rights = await fetchRightsCategoriesAPI()
        if (rights.data !== null){
            dispatchDashboard(saveRights({ rights: rights.data }))
        }
  
        let jobs = await fetchJobsAPI()
        dispatchDashboard(saveJobs({ jobs: jobs }))
    }


    const submitResetPasswordHandler = async () => {
        const enteredEmail = emailInputRef.current.value
        
        if (enteredEmail.length > 0){
            setLoader(true)
            const response = await sendPasswordResetAPI(props.auth, enteredEmail)
            setLoader(false)

            if (response.data !== null){
                showSuccessMessage(response.data)
            }else{
                showErrorMessage(response.error)
            }
        }
    }


    const renderPasswordField = () => {
        if (isLogin){
            return (
                <div className={classes.controlStyle}>
                    <label htmlFor='password'>{strings.LOGIN_PASSWORD}</label>
                    <input ref={passwordInputRef} type='password' id='password' required/>
                </div>
            )
        }
    }


    return (
        <div>
            <div className={classes.leftBackViewStyle}>
                <img className={classes.logoImageStyle} src={UAPP_BACKGROUND} alt="Logo" />
            </div>
    
            <div className={classes.rightBackViewStyle}>
                <div className={classes.authFormStyle}>
                    <h1>{isLogin ? strings.LOGIN_PAGE_TITLE : strings.LOGIN_FORGOT_PASSWORD}</h1>
                    
                    <div>
                        
                        <div className={classes.controlStyle}>
                            <label htmlFor='email'>{strings.LOGIN_EMAIL}</label>
                            <input ref={emailInputRef} type='email' id='email' required/>
                        </div>
                        
                        {renderPasswordField()}
    
                        <div className={classes.actionBackViewStyle}>
                            <button className={classes.actionStyle} onClick={() => isLogin ? submitLoginHandler() : submitResetPasswordHandler()}>{isLogin ? strings.LOGIN_LOGIN_BUTTON : strings.LOGIN_FORGOT_PASSWORD_BUTTON}</button>
                            <button className={classes.toggleStyle} onClick={() => switchModeHandler()}>{isLogin ? strings.LOGIN_FORGOT_PASSWORD : strings.LOGIN_LOGIN_BUTTON}</button>
                        </div>
                    </div>
                </div>
            </div>
    
            <div className={classes.poweredByStyle}>
                <text className={classes.poweredByTextStyle}>Designed & developed by </text>
                <img className={classes.poweredByIconStyle} src={BURD_LOGO} alt="Logo" />
            </div>
    
            <div className={generalStyles.loaderBackView}>
                {loader && <RingLoader color="#FFFFFF" />}
            </div>

            <SystemMessage />

        </div>
    )
  }
  
  
export default LoginPage