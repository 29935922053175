import UserReducer from "./UserReducer"
import CenterReducer from "./CenterReducer"
import DashboardReducer from "./DashboardReducer"
import { configureStore } from '@reduxjs/toolkit'


export const store = configureStore({
    reducer: {
        users: UserReducer,
        center: CenterReducer,
        dashboard: DashboardReducer
    }
})