export const SECTIONS_ITEMS = { 
    WORK: 'WORK', 
    STUDY: 'STUDY', 
    RIGHTS: 'RIGHTS', 
    MORE: 'MORE'
}


export const COLORS = { 
    JOBS: '#9A7CBE', 
    STUDY: '#49C4D9', 
    RIGHTS: "#FFDC40", 
    MORE: "#F79548",

    JOBS20: '#9A7CBE20', 
    STUDY20: '#49C4D920', 
    RIGHTS20: "#FFDC4020", 
    MORE20: "#F7954820",

    BLUE: "#176ED0",
    RED: "#FF0000",
    RED20: "#FF000020",
    WHITE: "#FFFFFF"
};


export const MESSAGE_TYPES  ={
    MESSAGE: "message",
    INVITE: "invite",
    SHARING: "sharing",
    EVENT: "event",
    EDIT: "edit"
}


export const POPUPS_TYPES  ={
    DASHBOARD_DETAILS: "DASHBOARD_DETAILS",
    NEW_MESSAGE: "NEW_MESSAGE",
    NEW_EVENT: "NEW_EVENT"
}


export const SETTINGS = {
    SUPER_ADMIN_EMAIL: ["sergey@burdev.com", "veredadmin@unitech-il.com"],
    VERSION: "2.1.2"
}

export const DEFAULT_MESSAGE_DOWNLOAD_LINK = "http://onelink.to/uapp"