import React from 'react'
import MessagesListItem from '../items/MessagesListItem'
import MessagesListDefaultItem from '../items/MessagesListDefaultItem'
import generalStyles from '../../styles/General.module.css'
import { UAPP_LOGO } from '../../assets/images'
import strings from '../../assets/strings'


const MessagesList = (props) => {

  const renderTable = () => {
    if (props.items.length > 0){
      return props.items.map(item => (
        <MessagesListItem key={item.date} item={item} />
      ))
    }else{
      return (
        <div style={{ width: '100%', flexDirection: 'column', height: '100%', position: 'absolute', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
           <img style={{ width: 150, height: 150, opacity: 0.2 }} src={UAPP_LOGO} />
           <text style={{ opacity: 0.8, marginTop: 20 }} className={generalStyles.text18}>{strings.EMPTY_STATE_TEXT}</text>
        </div>
      )
    }
  }


  return (
    <div className={generalStyles.listStyle}>
      {renderTable()}
    </div>
  )
}


export default MessagesList