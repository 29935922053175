import styles from './EventsPage.module.css'
import generalStyles from '../../styles/General.module.css'
import { useState, useRef, useEffect } from 'react'
import { PLUS_ICON } from '../../assets/images'
import moment from 'moment'
import CalendarDatePicker from '../../components/calendar/CalendarDatePicker'
import CalendarDayCell from '../../components/calendar/CalendarDayCell'
import CalendarCell from '../../components/calendar/CalendarCell'
import strings from '../../assets/strings'
import RingLoader from "react-spinners/RingLoader"
import SystemMessage from '../../components/system_messages/SystemMessage'
import { showSuccessMessage, showErrorMessage } from '../SystemMessagesManager'
import { createEventAPI, fetchEventsAPI, editEventAPI } from '../../managers/ServerEventsManager'
import { sendPushMessageAPI, sendSmsMessageAPI } from '../../managers/ServerMessagesManager'
import { DEFAULT_MESSAGE_DOWNLOAD_LINK, MESSAGE_TYPES, POPUPS_TYPES } from '../../constants/constants'
import CalendarEventView from '../../components/calendar/CalendarEventView'
import EditEventModal from '../../components/popups/EventsScreenPopups/EditEventModal'
import NewPopup from '../../components/popups/NewPopup'
import { useSelector } from 'react-redux'


const EventsPage = () => {

  const center = useSelector((state) => state.center.center)
  const weekDaysNames = ['ראשון','שני','שלישי','רביעי','חמישי','שישי','שבת']
  const [selectedEvent, setSelectedEvent] = useState(null)
  const [newEventPopupVisible, setNewEventPopupVisible] = useState(false)
  const [editEventPopupVisible, setEditEventPopupVisible] = useState(false)
  const [events, setEvents] = useState([])
  const [selectedEvents, setSelectedEvents] = useState(null)
  const [selectedDate, setSelectedDate] = useState(null)
  const [presentedDatesRange, setPresentedDatesRange] = useState({ from: new Date(), to: moment().add(30, 'days').calendar()})
  const calendarBackViewRef = useRef(null)
  const [loader, setLoader] = useState(false)
  const HOUR_CELL_HEIGHT = 30
  var positionY = 0


  useEffect(() => {
    fetchEvents()
  }, [])


  const fetchEvents = async () => {
    setLoader(true)
    const response = await fetchEventsAPI(center.centerKey)
    setLoader(false)

    if (response.data !== null){
      setEvents(response.data)
    }else{
      if (response.error !== null){
        showErrorMessage(response.error)
      }
    }
  }


  const calendarClickActionHandler = async (date, events) => {
    setSelectedDate(date)
    setSelectedEvents(events)
  }


  const createAndShareWithPushEventHandler = async (event, needShare) => {
    setLoader(true)
    const response = await createEventAPI(event, center.centerKey)
    setLoader(false)

    setNewEventPopupVisible(false)

    if (response){
      fetchEvents()
      showSuccessMessage(strings.SYSTEM_MESSAGE_EVENT_CREATED_SUCCESSFULLY)
      if (needShare){
        sendPushMessageEventHandler(event.eventParticipants, event.eventTitle)
      }
    }else{
      showErrorMessage(strings.SYSTEM_MESSAGE_EVENT_CREATED_ERROR)
    }
  }



  const createAndShareWithSmsEventHandler = async (event, needShare) => {
    setLoader(true)
    const response = await createEventAPI(event, center.centerKey)
    setLoader(false)

    setNewEventPopupVisible(false)

    if (response){
      fetchEvents()
      showSuccessMessage(strings.SYSTEM_MESSAGE_EVENT_CREATED_SUCCESSFULLY)
      if (needShare){
        sendSmsMessageEventHandler(event.eventParticipants, event.eventTitle)
      }
    }else{
      showErrorMessage(strings.SYSTEM_MESSAGE_EVENT_CREATED_ERROR)
    }
  }


  const editEventHandler = async (event) => {
    setLoader(true)
    const response = await editEventAPI(event, center.centerKey)
    setLoader(false)

    setEditEventPopupVisible(false)

    if (response){
      fetchEvents()
      showSuccessMessage(strings.SYSTEM_MESSAGE_EVENT_CREATED_SUCCESSFULLY)
    }else{
      showErrorMessage(strings.SYSTEM_MESSAGE_EVENT_CREATED_ERROR)
    }
  }


  const sendPushMessageEventHandler = async (users, message) => {
    await sendPushMessageAPI(users, message, MESSAGE_TYPES.EVENT, center.centerName)
    showSuccessMessage(strings.SYSTEM_MESSAGE_MESSAGE_SENDED_SUCCESSFULLY)
  }


  const sendSmsMessageEventHandler = async (users, message) => {
    let mes = message + DEFAULT_MESSAGE_DOWNLOAD_LINK
    await sendSmsMessageAPI(users, mes, MESSAGE_TYPES.EVENT, center.centerName)
    showSuccessMessage(strings.SYSTEM_MESSAGE_MESSAGE_SENDED_SUCCESSFULLY)
  }


  const editEventActionHandler = (event) => {
    setSelectedEvent(event)
    setEditEventPopupVisible(true)
  }


  const renderCalendar = () => {
    const space = 2
    const oneCellWidth = calendarBackViewRef.current ? (calendarBackViewRef.current.offsetWidth - 30) / 7 : 120
    const oneCellHeight = calendarBackViewRef.current ? (calendarBackViewRef.current.offsetHeight - 50 - 30) / 5 : 120
    let positionX = 10
    let positionY = 10
    const row = []
    let cellsCounter = 0
    var weekDays = []

    const startOfMonth = moment(presentedDatesRange.from).startOf('month').format('d')
    const endOfMonth = moment(presentedDatesRange.from).endOf('month').format('d')
    const numberOfDaysInMonth = moment(presentedDatesRange.from).daysInMonth()
    const today = moment().format('DD-MM-YYYY')
   
    for (let i=0; i<startOfMonth; i++){
      weekDays.push(0)
    }
    for (let i=1; i<numberOfDaysInMonth+1; i++){
        weekDays.push(i)
    }
    for (let i=endOfMonth; i<6; i++){
        weekDays.push(0)
    }

    weekDaysNames.map((weekDay) => {
      row.push(<CalendarDayCell day={weekDay} styles={{ width: oneCellWidth, height: 50, top: positionY, left: positionX}}/>);
      positionX = positionX + oneCellWidth + space
    })
    
    positionY = positionY + 50 + space
    positionX = 10

    weekDays.map((weekDay) => {
          const tempDate = moment(`${weekDay}-${moment(presentedDatesRange.from).format('M')}-${moment(presentedDatesRange.from).format('YYYY')}`, 'DD-MM-YYYY')
          
          var tempDateDay = tempDate.format('D')
          var tempDateMonth = tempDate.format('M')
          var tempDateYear = tempDate.format('YYYY')

          let allEvents = events.filter(event => {
            var day = moment(event.eventDate, 'DD/MM/YYYY').format('D')
            var month = moment(event.eventDate, 'DD/MM/YYYY').format('M')
            var year = moment(event.eventDate, 'DD/MM/YYYY').format('YYYY')
            return (tempDateDay === day && tempDateMonth === month && year === tempDateYear)
          })

          row.push(<CalendarCell 
                      isToday={tempDate.format('DD-MM-YYYY') === today} 
                      day={weekDay != 0 ? weekDay : ""}
                      selectedDate={selectedDate}
                      date={tempDate}
                      events={allEvents}
                      onClick={(date, events) => calendarClickActionHandler(date, events)}
                      styles={{ width: oneCellWidth, height: oneCellHeight, top: positionY, left: positionX }}
                  />)

          positionX = positionX + oneCellWidth + space
          cellsCounter = cellsCounter + 1
          if (cellsCounter % 7 === 0){
            positionY = positionY + oneCellHeight + space
            positionX = 10
          }
    })
    return (
      <>
        {row}
      </>
    )
  }


  const renderDailyCalendar = () => {
    if (selectedDate !== null){
      var indents = []
      for (var i = 6; i < 24; i++){
        positionY = positionY + HOUR_CELL_HEIGHT
        var timeText = i<10 ? `0${i}` : `${i}`
        indents.push(
          <div className={styles.hourCellStyle} style={{minHeight: HOUR_CELL_HEIGHT, top: positionY}}>
                <div className={styles.hourLineStyle}></div>
                <text className={styles.hourTextStyle}>{timeText}:00</text>
            </div>
        )

        for (var j = 15; j < 60; j=j+15){
          positionY = positionY + HOUR_CELL_HEIGHT
          indents.push(
            <div className={styles.hourCellStyle} style={{minHeight: HOUR_CELL_HEIGHT, top: positionY}}>
                  <div className={styles.halfHourLineStyle}></div>
                  <text className={styles.halfHourTextStyle}>:{j}</text>
              </div>
          )
        }
      }
      return indents
    }
  }


  const renderDailyEvents = () => {
    if (selectedDate !== null){
      var indents = []
      for (var e = 0; e < selectedEvents.length; e++){

        var positionY = HOUR_CELL_HEIGHT + HOUR_CELL_HEIGHT / 2
        var height = 0
        var width = '76%'
        const eventTime = selectedEvents[e].eventTime
        const eventDirection = selectedEvents[e].eventDirection
        const eventHour = eventTime.slice(0, 2)
        const eventMin = eventTime.slice(3, 5)

        if (Number(eventDirection) === 15){
          height = HOUR_CELL_HEIGHT
        }else if (Number(eventDirection) === 30){
          height = HOUR_CELL_HEIGHT * 2
        }else if (Number(eventDirection) === 45){
          height = HOUR_CELL_HEIGHT * 3
        }else if (Number(eventDirection) === 60){
          height = HOUR_CELL_HEIGHT * 4
        }else if (Number(eventDirection) === 90){
          height = HOUR_CELL_HEIGHT * 6
        }else if (Number(eventDirection) === 120){
          height = HOUR_CELL_HEIGHT * 8
        }

        for (var i = 6; i < 24; i++){
          if (i !== Number(eventHour)){
            
            positionY = positionY + HOUR_CELL_HEIGHT * 4

          }else{
            
            for (var j = 0; j <= 60; j=j+15){
              if (j !== Number(eventMin)){
                 positionY = positionY + HOUR_CELL_HEIGHT
              }else{
                indents.push(
                  <CalendarEventView 
                    positionY={positionY}
                    width={width}
                    height={height}
                    event={selectedEvents[e]}
                    editEventAction={(event) => editEventActionHandler(event)}
                  />
                )
              }
            }

          }
        }
      }
      return indents
    }
  }


  return (
    <div className={generalStyles.mainBackViewStyle}>
      
        <div className={`${generalStyles.blueViewWithShadowStyle} ${styles.leftBottomStyle}`}>
          {renderDailyCalendar()}
          {renderDailyEvents()}
        </div>

        <div className={`${generalStyles.blueViewWithShadowStyle} ${styles.rightTopStyle}`}>
            <CalendarDatePicker presentedDatesRange={presentedDatesRange} dateChanged={(date) => setPresentedDatesRange(date)} />
        </div>

        <div ref={calendarBackViewRef} className={`${generalStyles.blueViewWithShadowStyle} ${styles.rightBottomStyle}`}>
          {renderCalendar()}
        </div>

        <div 
          className={styles.newMessageButtonStyle} 
          onClick={() => {
            setNewEventPopupVisible(true)
          }}
        >
          <img className={styles.newIconsStyle} src={PLUS_ICON} alt="Logo" />
        </div>

        <NewPopup 
          center={center}
          type={POPUPS_TYPES.NEW_EVENT}
          isOpen={newEventPopupVisible}
          donePushAction={(event, needShare) => createAndShareWithPushEventHandler(event, needShare)}
          doneSmsAction={(event, needShare) => createAndShareWithSmsEventHandler(event, needShare)}
          closeEvent={() => setNewEventPopupVisible(null)} />

        <EditEventModal
          isOpen={editEventPopupVisible} 
          center={center}
          event={selectedEvent}
          doneAction={(event) => editEventHandler(event)}
          closeEvent={() => setEditEventPopupVisible(false)}
        />

        <div className={generalStyles.loaderBackView}>
            {loader && <RingLoader color="#176ED0" />}
        </div>

        <SystemMessage />

    </div>
  )
}
  
  
export default EventsPage