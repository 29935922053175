import React from 'react'
import styles from './DashboardItem.module.css'
import { UAPP_LOGO } from '../../assets/images'
import { COLORS, SECTIONS_ITEMS } from '../../constants/constants'
import strings from '../../assets/strings'


const DashboardItem = (props) => {


  const getBorderColor = () => {
    if (props.selectedSection === SECTIONS_ITEMS.WORK){
      return { border: '2px solid #49C4D9', borderColor: `${COLORS.JOBS}`, backgroundColor: `${COLORS.JOBS20}` }
    }
    if (props.selectedSection === SECTIONS_ITEMS.STUDY){
        return { border: '2px solid #49C4D9', borderColor: `${COLORS.STUDY}`, backgroundColor: `${COLORS.STUDY20}` }
    }
    if (props.selectedSection === SECTIONS_ITEMS.RIGHTS){
        return { border: '2px solid #FFDC40', borderColor: `${COLORS.RIGHTS}`, backgroundColor: `${COLORS.RIGHTS20}` }
    }
    if (props.selectedSection === SECTIONS_ITEMS.MORE){
        return { border: '2px solid #F79548', borderColor: `${COLORS.MORE}`, backgroundColor: `${COLORS.MORE20}` }
    }
    return { border: '2px solid #FFFFFF', borderColor: '#FFFFFF' }
  }


  const getTextColor = () => {
    if (props.selectedSection === SECTIONS_ITEMS.WORK || props.selectedSection === SECTIONS_ITEMS.STUDY || props.selectedSection === SECTIONS_ITEMS.MORE){
      return 'white'
    }
    return `${COLORS.WHITE}`
  }


  const getColor = () => {
    if (props.selectedSection === SECTIONS_ITEMS.WORK){
      return `${COLORS.JOBS}`
    }
    if (props.selectedSection === SECTIONS_ITEMS.STUDY){
        return `${COLORS.STUDY}`
    }
    if (props.selectedSection === SECTIONS_ITEMS.RIGHTS){
        return `${COLORS.RIGHTS}`
    }
    if (props.selectedSection === SECTIONS_ITEMS.MORE){
        return `${COLORS.MORE}`
    }
    return `${COLORS.WHITE}`
  }


  const renderBackgroundImage = () => {
    if (props.item.image !== ""){
      if (props.item.text !== ""){
        return (
          <img className={styles.backgroundImageStyle} style={{ opacity: 0.2 }} src={props.item.image} />
        )
      }else{
        return (
          <img className={styles.backgroundImageStyle} style={{ opacity: 1.0 }} src={props.item.image} />
        )
      }
    }else{
      return (
        <img className={styles.backgroundImageStyle} style={{ opacity: 0.2 }}  src={UAPP_LOGO} />
      )
    }
  }


  return (
    <div className={styles.sectionListStyle} style={getBorderColor()}>
        <div className={styles.headerBackViewStyle} style={{backgroundColor: `${getColor()}`, borderTopLeftRadius: '10px', borderTopRightRadius: '10px'}}>
            <text className={styles.headerTextStyle} style={{color: `${getTextColor()}`}}>{props.item.title}</text>
        </div>

        <div className={styles.containerStyle}>
          {renderBackgroundImage()}
          <text className={styles.textRowStyle}>{props.item.text.replace(/(<([^>]+)>)/ig, '')}</text>
        </div>

        <div className={styles.footerBackViewStyle}>
          <button className={styles.modalButtonStyle} style={{backgroundColor: `${getColor()}`}} onClick={() => props.onClickEvent(props.item)}>{strings.DETAILS_BUTTON}</button>
        </div>
              
    </div>
  )
}


export default DashboardItem
