//GENERAL ICONS
export const ARROW_LEFT = require('../images/general_icons/left-arrow.png')
export const ARROW_RIGHT = require('../images/general_icons/right-arrow.png')
export const PLUS_ICON = require('../images/general_icons/plus.png')
export const BURD_LOGO = require('../images/burd_apps_logo.png')

//SIDE MENU ICONS
export const SM_DASHBOARD = require('../images/side_menu_icons/sm_dashboard.png')
export const SM_INFO = require('../images/side_menu_icons/sm_info.png')
export const SM_ANALYTICS = require('../images/side_menu_icons/sm_analysis.png')
export const SM_LOGOUT = require('../images/side_menu_icons/sm_logout.png')
export const SM_MESSAGES = require('../images/side_menu_icons/sm_messages.png')
export const SM_USERS = require('../images/side_menu_icons/sm_users.png')
export const SM_SETTINGS = require('../images/side_menu_icons/sm_settings.png')
export const SM_NEW = require('../images/side_menu_icons/sm_new.png')

//UAPP ICONS
export const UAPP_LOGO = require('../images/uapp_icons/uapp_logo.png')
export const UAPP_BACKGROUND = require('../images/uapp_icons/main_background.png')
export const IC_EMPLOYMENTS = require('../images/uapp_icons/ic_employments.png')
export const IC_MORE = require('../images/uapp_icons/ic_more.png')
export const IC_RIGHTS = require('../images/uapp_icons/ic_rights.png')
export const IC_STUDY = require('../images/uapp_icons/ic_study.png')

export const PHONE_CALL_ICON = require('../images/phone_call.png')
export const EMAIL_ICON = require('../images/email.png')
export const GPS = require('../images/gps.png')
export const CLOCK = require('../images/clock.png')
export const FALSE_ICON = require('../images/close.png')
export const TRUE_ICON = require('../images/accept.png')

export const MESSAGE_TYPE_EDIT_ICON = require('../images/messages_types/message_type_edit.png')
export const MESSAGE_TYPE_EVENT_ICON = require('../images/messages_types/message_type_event.png')
export const MESSAGE_TYPE_INVITE_ICON = require('../images/messages_types/message_type_invite.png')
export const MESSAGE_TYPE_MESSAGE_ICON = require('../images/messages_types/message_type_message.png')
export const MESSAGE_TYPE_SHARE_ICON = require('../images/messages_types/message_type_share.png')

export const DOTS_BLUE_ICON = require('../images/dots_blue.png')
export const DOTS_WHITE_ICON = require('../images/dots_white.png')
export const GROUP_ICON = require('../images/group.png')
export const EVENT_ICON = require('../images/event.png')

export const WHATSAPP_ICON = require('../images/whatsapp.png')
export const EMAIL_USER_ICON = require('../images/email_user.png')

