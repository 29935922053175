import React from 'react'
import styles from './SelectedUsersRow.module.css'
import { ScrollMenu } from 'react-horizontal-scrolling-menu'


const SelectedUsersRow = props => {
  
    return (        
        <div className={styles.selectedItemsViewStyle} style={props.style}>
                <ScrollMenu>
                    {props.selectedUsers.map((user, index) => {
                        if (user.name !== ""){
                            return (
                                <div className={styles.selectedItemStyle}>
                                    <text className={styles.selectedItemTextStyle}>{user.name}</text>
                                </div>
                            )
                        }
                    })} 
                </ScrollMenu>
            </div>
  );
};


export default SelectedUsersRow