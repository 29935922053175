import generalModalStyles from '../../../styles/GeneralModal.module.css'
import generalStyles from '../../../styles/General.module.css'
import styles from '../Filters/FilterPopups.module.css'
import { motion, AnimatePresence } from "framer-motion"
import strings from '../../../assets/strings'
import { COLORS, KEYWORDS } from '../../../constants/constants'
import { useEffect, useRef, useState } from 'react'
import moment from 'moment'

const FilterPopups = (props) => {

    const birthdayFromYearRef = useRef()
    const birthdayFromMonthRef = useRef()
    const birthdayToYearRef = useRef()
    const birthdayToMonthRef = useRef()
    const dischargedFromYearRef = useRef()
    const dischargedToYearRef = useRef()
    const dischargedFromMonthRef = useRef()
    const dischargedToMonthRef = useRef()
    const currentStatusRef = useRef()
    const [presentation, setPresentation] = useState("ALL")
    const [isLonely, setIsLonely] = useState(false)
    const [isCombat, setIsCombat] = useState(false)
    const [isOfficer, setIsOfficer] = useState(false)
    const [isStudy, setIsStudy] = useState(false)
    const [isOther, setIsOther] = useState(false)
    const [birthdayFromYear, setBirthdayFromYear] = useState(null)
    const [birthdayToYear, setBirthdayToYear] = useState(null)
    const [birthdayFromMonth, setBirthdayFromMonth] = useState(null)
    const [birthdayToMonth, setBirthdayToMonth] = useState(null)
    const [dischargedFromYear, setDischargedFromYear] = useState(null)
    const [dischargedToYear, setDischargedToYear] = useState(null)
    const [dischargedFromMonth, setDischargedFromMonth] = useState(null)
    const [dischargedToMonth, setDischargedToMonth] = useState(null)
    const [currentStatus, setCurrentStatus] = useState(null)
    const [selectedKeywords, setSelectedKeywords] = useState([])


    useEffect(() => {
        props.filterAction(presentation, 
                           isLonely, 
                           isCombat, 
                           isOfficer, 
                           isStudy, 
                           isOther, 
                           birthdayFromYear, 
                           birthdayFromMonth, 
                           birthdayToYear, 
                           birthdayToMonth,
                           dischargedFromYear,
                           dischargedFromMonth,
                           dischargedToYear,
                           dischargedToMonth, 
                           currentStatus, 
                           selectedKeywords)
    },[presentation, isLonely, isCombat, isOfficer, isStudy, isOther, birthdayFromYear, birthdayFromMonth, birthdayToYear, birthdayToMonth, dischargedFromYear, dischargedFromMonth, dischargedToYear, dischargedToMonth, currentStatus, selectedKeywords])


    const cleanAllAction = () => {
        setPresentation("ALL")
        setIsLonely(false)
        setIsCombat(false)
        setIsOfficer(false)
        setIsStudy(false)
        setIsOther(false)
        setBirthdayFromYear(null)
        setBirthdayToYear(null)
        setBirthdayFromMonth(null)
        setBirthdayToMonth(null)
        setDischargedFromYear(null)
        setDischargedFromMonth(null)
        setDischargedToYear(null)
        setDischargedToMonth(null)
        setCurrentStatus(null)
        setSelectedKeywords([])
        props.cleanFiltersAction()
    }


    const getYears = () => {
        var tempYears = [""]
        for (var i = moment().year(); i > 1950; i--){
            tempYears.push(i)
        }
        return tempYears
    }


    const getMonth = () => {
        var tempYears = [""]
        for (var i = 1; i < 12; i++){
            tempYears.push(i)
        }
        return tempYears
    }


    const setCurrentStatusValue = () => {
        const currentStatus = currentStatusRef.current.value
        setCurrentStatus(currentStatus)
    }


    const keywordIsSelected = (keyword) => {
        return selectedKeywords.includes(keyword)
    }


    const addKeyword = (keyword) => {
        if (keywordIsSelected(keyword)){
            const temp = selectedKeywords.filter(item => item !== keyword)
            setSelectedKeywords(temp)
        }else{
            const temp = [...selectedKeywords, keyword]
            setSelectedKeywords(temp)
        }
    }


    const renderKeywordsAndSentences = () => {
        return (
          props.center.centerKeywords.map((centerKeyword) => {
            return (
                <div style={{marginTop: 5, marginBottom: 5, flexDirection: 'row', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%'}}>
                    <text style={{marginRight: 5}} className={`${generalStyles.text16}`}>{centerKeyword[1]}</text>
                    <input style={{marginRight: 20}} type="checkbox" name="filters" checked={keywordIsSelected(centerKeyword[1])} onChange={() => addKeyword(centerKeyword[1])} />
                </div>
            )
          })
        )
    }
    

    return(
      <AnimatePresence>
        {props.isOpen && (
            <>
                <motion.div initial={{ scale: 0 }} animate={{ scale: 1, transition: { delay: 0.1, duration: 0.2 } }} exit={{ scale: 0 }} className={styles.backViewWrapperStyle} style={{ top: props.top, left: props.left - 420 }}>
                    <div className={generalModalStyles.headerBackViewStyle}>
                        <text className={generalModalStyles.headerTextStyle}>{strings.FILTER_TITLE}</text>
                    </div>

                    <div className={styles.mainBackViewStyle}>
                        <div style={{marginBottom: 5, flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
                            <text style={{margin: 5}} className={`${generalStyles.text16}`}>{strings.USERS_FILTERS_ALL}</text>
                            <input style={{margin: 5}} type="radio" value={2} name="filters" checked={presentation === "ALL"} onChange={() => setPresentation("ALL")} />
                            <text style={{margin: 5}} className={`${generalStyles.text16}`}>{strings.USERS_FILTERS_APPROVED}</text>
                            <input style={{margin: 5}} type="radio" value={3} name="filters" checked={presentation === "APPROVED"} onChange={() => setPresentation("APPROVED")} />
                            <text style={{margin: 5}} className={`${generalStyles.text16}`}>{strings.USERS_FILTERS_NOT_APPROVED}</text>
                            <input style={{margin: 5}} type="radio" value={1} name="filters" checked={presentation === "NOT_APPROVED"} onChange={() => setPresentation("NOT_APPROVED")} />
                        </div>

                        <div style={{height: 1, marginBottom: 5, width: '100%', backgroundColor: COLORS.BLUE}}></div>
                  
                        <div style={{marginBottom: 5, flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}>

                            <div style={{marginBottom: 5, flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '50%'}}>
                                <div style={{marginTop: 5, marginBottom: 5, flexDirection: 'row', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%'}}>
                                    <text style={{marginRight: 5}} className={`${generalStyles.text16}`}>{strings.IS_LONELY}</text>
                                    <input style={{marginRight: 20}} type="checkbox" value={1} name="filters" checked={isLonely} onChange={() => setIsLonely(!isLonely)} />
                                </div>
                                <div style={{marginTop: 5, marginBottom: 5, flexDirection: 'row', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%'}}>
                                    <text style={{marginRight: 5}} className={`${generalStyles.text16}`}>{strings.IS_COMBAT}</text>
                                    <input style={{marginRight: 20}} type="checkbox" value={1} name="filters" checked={isCombat} onChange={() => setIsCombat(!isCombat)} />
                                </div>
                                <div style={{marginTop: 5, marginBottom: 5, flexDirection: 'row', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%'}}>
                                    <text style={{marginRight: 5}} className={`${generalStyles.text16}`}>{strings.IS_OFFICER}</text>
                                    <input style={{marginRight: 20}} type="checkbox" value={1} name="filters" checked={isOfficer} onChange={() => setIsOfficer(!isOfficer)} />
                                </div>
                                <div style={{marginTop: 5, marginBottom: 5, flexDirection: 'row', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%'}}>
                                    <text style={{marginRight: 5}} className={`${generalStyles.text16}`}>{strings.IS_STUDY}</text>
                                    <input style={{marginRight: 20}} type="checkbox" value={1} name="filters" checked={isStudy} onChange={() => setIsStudy(!isStudy)} />
                                </div>
                                <div style={{marginTop: 5, marginBottom: 5, flexDirection: 'row', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%'}}>
                                    <text style={{marginRight: 5}} className={`${generalStyles.text16}`}>{strings.IS_OTHER}</text>
                                    <input style={{marginRight: 20}} type="checkbox" value={1} name="filters" checked={isOther} onChange={() => setIsOther(!isOther)} />
                                </div>
                            </div>

                            <div style={{marginBottom: 5, flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '50%'}}>
                                {renderKeywordsAndSentences()}
                            </div>

                        </div>

                        <div style={{height: 1, marginBottom: 5, width: '100%', backgroundColor: COLORS.BLUE}}></div>
                        
                        <div style={{marginTop: 5, marginBottom: 5, flexDirection: 'column', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%'}}>
                                
                            <div style={{marginTop: 5, marginBottom: 5, flexDirection: 'row', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%'}}>
                                <select value={currentStatus} className={styles.datePickerStyle} onChange={() => setCurrentStatusValue()} ref={currentStatusRef}>
                                    <option value={strings.NEW_USER_OPTION_1_INPUT_TEXT}>{strings.NEW_USER_OPTION_1_INPUT_TEXT}</option>
                                    <option value={strings.NEW_USER_OPTION_2_INPUT_TEXT}>{strings.NEW_USER_OPTION_2_INPUT_TEXT}</option>
                                    <option value={strings.NEW_USER_OPTION_3_INPUT_TEXT}>{strings.NEW_USER_OPTION_3_INPUT_TEXT}</option>
                                    <option value={strings.NEW_USER_OPTION_4_INPUT_TEXT}>{strings.NEW_USER_OPTION_4_INPUT_TEXT}</option>
                                </select>
                                <text style={{margin: 5}} className={`${generalStyles.text16}`}>{strings.NEW_USER_OPTION_DEFAULT_INPUT_TEXT}</text>
                            </div>

                            <div style={{marginTop: 5, marginBottom: 5, flexDirection: 'row', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%'}}>
                                <select value={birthdayToMonth} className={styles.datePickerStyle} onChange={() => setBirthdayToMonth(birthdayToMonthRef.current.value)} ref={birthdayToMonthRef}>
                                    <option value={""}></option>
                                    {getMonth().map(month => {
                                        return (
                                            <option value={month}>{month}</option>
                                        )
                                    })}
                                </select>
                                <select value={birthdayToYear} className={styles.datePickerStyle} onChange={() => setBirthdayToYear(birthdayToYearRef.current.value)} ref={birthdayToYearRef}>
                                    <option value={""}></option>
                                    {getYears().map(year => {
                                        return (
                                            <option value={year}>{year}</option>
                                        )
                                    })}
                                </select>
                                <text style={{margin: 5}} className={`${generalStyles.text16}`}>{strings.TO_KEY}</text>

                                <select value={birthdayFromMonth} className={styles.datePickerStyle} onChange={() => setBirthdayFromMonth(birthdayFromMonthRef.current.value)} ref={birthdayFromMonthRef}>
                                    <option value={""}></option>
                                    {getMonth().map(month => {
                                        return (
                                            <option value={month}>{month}</option>
                                        )
                                    })}
                                </select>
                                <select value={birthdayFromYear} className={styles.datePickerStyle} onChange={() => setBirthdayFromYear(birthdayFromYearRef.current.value)} ref={birthdayFromYearRef}>
                                    <option value={""}></option>
                                    {getYears().map(year => {
                                        return (
                                            <option value={year}>{year}</option>
                                        )
                                    })}
                                </select>
                                <text style={{margin: 5}} className={`${generalStyles.text16}`}>{strings.FROM_KEY}</text>

                                <text style={{margin: 5}} className={`${generalStyles.text16}`}>{strings.FILTER_BIRTHDAY_YEAR}</text>
                            </div>
                            
                            <div style={{marginTop: 5, marginBottom: 5, flexDirection: 'row', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%'}}>
                                
                                <select value={dischargedToMonth} className={styles.datePickerStyle} onChange={() => setDischargedToMonth(dischargedToMonthRef.current.value)} ref={dischargedToMonthRef}>
                                    <option value={""}></option>
                                    {getMonth().map(month => {
                                        return (
                                            <option value={month}>{month}</option>
                                        )
                                    })}
                                </select>
                                <select value={dischargedToYear} className={styles.datePickerStyle} onChange={() => setDischargedToYear(dischargedToYearRef.current.value)} ref={dischargedToYearRef}>
                                    <option value={""}></option>
                                    {getYears().map(year => {
                                        return (
                                            <option value={year}>{year}</option>
                                        )
                                    })}
                                </select>
                                <text style={{margin: 5}} className={`${generalStyles.text16}`}>{strings.TO_KEY}</text>

                                <select value={dischargedFromMonth} className={styles.datePickerStyle} onChange={() => setDischargedFromMonth(dischargedFromMonthRef.current.value)} ref={dischargedFromMonthRef}>
                                    <option value={""}></option>
                                    {getMonth().map(month => {
                                        return (
                                            <option value={month}>{month}</option>
                                        )
                                    })}
                                </select>
                                <select value={dischargedFromYear} className={styles.datePickerStyle} onChange={() => setDischargedFromYear(dischargedFromYearRef.current.value)} ref={dischargedFromYearRef}>
                                    <option value={""}></option>
                                    {getYears().map(year => {
                                        return (
                                            <option value={year}>{year}</option>
                                        )
                                    })}
                                </select>
                                <text style={{margin: 5}} className={`${generalStyles.text16}`}>{strings.FROM_KEY}</text>

                                <text style={{margin: 5}} className={`${generalStyles.text16}`}>{strings.FILTER_DISCHARGE_YEAR}</text>
                            </div>
                                
                        </div>

                    </div>

                    <div className={generalModalStyles.footerBackViewStyle}>
                        <button className={styles.footerViewStyle_buttonStyle} onClick={() => props.closeEventAction()}>{strings.FILTER_CLOSE_ACTION}</button>
                        <button className={styles.footerViewStyle_buttonStyle} onClick={() => cleanAllAction()}>{strings.FILTER_CLEAN_ALL}</button>
                    </div>

                </motion.div>
            </>
        )}
      </AnimatePresence>
    )
}


export default FilterPopups