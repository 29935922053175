import React from 'react'
import styles from './SuperAdminMessagesPage.module.css'
import generalStyles from '../../styles/General.module.css'
import { useState, useEffect } from 'react'
import strings from '../../assets/strings'
import RingLoader from "react-spinners/RingLoader"
import SystemMessage from '../../components/system_messages/SystemMessage'
import { fetchAdminMessagesAPI } from '../../managers/ServerSuperAdminManager'
import { showErrorMessage, showSuccessMessage } from '../SystemMessagesManager'
import { PLUS_ICON } from '../../assets/images'
import { DEFAULT_MESSAGE_DOWNLOAD_LINK, MESSAGE_TYPES, POPUPS_TYPES } from '../../constants/constants'
import NewPopup from '../../components/popups/NewPopup'
import { sendPushMessageAPI, sendSmsMessageAPI } from '../../managers/ServerMessagesManager'
import AdminMessagesList from '../../components/lists/AdminMessagesList'


const SuperAdminNewPage = (props) => {

  const [loader, setLoader] = useState(false)
  const [messages, setMessages] = useState(false)
  const [newMessagePopVisible, setNewMessagePopVisible] = useState(false)


  useEffect(() => {
    fetchMessages()
  }, [])


  const fetchMessages = async () => {
    setLoader(true)
    const response = await fetchAdminMessagesAPI()
    setLoader(false)

    if (response.data !== null){
      setMessages(response.data)
    }else{
      showErrorMessage(response.error)
    }
  }


  const sendPushMessageEventHandler = async(users, message, link) => {
    await sendPushMessageAPI(users, message, MESSAGE_TYPES.MESSAGE, "ADMIN")
    setNewMessagePopVisible(false)
    showSuccessMessage(strings.SYSTEM_MESSAGE_MESSAGE_SENDED_SUCCESSFULLY)
  }


  const sendSmsMessageEventHandler = async(users, message, link) => {
    let mes = message + DEFAULT_MESSAGE_DOWNLOAD_LINK
    await sendSmsMessageAPI(users, mes, MESSAGE_TYPES.MESSAGE, "ADMIN")
    setNewMessagePopVisible(false)
    showSuccessMessage(strings.SYSTEM_MESSAGE_MESSAGE_SENDED_SUCCESSFULLY)
  }


  return (
    <div className={generalStyles.mainBackViewStyle}>
      <div className={styles.mainBackViewLeftSide}>
        {messages &&
          <div className={`${generalStyles.blueViewWithShadowStyle} ${styles.messagesBackViewStyle}`}>
            <AdminMessagesList items={messages} />
          </div>
        }  
      </div>

      <div
          className={styles.newMessageButtonStyle} 
          onClick={() => {
            setNewMessagePopVisible(true)
          }}
        >
        <img className={styles.newIconsStyle} src={PLUS_ICON} alt="Logo" />
      </div>

      <NewPopup
        type={POPUPS_TYPES.NEW_MESSAGE}
        isOpen={newMessagePopVisible}
        sendPushMessageEvent={(users, message, link) => sendPushMessageEventHandler(users, message, link)} 
        sendSmsMessageEvent={(users, message, link) => sendSmsMessageEventHandler(users, message, link)} 
        closeEvent={() => setNewMessagePopVisible(null)} />

      <SystemMessage />

    </div>
  )
}


export default SuperAdminNewPage
