import moment from "moment/moment"


export function getAllUsers(appUsers, centerUsers){
    for (var cu = 0; cu < centerUsers.length; cu++){
        for (var au = 0; au < appUsers.length; au++){
            if (centerUsers[cu].phone === appUsers[au].phone){
                centerUsers[cu].approved = true
            }
        }
    }

    var allUsersWithDuplicates = centerUsers.concat(appUsers)
    const allUsersWithoutDuplicates = Array.from(new Set(allUsersWithDuplicates.map(a => a.phone))).map(phone => {
        return allUsersWithDuplicates.find(a => a.phone === phone)
    })

    return allUsersWithoutDuplicates
}




export function getAppUsers(appUsers, centerUsers){
    var tempAppUsers = getAllUsers(appUsers, centerUsers)
    return tempAppUsers.filter((user => user.approved === true))
}


function filterDateRanges(list, fromMonth, fromYear, toMonth, toYear, isBirthday){
    //ALL VALUES
    if (fromMonth !== null && fromYear !== null && toYear !== null && toMonth !== null && fromYear !== "" && fromMonth !== "" && toYear !== "" && toMonth !== ""){
      
        var fromFilterDate = moment()
        fromFilterDate.set('year', fromYear)
        fromFilterDate.set('month', fromMonth-1)
        fromFilterDate.set('date', 1)
  
        var toFilterDate = moment()
        toFilterDate.set('year', toYear)
        toFilterDate.set('month', toMonth-1)
        toFilterDate.set('date', 30)
  
        return list.filter((user) => {
          const date = moment(isBirthday ? user.birthDate : user.dischargeDate, "DD/MM/YYYY")
          return date.isValid() ? date.isBetween(fromFilterDate, toFilterDate) : false
        })
      
    //ONLY FROM (PRESENT FROM TO TODAY)
    }else if (fromYear !== null && fromMonth !== null && fromYear !== "" && fromMonth !== ""){
        
        var fromFilterDate = moment()
        fromFilterDate.set('year', fromYear)
        fromFilterDate.set('month', fromMonth-1)
        fromFilterDate.set('date', 1)
  
        return list.filter((user) => {
            const date = moment(isBirthday ? user.birthDate : user.dischargeDate, "DD/MM/YYYY")
            return date.isValid() ? date.isAfter(fromFilterDate) : false
        })
      
    //ONLY TO (PRESENT FROM START TO TO)
    }else if (toYear !== null && toMonth !== null && toYear !== "" && toMonth !== ""){
  
        var toFilterDate = moment()
        toFilterDate.set('year', toYear)
        toFilterDate.set('month', toMonth-1)
        toFilterDate.set('date', 1)
  
        return list.filter((user) => {
            const date = moment(isBirthday ? user.birthDate : user.dischargeDate, "DD/MM/YYYY")
            return date.isValid() ? date.isBefore(toFilterDate) : false
        })
      
    //ONLY YEARS (PRESENT FROM TO TODAY)
    }else if (toYear !== null && toYear !== ""){
      
        var fromFilterDate = moment()
        fromFilterDate.set('year', fromYear)
        fromFilterDate.set('month', 0)
        fromFilterDate.set('date', 1)
  
        var toFilterDate = moment()
        toFilterDate.set('year', toYear)
        toFilterDate.set('month', 11)
        toFilterDate.set('date', 30)
  
        return list.filter((user) => {
            const date = moment(isBirthday ? user.birthDate : user.dischargeDate, "DD/MM/YYYY")
            return date.isValid() ? date.isBetween(fromFilterDate, toFilterDate) : false
        })
      
      //ONLY FROM YEAR (PRESENT FROM TO TODAY)
    }else if (fromYear !== null && fromYear !== ""){
      
        var fromFilterDate = moment()
        fromFilterDate.set('year', fromYear)
        fromFilterDate.set('month', 0)
        fromFilterDate.set('date', 1)
  
        return list.filter((user) => {
            const date = moment(isBirthday ? user.birthDate : user.dischargeDate, "DD/MM/YYYY")
            return date.isValid() ? date.isAfter(fromFilterDate) : false
        })
      
      //ONLY TO YEAR (PRESENT FROM TO TODAY)
    }else if (toYear !== null && toYear !== ""){
      
        var toFilterDate = moment()
        toFilterDate.set('year', toYear)
        toFilterDate.set('month', 11)
        toFilterDate.set('date', 30)
  
        return list.filter((user) => {
            const date = moment(isBirthday ? user.birthDate : user.dischargeDate, "DD/MM/YYYY")
            return date.isValid() ? date.isBefore(toFilterDate) : false
        })
    }

    return list
}


    
    
export function filterAction(list, searchText, presentation, isLonely, isCombat, isOfficer, isStudy, isOther, birthdayFromYear, birthdayFromMonth, birthdayToYear, birthdayToMonth, dischargedFromYear, dischargedFromMonth, dischargedToYear, dischargedToMonth, currentStatus, selectedKeywords){
    var tempList = list
    
    if (searchText !== null){
      tempList = tempList.filter(user => user.name !== undefined && user.name.includes(searchText))
    }
    if (presentation === "APPROVED"){
      tempList = tempList.filter(user => user.approved == true)
    }
    if (presentation === "NOT_APPROVED"){
      tempList = tempList.filter(user => user.approved == false)
    }
    if (isLonely === true){
      tempList = tempList.filter(user => user.isLonely == true)
    }
    if (isCombat === true){
      tempList = tempList.filter(user => user.isCombat == true)
    }
    if (isOfficer === true){
      tempList = tempList.filter(user => user.isOfficer == true)
    }
    if (isStudy === true){
      tempList = tempList.filter(user => user.isStudy == true)
    }
    if (isOther === true){
      tempList = tempList.filter(user => user.isOther == true)
    }

    tempList = filterDateRanges(tempList, birthdayFromMonth, birthdayFromYear, birthdayToMonth, birthdayToYear, true)

    tempList = filterDateRanges(tempList, dischargedFromMonth, dischargedFromYear, dischargedToMonth, dischargedToYear, false)

    if (currentStatus !== null){
      tempList = tempList.filter((user) => user.currentStatus == currentStatus)
    }

    if (selectedKeywords !== undefined && selectedKeywords !== null){
        if (selectedKeywords.length > 0){
        for (var i = 0; i < selectedKeywords.length; i++){
            tempList = tempList.filter(user => {
            if (user.keywords !== undefined){
                return user.keywords.includes(selectedKeywords[i])
            }
            return false
            })
        }
        }
    }
    return tempList
  }



  export function isFilterActive(list, presentation, isLonely, isCombat, isOfficer, isStudy, isOther, birthdayFromYear, birthdayFromMonth, birthdayToYear, birthdayToMonth, dischargedFromYear, dischargedFromMonth, dischargedToYear, dischargedToMonth, currentStatus, selectedKeywords){
    var tempList = list

    if (presentation === "APPROVED"){
        return true
    }
    if (presentation === "NOT_APPROVED"){
        return true
    }
    if (isLonely === true || isCombat === true || isOfficer === true || isStudy === true || isOther === true){
        return true
    }
    if (birthdayFromYear !== null && birthdayFromMonth !== null && birthdayToYear !== null && birthdayToMonth !== null && birthdayFromYear !== "" && birthdayFromMonth !== "" && birthdayToYear !== "" && birthdayToMonth !== ""){
        return true
    
    }else if (birthdayFromYear !== null && birthdayFromMonth !== null && birthdayFromYear !== "" && birthdayFromMonth !== ""){
        return true

    }else if (birthdayToYear !== null && birthdayToMonth !== null && birthdayToYear !== "" && birthdayToMonth !== ""){
        return true

    }else if (birthdayToYear !== null && birthdayToYear !== ""){
        return true
      
    }else if (birthdayFromYear !== null && birthdayFromYear !== ""){
        return true
      
    }else if (birthdayToYear !== null && birthdayToYear !== ""){
        return true
    }
    if (dischargedFromYear !== null && dischargedFromMonth !== null && dischargedToYear !== null && dischargedToMonth !== null && dischargedFromYear !== "" && dischargedFromMonth !== "" && dischargedToYear !== "" && dischargedToMonth !== ""){
        return true
      
    }else if (dischargedFromYear !== null && dischargedFromMonth !== null && dischargedFromYear !== "" && dischargedFromMonth !== ""){
        return true
      
    }else if (dischargedToYear !== null && dischargedToMonth !== null && dischargedToYear !== "" && dischargedToMonth !== ""){
        return true
      
    }else if (dischargedToYear !== null && dischargedToYear !== ""){
        return true
      
    }else if (dischargedFromYear !== null && dischargedFromYear !== ""){
        return true
      
    }else if (dischargedToYear !== null && dischargedToYear !== ""){
        return true 
    }
    if (currentStatus !== null){
        return true
    }
    if (selectedKeywords.length > 0){
      for (var i = 0; i < selectedKeywords.length; i++){
        tempList = tempList.filter(user => {
          if (user.keywords !== undefined){
            return user.keywords.includes(selectedKeywords[i])
          }
          return false
        })
      }
      return true
    }
    return false
  }