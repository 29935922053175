import { createSlice } from "@reduxjs/toolkit"



const centerSlice = createSlice({
    name: 'center',
    initialState: {
        center: null
    },
    reducers: {
        saveCenter: (state, action) => {
            state.center = action.payload.center
        },
        clean: (state, action) => {
            state.center = null
        }
    }
})



export const saveCenter = centerSlice.actions.saveCenter
export const clean = centerSlice.actions.clean
export default centerSlice.reducer