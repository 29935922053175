import styles from './UsersPage.module.css'
import generalStyles from '../../styles/General.module.css'
import { useState, useRef, useEffect } from 'react'
import UsersList from '../../components/lists/UsersList'
import MessagesList from '../../components/lists/MessagesList'
import NewUserModal from '../../components/popups/UsersPopups/NewUserModal'
import DragAndDropModal from '../../components/popups/UsersPopups/DragAndDropModal'
import { PLUS_ICON, TRUE_ICON, FALSE_ICON, DOTS_BLUE_ICON, UAPP_LOGO, WHATSAPP_ICON, EMAIL_USER_ICON } from '../../assets/images'
import strings from '../../assets/strings'
import RingLoader from "react-spinners/RingLoader"
import SystemMessage from '../../components/system_messages/SystemMessage'
import { showSuccessMessage, showErrorMessage } from '../SystemMessagesManager'
import { createUserAPI, fetchAppUsersAPI, fetchCenterUsersAPI, updateUserNotesAPI } from '../../managers/ServerUsersManager'
import { sendPushMessageAPI, saveMessageAPI, fetchUserMessagesAPI, sendDefaultMessageAPI, sendSmsMessageAPI } from '../../managers/ServerMessagesManager'
import { COLORS, MESSAGE_TYPES, POPUPS_TYPES } from '../../constants/constants'
import EditUserModal from '../../components/popups/UsersPopups/EditUserModal'
import FilterPopups from '../../components/popups/Filters/FilterPopups'
import moment from 'moment'
import { filterAction, getAllUsers, isFilterActive } from '../../constants/Utils'
import NewPopup from '../../components/popups/NewPopup'
import FloatGroup from 'react-float-button'
import { DEFAULT_MESSAGE_DOWNLOAD_LINK } from '../../constants/constants'
import { useSelector, useDispatch } from 'react-redux'
import { saveUsers } from '../../redux/UserReducer'
import _ from 'underscore'
import MessagesListDefaultItem from '../../components/items/MessagesListDefaultItem'
import UserNotesModal from '../../components/popups/UsersPopups/UserNotesModal'


const UsersPage = () => {

  const users = useSelector((state) => state.users.users)
  const center = useSelector((state) => state.center.center)
  const [selectedUser, setSelectedUser] = useState(null)
  const [filterPopupVisible, setFilterPopupVisible] = useState(false)
  const [filterIsActive, setFilterIsActive] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [presentedUsers, setPresentedUsers] = useState([])
  const [userMessages, setUserMessages] = useState([])
  const [selectedSubSection, setSelectedSubSection] = useState("DETAILS")
  const searchInputRef = useRef()
  const messagesFilterRef = useRef()
  const [newMessagePopupVisible, setNewMessagePopupVisible] = useState(false)
  const [newUserPopupVisible, setNewUserPopupVisible] = useState(false)
  const [dragAndDropPopupVisible, setDragAndDropPopupVisible] = useState(false)
  const [editUserPopupVisible, setEditUserPopupVisible] = useState(false)
  const [editUserNotesPopupVisible, setEditUserNotesPopupVisible] = useState(false)
  const [filterUserMassages, setFilterUserMassages] = useState("ALL")
  const [loader, setLoader] = useState(false)
  const [mousePos, setMousePos] = useState({})
  const dispatch = useDispatch()


  useEffect(() => {
    const handleMouseMove = (event) => {
      if (filterPopupVisible === false){
        setMousePos({ x: event.clientX, y: event.clientY })
      }
    };

    window.addEventListener('mousemove', handleMouseMove)

    return () => {
      window.removeEventListener('mousemove', handleMouseMove)
    };
  }, [filterPopupVisible])


  useEffect(() => {
    var tempList = users
    tempList = tempList.filter(user => user.name !== undefined && user.name.toLowerCase().includes(searchText.toLowerCase()))
    setPresentedUsers(tempList)
  }, [searchText])


  const refreshUsers = async () => {
    const users = await fetchAppUsersAPI(center.centerCity)
    const centerUsers = await fetchCenterUsersAPI(center.centerName)
    const allUsers = getAllUsers(users.data, centerUsers.data)
    const tempSelectedUser = allUsers.find(a => a.phone === selectedUser.phone)
    setSelectedUser(tempSelectedUser)
    dispatch(saveUsers({ users: allUsers }))
  }


  const sendPushMessageEventHandler = async (users, message, link) => {
    await sendPushMessageAPI(users, message, MESSAGE_TYPES.MESSAGE, center.centerName)
    setNewMessagePopupVisible(false)
    showSuccessMessage(strings.SYSTEM_MESSAGE_MESSAGE_SENDED_SUCCESSFULLY)
    fetchMessages(selectedUser)
  }



  const sendSmsMessageEventHandler = async (users, message, link) => {
    let mes = message + DEFAULT_MESSAGE_DOWNLOAD_LINK
    await sendSmsMessageAPI(users, mes, MESSAGE_TYPES.MESSAGE, center.centerName)
    setNewMessagePopupVisible(false)
    showSuccessMessage(strings.SYSTEM_MESSAGE_MESSAGE_SENDED_SUCCESSFULLY)
    fetchMessages(selectedUser)
  }


  const fetchMessages = async (user) => {
    setLoader(true)
    const response = await fetchUserMessagesAPI(user, center.centerName)
    setLoader(false)

    if (response.data !== null){
      setUserMessages(response.data)
    }else{
      showErrorMessage(response.error)
    }
  }


  const saveOrUpdateUserNotes = async(notes, message) => {
    setLoader(true)
    const responseStatus = await updateUserNotesAPI(center.centerName, selectedUser, notes)
    setLoader(false)

    if (message != ""){
      await saveMessageAPI(selectedUser, message, MESSAGE_TYPES.EDIT, center.centerName)
      fetchMessages(selectedUser)
    }

    if (responseStatus){
      showSuccessMessage(strings.SYSTEM_MESSAGE_USER_ADDED_SUCCESSFULLY)
      refreshUsers()
    }else{
      showErrorMessage(strings.SYSTEM_MESSAGE_USER_ADDED_ERROR)
    }
  }


  const saveOrUpdateUserWithMessage = async(user, message) => {
    await saveOrUpdateUser(user)

    if (message != ""){
      const tempUsers = [user]
      await saveMessageAPI(tempUsers, message, MESSAGE_TYPES.EDIT, center.centerName)
      fetchMessages(selectedUser)
    }
  }


  const saveOrUpdateUser = async(user) => {
    setLoader(true)
    const responseStatus = await createUserAPI(center.centerName, user)

    setLoader(false)
    setNewUserPopupVisible(false)
    
    if (responseStatus){

      showSuccessMessage(strings.SYSTEM_MESSAGE_USER_ADDED_SUCCESSFULLY)
      refreshUsers()

    }else{
      showErrorMessage(strings.SYSTEM_MESSAGE_USER_ADDED_ERROR)
    }
  }


  const openWhatsappAction = (number) =>{
    if (number){
      let url = `https://web.whatsapp.com/send?phone=+972` + number
      window.open(url)
    }else{
      let url = `https://api.whatsapp.com`
      window.open(url)
    }
  }
  

  const sendEmailAction = () =>{
    window.location = 'mailto:' + selectedUser.email
  }


  // const deleteUser = async(user) => {
  //   confirmAlert({
  //     customUI: ({ onClose }) => {
  //       return (
  //         <div className='custom-ui'>
  //           <h1 style={{textAlign: 'center'}}>{strings.DELETE_USER_ARE_YOU_SURE_TITLE}?</h1>
  //           <p style={{textAlign: 'center'}}>{strings.DELETE_USER_ARE_YOU_SURE_SUB_TITLE} {`${user.firstName} ${user.lastName}`}</p>

  //           <div style={{height: 40, width: '100%'}}>
  //             <button 
  //               style={{height: '100%', width: '50%', borderRadius: 10, color: COLORS.BLUE_DARK }} 
  //               onClick={onClose}>{strings.DELETE_USER_ARE_YOU_SURE_NO_BUTTON}
  //             </button>

  //             <button
  //               style={{height: '100%', width: '50%', borderRadius: 10, color: COLORS.BLUE_DARK }} 
  //               onClick={() => {
  //                 onClose()
  //               }}
  //             >
  //               {strings.DELETE_USER_ARE_YOU_SURE_YES_BUTTON}
  //             </button>
  //           </div>
  //         </div>
  //       );
  //     }
  //   });
  // }


  const createMessage = () => {
    var text = `${center.centerDefaultMessage}` + "ֿ\n"
    text = text + "ֿ\n" + DEFAULT_MESSAGE_DOWNLOAD_LINK
    return text
  }


  const sendDefaultMessageHandler = async () => {
    const response = await sendDefaultMessageAPI(selectedUser, createMessage(), MESSAGE_TYPES.INVITE, center.centerName)
    showSuccessMessage(strings.SYSTEM_MESSAGE_MESSAGE_SENDED_SUCCESSFULLY)
    fetchMessages(selectedUser)
  }


  const onItemClickHandler = (user) => {
    setSelectedUser(user)
    setUserMessages([])
    fetchMessages(user)
  }


  const getMessagesList = () => {
    if (filterUserMassages !== "ALL"){
      return userMessages.filter(message => message.messageStatus === filterUserMassages).sort((a, b) => (a.messageDate > b.messageDate ? -1 : 1))
    }
    return userMessages.sort((a, b) => (a.messageDate > b.messageDate ? -1 : 1))
  }


  const renderUserStatusRow = (value) => {
    if (value !== null){
      return (
        <img className={styles.userStatusIconStyle} src={value ? TRUE_ICON : FALSE_ICON} />
      )
    }
  }


  const renderKeywords = () => {
    if (selectedUser.keywords !== undefined){
       return selectedUser.keywords.map(keyword => {
          return (
            <div className={ styles.keywordTagStyle }>
              <text className={styles.userKeywordsTextStyle}>{keyword}</text>
            </div>
          )
        })
    }
  }


  const renderNotes = () => {
    if (selectedUser.notes !== null){
      return selectedUser.notes
    }
  }


  const renderNotesSection = () => {
    if (selectedUser.notes !== undefined){
      return (
          <textarea disabled placeholder={renderNotes()} className={styles.textAreaStyle} />
      )
    }
  }


  const filterActionHandler = (presentation, isLonely, isCombat, isOfficer, isStudy, isOther, birthdayFromYear, birthdayFromMonth, birthdayToYear, birthdayToMonth, dischargedFromYear, dischargedFromMonth, dischargedToYear, dischargedToMonth, currentStatus, selectedKeywords) => {
    var tempList = filterAction(users, searchText, presentation, isLonely, isCombat, isOfficer, isStudy, isOther, birthdayFromYear, birthdayFromMonth, birthdayToYear, birthdayToMonth, dischargedFromYear, dischargedFromMonth, dischargedToYear, dischargedToMonth, currentStatus, selectedKeywords) 
    var tempFilletIsActive = isFilterActive(users, presentation, isLonely, isCombat, isOfficer, isStudy, isOther, birthdayFromYear, birthdayFromMonth, birthdayToYear, birthdayToMonth, dischargedFromYear, dischargedFromMonth, dischargedToYear, dischargedToMonth, currentStatus, selectedKeywords)
    setFilterIsActive(tempFilletIsActive)
    setPresentedUsers(tempList)
  }


  const cleanFiltersActionHandler = () => {
    setPresentedUsers(users)
    setFilterPopupVisible(false)
  }


  const renderSearchSection = () => {
    return (
      <div className={`${generalStyles.blueViewWithShadowStyle} ${styles.searchBackViewStyle}`}>
          <text className={`${generalStyles.text16}`} style={{ width: 40, margin: 5, cursor: 'pointer', textDecoration: 'underline', color: filterIsActive ? COLORS.RED : COLORS.BLUE }} onClick={() => setFilterPopupVisible(true)}>{strings.FILTER_BUTTON}</text>
          <input placeholder={strings.SEARCH_PLACEHOLDER} className={styles.controlStyle} onChange={() => setSearchText(searchInputRef.current.value)} ref={searchInputRef} type='text' id='search'/>
          <div className={styles.totalDataBackViewStyle}>
            <text className={`${generalStyles.text20}`}>{presentedUsers.length}</text>
          </div>
      </div>
    )
  }


  const renderUserListSection = () => {
    return (
      <div className={`${generalStyles.blueViewWithShadowStyle} ${styles.usersListBackViewStyle}`}>
        <UsersList showFullData={false} items={presentedUsers} selectedUser={selectedUser} onItemClick={(selectedUser) => onItemClickHandler(selectedUser)} />
      </div>
    )
  }


  const renderEmptyStateView = () => {
    if (selectedUser === null){
      return(
        <div className={`${generalStyles.blueViewWithShadowStyle} ${styles.emptyStateBackSection}`}>
          <img className={styles.emptyStateImage} src={UAPP_LOGO} />
        </div>
      )
    }
  }


  const renderBirthdayValue = () => {
    const temp = moment(selectedUser.birthDate, "DD/MM/YYYY")
    return (
      <text className={styles.userDetailsSmallTextStyle}>{temp.isValid() ? temp.format('DD/MM/YYYY') : '---'}</text>
    )
  }


  const renderDischargeDateValue = () => {
    const temp = moment(selectedUser.dischargeDate, "DD/MM/YYYY")
    return (
      <text className={styles.userDetailsSmallTextStyle}>{temp.isValid() ? temp.format('DD/MM/YYYY') : '---'}</text>
    )
  }


  const renderEmailButton = () => {
    if (selectedUser.email !== undefined){
      return (
        <button className={styles.leftSectionBackStyle_footerViewStyle_buttonStyle} onClick={() => sendEmailAction()}><img className={styles.iconStyle} src={EMAIL_USER_ICON} /></button>
      )
    }
  }


  const renderUserDetailsSection = () => {
    return (
      <div className={`${generalStyles.blueViewWithShadowStyle} ${styles.dataBackViewStyle}`}>
        {renderEmptyStateView()}

        {selectedUser &&
          <>
            <div className={styles.userDataSubSection}>
              <text className={styles.userNameTextStyle}>{selectedUser.name}</text>
            </div>

            <div className={styles.userDataSubSection}>
              <table className={styles.tableStyle}>
                <tr style={{ height: '30%' }}>
                  <th style={{ width: '25%' }}><text className={styles.userDetailsSmallTextUnderlineStyle}>{strings.NEW_USER_ID_PLACEHOLDER}</text></th>
                  <th style={{ width: '25%' }}><text className={styles.userDetailsSmallTextUnderlineStyle}>{strings.NEW_USER_PHONE_NUMBER_PLACEHOLDER}</text></th>
                  <th style={{ width: '25%' }}><text className={styles.userDetailsSmallTextUnderlineStyle}>{strings.NEW_USER_EMAIL_PLACEHOLDER}</text></th>
                  <th style={{ width: '25%' }}><text className={styles.userDetailsSmallTextUnderlineStyle}>{strings.NEW_USER_CITY_PLACEHOLDER}</text></th>
                </tr>

                <tr>
                  <td>
                    <text className={styles.userDetailsSmallTextStyle}>{selectedUser.idNumber ? selectedUser.idNumber : "---"}</text>
                  </td>

                  <td>
                    {selectedUser.phone &&
                      <text className={styles.userDetailsSmallTextStyle}>{selectedUser.phone}</text>
                    }
                  </td>

                  <td>
                    {selectedUser.email &&
                      <text className={styles.userDetailsSmallTextStyle}>{selectedUser.email}</text>
                    }
                  </td>

                  <td>
                    <text className={styles.userDetailsSmallTextStyle}>{selectedUser.city ? selectedUser.city : "---"}</text>
                  </td>
                </tr>
              </table>
            </div>

            <div className={styles.userDataSubSection}>
              <table className={styles.tableStyle}>
                <tr style={{ height: '30%' }}>
                  <th style={{ width: '33%' }}><text className={styles.userDetailsSmallTextUnderlineStyle}>{strings.NEW_USER_OPTION_DEFAULT_INPUT_TEXT}</text></th>
                  <th style={{ width: '33%' }}><text className={styles.userDetailsSmallTextUnderlineStyle}>{strings.NEW_USER_BIRTHDAY_DATE_TITLE}</text></th>
                  <th style={{ width: '33%' }}><text className={styles.userDetailsSmallTextUnderlineStyle}>{strings.NEW_USER_DISCHARGE_DATE_TITLE}</text></th>
                </tr>

                <tr>
                  <td>
                    <text className={styles.userDetailsSmallTextStyle}>{selectedUser.currentStatus ? selectedUser.currentStatus : "---"}</text>
                  </td>

                  <td>
                    {renderBirthdayValue()}
                  </td>

                  <td>
                    {renderDischargeDateValue()}
                  </td>
                </tr>
              </table>
            </div>

            <div className={styles.userDataSubSection}>
              <table className={styles.tableStyle} style={{ minHeight: 100 }}>
                <tr style={{ height: '30%' }}>
                  <th style={{ width: '20%' }}><text className={styles.userDetailsSmallTextUnderlineStyle}>{strings.IS_LONELY}</text></th>
                  <th style={{ width: '20%' }}><text className={styles.userDetailsSmallTextUnderlineStyle}>{strings.IS_COMBAT}</text></th>
                  <th style={{ width: '20%' }}><text className={styles.userDetailsSmallTextUnderlineStyle}>{strings.IS_OFFICER}</text></th>
                  <th style={{ width: '20%' }}><text className={styles.userDetailsSmallTextUnderlineStyle}>{strings.IS_STUDY}</text></th>
                  <th style={{ width: '20%' }}><text className={styles.userDetailsSmallTextUnderlineStyle}>{strings.IS_OTHER}</text></th>
                </tr>

                <tr>
                  <td>{renderUserStatusRow(selectedUser.isLonely)}</td>
                  <td>{renderUserStatusRow(selectedUser.isCombat)}</td>
                  <td>{renderUserStatusRow(selectedUser.isOfficer)}</td>
                  <td>{renderUserStatusRow(selectedUser.isStudy)}</td>
                  <td>{renderUserStatusRow(selectedUser.isOther)}</td>
                  
                </tr>
              </table>
            </div>

            <div className={styles.userKeywordsDataSubSection}>
              <table className={styles.tableStyle}>
                <tr style={{ height: '30%' }}>
                  <th style={{ width: '100%' }}><text className={styles.userDetailsSmallTextUnderlineStyle}>{strings.NEW_CENTER_KEYWORDS}</text></th>
                </tr>
                <tr style={{ minHeight: 80, display: 'flex', alignItems: 'center', backgroundColor: '#E7F6FE', borderBottomLeftRadius: 10, borderBottomRightRadius: 10, justifyContent: 'flex-end', flexDirection: 'row' }}>
                  {renderKeywords()}
                </tr>
              </table>
            </div>

            <div className={styles.userKeywordsDataSubSection}>
              <table className={styles.tableStyle}>
                <tr style={{ minHeight: 80, display: 'flex', alignItems: 'center', backgroundColor: '#E7F6FE', borderRadius: 10, justifyContent: 'flex-end', flexDirection: 'row' }}>
                  {!selectedUser.approved && 
                    <MessagesListDefaultItem selectedUser={selectedUser} onClickEvent={() => sendDefaultMessageHandler()} />
                  }
                  <button className={styles.leftSectionBackStyle_footerViewStyle_buttonStyle} onClick={() => openWhatsappAction(selectedUser.phone)}><img className={styles.iconStyle} src={WHATSAPP_ICON} /></button>
                  {renderEmailButton()}

                  

                </tr>
              </table>
            </div>

            <img 
              className={styles.dotsIconStyle} 
              src={DOTS_BLUE_ICON}
              onClick={() => {
                setEditUserPopupVisible(true)
              }} 
            />
          </>
        }
      </div>
    )
  }


  const renderUserMessagesSection = () => {
    return (
      <div className={`${generalStyles.blueViewWithShadowStyle} ${styles.dataBackViewStyle}`}>
          {selectedUser &&
            <div className={styles.filterMassagesBackViewStyle}>
              <select value={filterUserMassages} style={{minWidth: '120px'}} className={styles.messagesFilterPickerStyle} onChange={() => setFilterUserMassages(messagesFilterRef.current.value)} ref={messagesFilterRef} placeholder={strings.FILTER_MESSAGES_ALL}>
                  <option value={"ALL"}>{strings.FILTER_MESSAGES_ALL}</option>
                  <option value={MESSAGE_TYPES.MESSAGE}>{strings.NOTIFICATION_TITLE_MESSAGE_TYPES_MESSAGE}</option>
                  <option value={MESSAGE_TYPES.INVITE}>{strings.NOTIFICATION_TITLE_MESSAGE_TYPES_INVITE}</option>
                  <option value={MESSAGE_TYPES.SHARING}>{strings.NOTIFICATION_TITLE_MESSAGE_TYPES_SHARING}</option>
                  <option value={MESSAGE_TYPES.EVENT}>{strings.NOTIFICATION_TITLE_MESSAGE_TYPES_EVENT}</option>
                  <option value={MESSAGE_TYPES.EDIT}>{strings.NOTIFICATION_TITLE_MESSAGE_TYPES_EDIT}</option>
              </select>

              {!selectedUser.approved && 
                <div style={{ flexDirection: 'column', position: 'absolute', top: 0, left: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <MessagesListDefaultItem selectedUser={selectedUser} onClickEvent={() => sendDefaultMessageHandler()} />
                </div>
              }

            </div>
          }

          <div className={styles.messagesBackViewStyle}>
            <MessagesList items={getMessagesList()} selectedUser={selectedUser} />
          </div>
      </div>
    )
  }


  const renderUserNotesSection = () => {
    return (
      <div className={`${generalStyles.blueViewWithShadowStyle} ${styles.dataBackViewStyle}`}>
          {renderEmptyStateView()}

          {selectedUser &&
            renderNotesSection()
          }
          {selectedUser &&
            <img 
              className={styles.dotsIconStyle} 
              src={DOTS_BLUE_ICON}
              onClick={() => {
                setEditUserNotesPopupVisible(true)
              }}
            />
        }
      </div>
    )
  }


  const renderUserOthersSection = () => {
    return (
      <div className={`${generalStyles.blueViewWithShadowStyle} ${styles.dataBackViewStyle}`}>
        {renderEmptyStateView()}
      </div>
    )
  }


  return (
    <div className={generalStyles.mainBackViewStyle}>
        <div className={styles.mainBackViewLeftSide}>
          <div className={`${generalStyles.blueViewWithShadowStyle} ${styles.sectionSelectorBackViewStyle}`}>
              
              <img className={styles.generalIconStyle} src={WHATSAPP_ICON} onClick={() => openWhatsappAction()}/>

              <text
                onClick={() => setSelectedSubSection("OTHERS")}
                className={selectedSubSection === "OTHERS" ? styles.sectionSelectorItemSelectedStyle : styles.sectionSelectorItemStyle}>
                {strings.USER_SUBSECTION_OTHERS}
              </text>

              <text
                onClick={() => setSelectedSubSection("MESSAGE")}
                className={selectedSubSection === "MESSAGE" ? styles.sectionSelectorItemSelectedStyle : styles.sectionSelectorItemStyle}>
                {strings.USER_SUBSECTION_MESSAGES}
              </text>

              <text
                onClick={() => setSelectedSubSection("NOTES")}
                className={selectedSubSection === "NOTES" ? styles.sectionSelectorItemSelectedStyle : styles.sectionSelectorItemStyle}>
                {strings.USER_SUBSECTION_NOTES}
              </text>

              <text
                onClick={() => setSelectedSubSection("DETAILS")}
                className={selectedSubSection === "DETAILS" ? styles.sectionSelectorItemSelectedStyle : styles.sectionSelectorItemStyle}>
                {strings.USER_SUBSECTION_DETAILS}
              </text>

          </div>

          {selectedSubSection === "DETAILS" &&
              renderUserDetailsSection()
          }

          {selectedSubSection === "NOTES" &&
            renderUserNotesSection()
          }

          {selectedSubSection === "OTHERS" &&
            renderUserOthersSection()
          }

          {selectedSubSection === "MESSAGE" &&
            renderUserMessagesSection()
          }        
        </div>

        <div className={styles.mainBackViewRightSide}>
            {renderSearchSection()}
            {renderUserListSection()}
        </div>

       <div className={styles.newMessageButtonStyle}>
          <FloatGroup margin={100} delay={0.02}>
            <div className={styles.newMessageButtonStyle}>
              <img className={`${styles.newIconsStyle}`} src={PLUS_ICON} />
            </div>

            <div className={styles.newMessageButtonTextStyle} onClick={() => setNewMessagePopupVisible(true)}>
              <text style={{textAlign: 'center', color: COLORS.WHITE, fontSize: 14}}>{strings.NEW_SELECTOR_NEW_MESSAGE_1}</text>
              <text style={{textAlign: 'center', color: COLORS.WHITE, fontSize: 14}}>{strings.NEW_SELECTOR_NEW_MESSAGE_2}</text>
            </div>
            <div className={styles.newMessageButtonTextStyle} onClick={() => setNewUserPopupVisible(true)}>
              <text style={{textAlign: 'center', color: COLORS.WHITE, fontSize: 14}}>{strings.NEW_SELECTOR_NEW_USER_1}</text>
              <text style={{textAlign: 'center', color: COLORS.WHITE, fontSize: 14}}>{strings.NEW_SELECTOR_NEW_USER_2}</text>
            </div>
            <div className={styles.newMessageButtonTextStyle} onClick={() => setDragAndDropPopupVisible(true)}>
              <text style={{textAlign: 'center', color: COLORS.WHITE, fontSize: 14}}>{strings.NEW_SELECTOR_UPLOAD_FILE_1}</text>
              <text style={{textAlign: 'center', color: COLORS.WHITE, fontSize: 14}}>{strings.NEW_SELECTOR_UPLOAD_FILE_2}</text>
            </div>
          </FloatGroup>
        </div>

        <div className={generalStyles.loaderBackView}>
            {loader && <RingLoader color="#176ED0" />}
        </div>

        <SystemMessage />

        <NewPopup
          center={center}
          type={POPUPS_TYPES.NEW_MESSAGE}
          isOpen={newMessagePopupVisible} 
          sendPushMessageEvent={(users, message, link) => sendPushMessageEventHandler(users, message, link)} 
          sendSmsMessageEvent={(users, message, link) => sendSmsMessageEventHandler(users, message, link)} 
          closeEvent={() => setNewMessagePopupVisible(null)} />

        <DragAndDropModal
          center={center}
          isOpen={dragAndDropPopupVisible}
          doneEvent={() => {
            refreshUsers()
            setDragAndDropPopupVisible(false)
          }}
          closeEvent={() => setDragAndDropPopupVisible(false)} />

        <FilterPopups
          isOpen={filterPopupVisible}
          top={mousePos.y}
          left={mousePos.x}
          center={center}
          filterAction={(presentation, isLonely, isCombat, isOfficer, isStudy, isOther, birthdayFromYear, birthdayFromMonth, birthdayToYear, birthdayToMonth, dischargedFromYear, dischargedFromMonth, dischargedToYear, dischargedToMonth, currentStatus, selectedKeywords) => filterActionHandler(presentation, isLonely, isCombat, isOfficer, isStudy, isOther, birthdayFromYear, birthdayFromMonth, birthdayToYear, birthdayToMonth, dischargedFromYear, dischargedFromMonth, dischargedToYear, dischargedToMonth, currentStatus, selectedKeywords)}
          closeEventAction={() => setFilterPopupVisible(false)}
          cleanFiltersAction={() => cleanFiltersActionHandler()}
        />

        <UserNotesModal 
          isOpen={editUserNotesPopupVisible}
          center={center}
          user={selectedUser}
          updateUserAction={(user, message) => {
            setEditUserNotesPopupVisible(false)
            saveOrUpdateUserNotes(user.notes, message)
          }}
          closeEvent={() => setEditUserNotesPopupVisible(false)}
        />

        <EditUserModal
          isOpen={editUserPopupVisible}
          center={center}
          user={selectedUser}
          updateUserAction={(user, message) => {
            setEditUserPopupVisible(false)
            saveOrUpdateUserWithMessage(user, message)
          }}
          // deleteUserAction={(user) => {
          //   setEditUserPopupVisible(false)
          //   deleteUser(user)
          // }}
          closeEvent={() => setEditUserPopupVisible(false)} />

        <NewUserModal
          isOpen={newUserPopupVisible}
          center={center}
          saveNewUser={(user) => {
            setNewUserPopupVisible(false)
            saveOrUpdateUser(user)
          }}
          closeEvent={() => setNewUserPopupVisible(false)} />
    </div>
  )
}


export default UsersPage
