import { getDatabase, ref, child, get, set } from "firebase/database"
import strings from '../assets/strings'
import { signInWithEmailAndPassword, sendPasswordResetEmail, signOut } from "firebase/auth"
import { SETTINGS } from "../constants/constants"


const CENTER = "centers/"


const isAdmin = (email) => {
    const isFound = SETTINGS.SUPER_ADMIN_EMAIL.some(element => {
        if (email === element) {
          return true
        }
        return false
    })
    return isFound
}


export async function loginAPI(auth, email, password){

    try {
        
        await signInWithEmailAndPassword(auth, email, password)
        if (!isAdmin(email)){
            return fetchAccountAPI(email)
        }else{
            return{ data: null, isAdmin: true, error: null }
        }

    } catch (err) {

        return { data: null, isAdmin: false, error: strings.SYSTEM_MESSAGE_LOGIN_ERROR }
    }
}



export async function logoutAPI(auth){
    signOut(auth)
  };



export async function sendPasswordResetAPI(auth, email){
    try {

      await sendPasswordResetEmail(auth, email)
      return { data: strings.SYSTEM_MESSAGE_RESTORE_PASSWORD_SUCCESS, error: null }

    } catch (err) {
        return { data: null, error: strings.SYSTEM_MESSAGE_RESTORE_PASSWORD_ERROR }
    }
}


export async function fetchAccountAPI(email){

    const dbRef = ref(getDatabase())
    var success = false
    var tempCenterCity = ""
    var tempCenterLogoUrl = ""
    var tempCenterName = ""
    var tempCenterKeywords = []
    var tempCenterDefaultMessage = ""
    var tempCenterKey = ""

    return get(child(dbRef, CENTER)).then((snapshot) => {

        try {
            if (snapshot.exists()) {
                snapshot.forEach((jobSnapshot) => {
                    
                    var obj = jobSnapshot.val()
                    obj.centerManagers.forEach((centerManagerEmail) => {
                        if (email === centerManagerEmail){
                            success = true
                            tempCenterKey = jobSnapshot.key
                            tempCenterCity = obj.centerCity
                            tempCenterLogoUrl = obj.centerLogoUrl
                            tempCenterName = obj.centerName
                            tempCenterKeywords = obj.centerKeywords
                            tempCenterDefaultMessage = obj.centerDefaultMessage
                        }
                    })
                })

                if (!success){
                    return { data: null, isAdmin: false, error: strings.SYSTEM_MESSAGE_LOGIN_ERROR }
                }else{
                    const user = { centerKey: tempCenterKey,
                                    centerName: tempCenterName, 
                                    centerLogoUrl: tempCenterLogoUrl, 
                                    centerCity: tempCenterCity,
                                    centerKeywords: tempCenterKeywords,
                                    centerDefaultMessage: tempCenterDefaultMessage,
                                    centerUserEmail: email }
    
                    return { data: user, isAdmin: false, error: null }
                }
    
            }else{
                return { data: null, isAdmin: false, error: strings.SYSTEM_MESSAGE_GENERAL_ERROR }
            }

        } catch (error) {
            return { data: null, isAdmin: false, error: strings.SYSTEM_MESSAGE_GENERAL_ERROR }
        }
    })
}