import { createSlice } from "@reduxjs/toolkit"



const dashboardSlice = createSlice({
    name: 'jobs',
    initialState: {
        jobs: [],
        studies: [],
        more: [],
        rights: []
    },
    reducers: {
        saveJobs: (state, action) => {
            state.jobs = action.payload.jobs
        },
        saveStudies: (state, action) => {
            state.studies = action.payload.studies
        },
        saveMore: (state, action) => {
            state.more = action.payload.more
        },
        saveRights: (state, action) => {
            state.rights = action.payload.rights
        },
        clean: (state, action) => {
            state.jobs = null
        }
    }
})



export const saveJobs = dashboardSlice.actions.saveJobs
export const saveStudies = dashboardSlice.actions.saveStudies
export const saveMore = dashboardSlice.actions.saveMore
export const saveRights = dashboardSlice.actions.saveRights
export const clean = dashboardSlice.actions.clean
export default dashboardSlice.reducer