import React from 'react'
import styles from './CentersListItem.module.css'
import generalStyle from '../../styles/General.module.css'


const CentersListItem = props => {

    const renderManagers = () => {
        var tempText = ""
        for (var i = 0; i < props.item.centerManagers.length; i++){
            tempText = tempText + "\n" + props.item.centerManagers[i] + "\n"
        }
        return tempText
    }

  
    return (        
        <div className={`${generalStyle.whiteViewWithShadowStyle} ${styles.sectionItemStyle}`} style={props.isSelected ? { background: '#C2DAEE' } : { background: '#FFFFFF' }} onClick={() => props.onItemClick()}>
            
            <div className={styles.textBackStyle}>
                <text className={`${generalStyle.text12} ${styles.textStyle}`}>{props.item.centerDefaultMessage}</text>
            </div>

            <div className={styles.lineViewStyle}></div>

            <div className={styles.textBackStyle} style={{ width: '300px' }}>
                <text className={`${generalStyle.text14} ${styles.textStyle}`}>{renderManagers()}</text>
            </div>

            <div className={styles.lineViewStyle}></div>

            <div className={styles.textBackStyle} style={{ width: '240px' }}>
                <text className={`${generalStyle.text14} ${styles.textStyle}`}>{props.item.centerCity}</text>
            </div>

            <div className={styles.lineViewStyle}></div>

            <div className={styles.textBackStyle} style={{ width: '250px' }}>
                <text className={`${generalStyle.text14} ${styles.textStyle}`}>{props.item.centerName}</text>
            </div>

            <img className={styles.logoImageStyle} src={props.item.centerLogoUrl} alt="Logo" />
        </div>
  );
};


export default CentersListItem