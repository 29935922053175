import styles from '../../popups/UsersPopups/UserModal.module.css'
import generalModalStyles from '../../../styles/GeneralModal.module.css'
import generalStyles from '../../../styles/General.module.css'
import { motion, AnimatePresence } from "framer-motion"
import strings from '../../../assets/strings'
import { useState, useRef } from 'react'
import moment from 'moment'
import { COLORS } from '../../../constants/constants'


const initialState = { idNumber: '',
                       firstName: '', 
                       lastName: '', 
                       birthDate: '', 
                       currentStatus: '',
                       dischargeDate: '', 
                       email: '',
                       phone: '', 
                       isCombat: false, 
                       isLonely: false, 
                       isOfficer: false, 
                       isStudy: false, 
                       other: false, 
                       notes: '', 
                       keywords: [] }


const NewUserModal = (props) => {

    const [newUser, setNewUser] = useState(initialState)
    const [illegalIDNumberError, setIllegalIDNumberError] = useState(false)
    const [noPhoneNumberError, setNoPhoneNumberError] = useState(false)
    const [illegalEmailError, setIllegalEmailError] = useState(false)
    const notesInputRef = useRef()
    const firstNameInputRef = useRef()
    const lastNameInputRef = useRef()
    const phoneNumberInputRef = useRef()
    const emailInputRef = useRef()
    const birthdayDateInputRef = useRef()
    const endArmyDateInputRef = useRef()
    const currentStatusInputRef = useRef()
    const idNumberInputRef = useRef()



    const keywordIsSelected = (keyword) => {
        return newUser.keywords.includes(keyword)
    }


    const addKeyword = (keyword) => {
        if (keywordIsSelected(keyword)){
            const temp = newUser.keywords.filter(item => item !== keyword)
            setNewUser({...newUser, keywords: temp})
        }else{
            const temp = [...newUser.keywords, keyword]
            setNewUser({...newUser, keywords: temp})
        }
    }


    const setFirstName = () => {
        const firstName = firstNameInputRef.current.value
        setNewUser({...newUser, firstName: firstName })
    }


    const setIdNumber = () => {
        setIllegalIDNumberError(false)
        const idNumber = idNumberInputRef.current.value
        setNewUser({...newUser, idNumber: idNumber })
    }


    const setLastName = () => {
        const lastName = lastNameInputRef.current.value
        setNewUser({...newUser, lastName: lastName})
    }


    const setEmail = () => {
        setIllegalEmailError(false)
        const email = emailInputRef.current.value
        setNewUser({...newUser, email: email})
    }


    const setPhoneNumber = () => {
        setNoPhoneNumberError(false)
        const phoneNumber = phoneNumberInputRef.current.value
        setNewUser({...newUser, phone: phoneNumber})
    }


    const setNotes = () => {
        const notes = notesInputRef.current.value
        setNewUser({...newUser, notes: notes})
    }


    const setCurrentStatus = () => {
        const currentStatus = currentStatusInputRef.current.value
        setNewUser({...newUser, currentStatus: currentStatus})
    }


    const setBirthdayDate = () => {
        const birthdayDate = birthdayDateInputRef.current.value
        const birthDate = moment(birthdayDate, 'YYYY-MM-DD').format('DD/MM/YYYY')
        setNewUser({...newUser, birthDate: birthDate})
    }


    const getUserBirthdayDate = () => {
        const temp = moment(newUser.birthDate, "DD/MM/YYYY")
        return temp.format("YYYY-MM-DD")
    }


    const setEndArmyDate = () => {
        const endArmyDate = endArmyDateInputRef.current.value
        const endArmy = moment(endArmyDate, 'YYYY-MM-DD').format('DD/MM/YYYY')
        setNewUser({...newUser, dischargeDate: endArmy})
    }


    const getEndArmyDate = () => {
        const temp = moment(newUser.dischargeDate, "DD/MM/YYYY")
        return temp.format("YYYY-MM-DD")
    }


    const renderKeywordsAndSentences = () => {
        return (
          props.center.centerKeywords.map((centerKeyword) => {
            return (
              <p style={{marginRight: 10}}>
                <text className={generalStyles.text20}>{centerKeyword[0]}{' '}</text>
                  <text style={{textDecoration: 'underline'}} className={generalStyles.text20}>{centerKeyword[1]}</text>{' '}
                  <text className={generalStyles.text20}>{centerKeyword[2]}</text>
                  <input type="checkbox" value={"FILTERS.ALL"} name="filters" checked={keywordIsSelected(centerKeyword[1])} onChange={() => addKeyword(centerKeyword[1])} />
              </p>
            )
          })
        )
    }


    const closeEventHandler = () => {
        setNewUser(initialState)
        props.closeEvent()
    }


    const saveUserHandler = () => {
        let isPhoneNum = /^\d+$/.test(newUser.phone)
        let isIDNumberNum = /^\d+$/.test(newUser.idNumber)
        var errorExist = false
        
        if (!isPhoneNum || (newUser.phone.length != 9 && newUser.phone.length != 10)) {
            errorExist = true
            setNoPhoneNumberError(true)
        }
       
        if (newUser.idNumber != "") {
            if (!isIDNumberNum) {
                errorExist = true
                setIllegalIDNumberError(true)
            }
            if (newUser.idNumber.length != 9) {
                errorExist = true
                setIllegalIDNumberError(true)
            }
        }
    
        if (newUser.email != "" && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(newUser.email)){
            errorExist = true
            setIllegalEmailError(true)
        }

        if (!errorExist){
            props.saveNewUser(newUser)
            setNewUser(initialState)
        }
    }


    return(
        <AnimatePresence>
            {props.isOpen && (
            <>
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: 0.3 } }} exit={{ opacity: 0 }} onClick={() => props.closeEvent()} className={generalModalStyles.backViewStyle} />
                    <motion.div initial={{ scale: 0 }} animate={{ scale: 1, transition: { delay: 0.3, duration: 0.3 } }} exit={{ scale: 0 }} className={styles.backViewWrapperStyle}>
                        <motion.div className={styles.mainViewStyle}>
                            
                            
                            <div className={styles.mainSectionBackStyle}>

                                <div className={styles.headerStyle}>
                                    <text className={generalModalStyles.headerTextStyle}>{strings.NEW_USER_POPUP_TITLE}</text>
                                </div>

                                <div className={styles.mainSectionStyle}>
                                    <input
                                        type='email'
                                        style={illegalEmailError ? { backgroundColor: COLORS.RED20 } : { backgroundColor: COLORS.WHITE }}
                                        placeholder={strings.NEW_USER_EMAIL_PLACEHOLDER} 
                                        value={newUser.email} 
                                        className={styles.inputStyle} 
                                        onChange={() => setEmail()} 
                                        ref={emailInputRef}
                                    />

                                    <input
                                        type='text'
                                        maxLength={10}
                                        style={noPhoneNumberError ? { backgroundColor: COLORS.RED20 } : { backgroundColor: COLORS.WHITE }}
                                        placeholder={strings.NEW_USER_PHONE_NUMBER_PLACEHOLDER} 
                                        value={newUser.phone} 
                                        className={styles.inputStyle} 
                                        onChange={() => setPhoneNumber()} 
                                        ref={phoneNumberInputRef}
                                    />

                                    <input 
                                        placeholder={strings.NEW_USER_LAST_NAME_PLACEHOLDER}
                                        className={styles.inputStyle} 
                                        value={newUser.lastName} 
                                        onChange={() => setLastName()} 
                                        ref={lastNameInputRef} 
                                        type='text'
                                    />
                                    
                                    <input 
                                        placeholder={strings.NEW_USER_FIRST_NAME_PLACEHOLDER} 
                                        className={styles.inputStyle} 
                                        onChange={() => setFirstName()} 
                                        value={newUser.firstName} 
                                        ref={firstNameInputRef} 
                                        type='text'
                                    />

                                    <input 
                                        placeholder={strings.NEW_USER_ID_PLACEHOLDER} 
                                        className={styles.inputStyle}
                                        maxLength={9}
                                        style={illegalIDNumberError ? { backgroundColor: COLORS.RED20 } : { backgroundColor: COLORS.WHITE }}
                                        onChange={() => setIdNumber()} 
                                        value={newUser.idNumber} 
                                        ref={idNumberInputRef} 
                                        type='text'
                                    />
                                </div>

                                <div className={styles.mainSectionStyle}>
                                    <input 
                                        className={styles.inputStyle} 
                                        value={getEndArmyDate()} 
                                        onChange={() => setEndArmyDate()} 
                                        ref={endArmyDateInputRef} 
                                        type='date'
                                    />
                                    <text 
                                        className={`${generalStyles.text16}`} 
                                        style={{justifyContent: 'center', alignItems: 'center', textAlign: 'center', alignSelf: 'center'}}>
                                        {strings.NEW_USER_DISCHARGE_DATE_TITLE}
                                    </text>

                                    <input 
                                        className={styles.inputStyle} 
                                        value={getUserBirthdayDate()} 
                                        onChange={() => setBirthdayDate()} 
                                        ref={birthdayDateInputRef} 
                                        type='date'
                                    />
                                    <text
                                        className={`${generalStyles.text16}`} 
                                        style={{justifyContent: 'center', alignItems: 'center', textAlign: 'center', alignSelf: 'center'}}>
                                        {strings.NEW_USER_BIRTHDAY_DATE_TITLE}
                                    </text>
                               
                                    <select value={newUser.currentStatus} style={{height: '40px'}} className={styles.inputStyle} onChange={() => setCurrentStatus()} ref={currentStatusInputRef} placeholder={strings.NEW_USER_OPTION_DEFAULT_INPUT_TEXT}>
                                        <option value={strings.NEW_USER_OPTION_1_INPUT_TEXT}>{strings.NEW_USER_OPTION_1_INPUT_TEXT}</option>
                                        <option value={strings.NEW_USER_OPTION_2_INPUT_TEXT}>{strings.NEW_USER_OPTION_2_INPUT_TEXT}</option>
                                        <option value={strings.NEW_USER_OPTION_3_INPUT_TEXT}>{strings.NEW_USER_OPTION_3_INPUT_TEXT}</option>
                                        <option value={strings.NEW_USER_OPTION_4_INPUT_TEXT}>{strings.NEW_USER_OPTION_4_INPUT_TEXT}</option>
                                    </select>
                                    <text className={`${generalStyles.text16}`} style={{justifyContent: 'center', alignItems: 'center', textAlign: 'center', alignSelf: 'center'}}>{strings.NEW_USER_OPTION_DEFAULT_INPUT_TEXT}</text>
                                </div>

                                <div className={styles.mainSectionStyle} style={{flexDirection: 'row', minHeight: '50px'}}>
                                    <div style={{marginRight: '10px', marginLeft: '10px', width: '20%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row'}}>
                                        <text className={`${generalStyles.text16}`} style={{margin: '5px'}}>{strings.NEW_USER_LONELY_INPUT_TEXT}</text>
                                        <input type="checkbox" value={"FILTERS.ALL"} name="filters" checked={newUser.isLonely} onChange={() => setNewUser({...newUser, isLonely: !newUser.isLonely})} />
                                    </div>
                                    
                                    <div style={{marginRight: '10px', marginLeft: '10px', width: '20%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row'}}>
                                        <text className={`${generalStyles.text16}`} style={{margin: '5px'}}>{strings.NEW_USER_COMBAT_INPUT_TEXT}</text>
                                        <input type="checkbox" value={"FILTERS.ALL"} name="filters" checked={newUser.isCombat} onChange={() => setNewUser({...newUser, isCombat: !newUser.isCombat})} />
                                    </div>

                                    <div style={{marginRight: '10px', marginLeft: '10px', width: '20%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row'}}>
                                        <text className={`${generalStyles.text16}`} style={{margin: '5px'}}>{strings.NEW_USER_OFFICER_INPUT_TEXT}</text>
                                        <input type="checkbox" value={"FILTERS.ALL"} name="filters" checked={newUser.isOfficer} onChange={() => setNewUser({...newUser, isOfficer: !newUser.isOfficer})} />
                                    </div>

                                    <div style={{marginRight: '10px', marginLeft: '10px', width: '20%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row'}}>
                                        <text className={`${generalStyles.text16}`} style={{margin: '5px'}}>{strings.NEW_USER_ACADEMY_INPUT_TEXT}</text>
                                        <input type="checkbox" value={"FILTERS.ALL"} name="filters" checked={newUser.isStudy} onChange={() => setNewUser({...newUser, isStudy: !newUser.isStudy})} />
                                    </div>

                                    <div style={{marginRight: '10px', marginLeft: '10px', width: '20%', height: '100%', height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row'}}>
                                        <text className={`${generalStyles.text16}`} style={{margin: '5px'}}>{strings.NEW_USER_OTHER_INPUT_TEXT}</text>
                                        <input type="checkbox" value={"FILTERS.ALL"} name="filters" checked={newUser.other} onChange={() => setNewUser({...newUser, other: !newUser.other})} />
                                    </div>
                                </div>

                                <div className={styles.mainSectionStyle}>
                                    <div style={{width: '100%', height: '220px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row'}}>
                                        <textarea placeholder={strings.NEW_USER_NOTES_INPUT_TEXT} value={newUser.notes} className={styles.controlStyle} onChange={() => setNotes()} ref={notesInputRef} type="input" id='search'/>
                                    </div>
                                </div>

                                <div className={styles.mainSectionStyle} style={{flexDirection: 'column'}}>
                                    {renderKeywordsAndSentences()}
                                </div>

                            </div>

                            <div className={styles.footerViewStyle}>
                                <button className={styles.leftSectionBackStyle_footerViewStyle_buttonStyle} onClick={() => closeEventHandler()}>{strings.CLOSE_BUTTON}</button>
                                <button className={styles.leftSectionBackStyle_footerViewStyle_buttonStyle} onClick={() => saveUserHandler()}>{strings.NEW_USER_INPUT_CREATE_BUTTON}</button>
                            </div>

                        </motion.div>
                    </motion.div>
                </>
            )}
        </AnimatePresence>
    )
}


export default NewUserModal