import React from 'react'
import styles from './SuperAdminUsersPage.module.css'
import generalStyles from '../../styles/General.module.css'
import { useState, useRef, useEffect } from 'react'
import UsersList from '../../components/lists/UsersList'
import strings from '../../assets/strings'
import RingLoader from "react-spinners/RingLoader"
import SystemMessage from '../../components/system_messages/SystemMessage'
import { COLORS } from '../../constants/constants'
import moment from 'moment'
import FilterPopupsSuperAdmin from '../../components/popups/Filters/FilterPopupsSuperAdmin'
import { useSelector } from 'react-redux'



const SuperAdminUsersPage = () => {

  const users = useSelector((state) => state.users.users)
  const [selectedUser, setSelectedUser] = useState(null)
  const [filterPopupVisible, setFilterPopupVisible] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [presentedUsers, setPresentedUsers] = useState([])
  const [activeFiltersIsActive, setActiveFiltersIsActive] = useState(0)
  const searchInputRef = useRef()
  const [loader, setLoader] = useState(false)


  useEffect(() => {
    setPresentedUsers(users)
  }, [])


  useEffect(() => {
    var tempList = users
    tempList = tempList.filter(user => user.name !== undefined && user.name.includes(searchText))
    setPresentedUsers(tempList)
  }, [searchText])



  const filterActionHandler = (presentation, isLonely, isCombat, isOfficer, isStudy, isOther, birthdayYear, dischargedYear, currentStatus) => {
    // var tempList = users
    // var tempFilterIsActive = false

    // if (searchText !== null){
    //   tempList = tempList.filter(user => user.name !== undefined && user.name.includes(searchText))
    // }
    // if (presentation === "ANDROID"){
    //   tempFilterIsActive = true
    //   tempList = tempList.filter(user => user.platform === "Android")
    // }
    // if (presentation === "IOS"){
    //   tempFilterIsActive = true
    //   tempList = tempList.filter(user => user.platform === "iOS")
    // }
    // if (presentation === "UNKNOWN"){
    //   tempFilterIsActive = true
    //   tempList = tempList.filter(user => user.platform === null)
    // }
    // if (isLonely === true){
    //   tempFilterIsActive = true
    //   tempList = tempList.filter(user => user.isLonely === true)
    // }
    // if (isCombat === true){
    //   tempFilterIsActive = true
    //   tempList = tempList.filter(user => user.isCombat === true)
    // }
    // if (isOfficer === true){
    //   tempFilterIsActive = true
    //   tempList = tempList.filter(user => user.isOfficer === true)
    // }
    // if (isStudy === true){
    //   tempFilterIsActive = true
    //   tempList = tempList.filter(user => user.isStudy === true)
    // }
    // if (isOther === true){
    //   tempFilterIsActive = true
    //   tempList = tempList.filter(user => user.isOther === true)
    // }
    // if (birthdayYear !== null){
    //   tempFilterIsActive = true
    //   tempList = tempList.filter((user) => {
    //     const year = moment(user.birthDate, "DD/MM/YYYY").format("YYYY")
    //     return year == birthdayYear
    //   })
    // }
    // if (dischargedYear !== null){
    //   tempFilterIsActive = true
    //   tempList = tempList.filter((user) => {
    //     const year = moment(user.dischargeDate, "DD/MM/YYYY").format("YYYY")
    //     return year == dischargedYear
    //   })
    // }
    // if (currentStatus !== null){
    //   tempFilterIsActive = true
    //   tempList = tempList.filter((user) => user.currentStatus === currentStatus)
    // }
    // setActiveFiltersIsActive(tempFilterIsActive)
    // setPresentedUsers(tempList)
  }


  const cleanFiltersActionHandler = () => {
    setPresentedUsers(users)
    setActiveFiltersIsActive(false)
    setFilterPopupVisible(false)
  }


  const renderSearchSection = () => {
    return (
      <div className={`${generalStyles.blueViewWithShadowStyle} ${styles.searchBackViewStyle}`}>
          <text className={`${generalStyles.text16}`} style={{ color: activeFiltersIsActive ? COLORS.RED : COLORS.BLUE, width: 50, margin: 5, cursor: 'pointer', textDecoration: 'underline' }} onClick={() => setFilterPopupVisible(true)}>{strings.FILTER_BUTTON}</text>
          <input placeholder={strings.SEARCH_PLACEHOLDER} className={styles.controlStyle} onChange={() => setSearchText(searchInputRef.current.value)} ref={searchInputRef} type='text' id='search'/>
          <div className={styles.totalDataBackViewStyle}>
            <text className={`${generalStyles.text20}`}>{presentedUsers.length}</text>
          </div>
      </div>
    )
  }


  const renderUserListSection = () => {
    return (
      <div className={`${generalStyles.blueViewWithShadowStyle} ${styles.usersListBackViewStyle}`}>
        <UsersList showFullData={true} items={presentedUsers} selectedUser={selectedUser} onItemClick={(selectedUser) => setSelectedUser(selectedUser)} />
      </div>
    )
  }


  return (
    <div className={generalStyles.mainBackViewStyle}>
      <div className={styles.mainBackViewLeftSide}>
          {renderSearchSection()}
          {renderUserListSection()}
      </div>

      <div className={generalStyles.loaderBackView}>
          {loader && <RingLoader color="#176ED0" />}
      </div>

      <SystemMessage />

      <FilterPopupsSuperAdmin
        isOpen={filterPopupVisible}
        filterAction={(presentation, isLonely, isCombat, isOfficer, isStudy, isOther, birthdayYear, dischargedYear, currentStatus) => filterActionHandler(presentation, isLonely, isCombat, isOfficer, isStudy, isOther, birthdayYear, dischargedYear, currentStatus)}
        closeEventAction={() => setFilterPopupVisible(false)}
        cleanFiltersAction={() => cleanFiltersActionHandler()}
      />
    </div>
  )
}


export default SuperAdminUsersPage
