import React from 'react'
import UsersListItem from '../items/UsersListItem'
import generalStyles from '../../styles/General.module.css'
import FullUsersListItem from '../items/FullUsersListItem'


const UsersList = (props) => {

  
  const renderUserItem = (item) => {
    if (props.showFullData){
      return (
        <FullUsersListItem
            isSelected={props.selectedUser !== null ? props.selectedUser.phone === item.phone : false}
            approved={item.approved}
            key={item.phone} 
            id={item.phone}
            item={item}
            onItemClick={() => props.onItemClick(item)} />
      )
    }else{
      return (
        <UsersListItem
            isSelected={props.selectedUser !== null ? props.selectedUser.phone === item.phone : false}
            approved={item.approved}
            key={item.phone} 
            id={item.phone}
            onItemClick={() => props.onItemClick(item)}>
            {item.name}
        </UsersListItem>
      )
    }
  }

 
  return (
    <div className={generalStyles.listStyle}>
      {props.items.map(item => (
        renderUserItem(item)
      ))}
    </div>
  )
}


export default UsersList