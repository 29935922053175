import React from 'react'
import styles from './CalendarDatePicker.module.css'
import { ARROW_LEFT, ARROW_RIGHT } from '../../assets/images'
import moment from 'moment'
import strings from '../../assets/strings'


const CalendarDatePicker = (props) => {

    const getMonthAndYearString = () => {
        let englishDate = moment(props.presentedDatesRange.from).format('MMMM')
        if (englishDate.includes('January')) { 
          return strings.MONTH_JAN + " " + moment(props.presentedDatesRange.from).format("YYYY")
        }else if (englishDate.includes('February')) { 
          return strings.MONTH_FEB + " " + moment(props.presentedDatesRange.from).format("YYYY")
        }else if (englishDate.includes('March')) { 
          return strings.MONTH_MAR + " " + moment(props.presentedDatesRange.from).format("YYYY")
        }else if (englishDate.includes('April')) { 
          return strings.MONTH_APR + " " + moment(props.presentedDatesRange.from).format("YYYY")
        }else if (englishDate.includes('May')) { 
          return strings.MONTH_MAY + " " + moment(props.presentedDatesRange.from).format("YYYY")
        }else if (englishDate.includes('June')) { 
          return strings.MONTH_JUN + " " + moment(props.presentedDatesRange.from).format("YYYY")
        }else if (englishDate.includes('July')) { 
          return strings.MONTH_JUL + " " + moment(props.presentedDatesRange.from).format("YYYY")
        }else if (englishDate.includes('August')) { 
          return strings.MONTH_AUG + " " + moment(props.presentedDatesRange.from).format("YYYY")
        }else if (englishDate.includes('September')) { 
          return strings.MONTH_SEP + " " + moment(props.presentedDatesRange.from).format("YYYY")
        }else if (englishDate.includes('October')) { 
          return strings.MONTH_OCT + " " + moment(props.presentedDatesRange.from).format("YYYY")
        }else if (englishDate.includes('November')) { 
          return strings.MONTH_NOV + " " + moment(props.presentedDatesRange.from).format("YYYY")
        }else if (englishDate.includes('December')) { 
          return strings.MONTH_DEC + " " + moment(props.presentedDatesRange.from).format("YYYY")
        }
    }


    const forwardCalendarHandler = () => {
        props.dateChanged({
            from: moment(props.presentedDatesRange.from).add(1, 'M').startOf('month'), 
            to: moment(props.presentedDatesRange.to).add(1, 'M').endOf('month')
        });
    }


    const backCalendarHandler = () => {
        props.dateChanged({
            from: moment(props.presentedDatesRange.from).add(-1, 'M').startOf('month'), 
            to: moment(props.presentedDatesRange.to).add(-1, 'M').endOf('month')
        });
    }

    
    return (
        <div className={styles.backStyle}>
            <button className={styles.navigationButtonStyle} onClick={() => backCalendarHandler()}><img className={styles.arrowImageStyle} src={ARROW_LEFT} /></button>
            <text className={styles.dateTextStyle}>{getMonthAndYearString()}</text>
            <button className={styles.navigationButtonStyle} onClick={() => forwardCalendarHandler()}><img className={styles.arrowImageStyle} src={ARROW_RIGHT} /></button>
        </div>
    )
}


export default CalendarDatePicker
