import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'


const SystemMessage = () => {

    return (
        <ToastContainer
            position="top-right"
            hideProgressBar
            limit={10}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
        />
    )
  }
  
  
  
  export default SystemMessage