import { getDatabase, ref, child, get, set } from "firebase/database"


const USERS = "user/"
const CENTERS = "centers/"
const CENTER_MESSAGES = "centerMessages/"


export async function fetchAllAppUsersAPI(){
    const dbRef = ref(getDatabase())
    return get(child(dbRef, USERS)).then((snapshot) => {
        if (snapshot.exists()) {
                   
          var tempAllUsersList = []
          snapshot.forEach((jobSnapshot) => {
            var obj = jobSnapshot.val()
  
            const tempItem = { name: obj.name,
                               phone: jobSnapshot.key,
                               birthDate: obj.birthDate,
                               currentStatus: obj.currentStatus,
                               dischargeDate: obj.dischargeDate,
                               isLonely: obj.isLonely,
                               isCombat: obj.isCombat,
                               isOfficer: obj.isOfficer,
                               isStudy: obj.isStudy,
                               other: obj.other,
                               email: obj.email, 
                               city: obj.city,
                               platform: obj.platform,
                               version: obj.version,
                               approved: true }
  
            tempAllUsersList = tempAllUsersList.concat(tempItem)
          })
          return { data: tempAllUsersList, error: null }
        }
        }).catch((error) => {
    
            return { data: null, error: error }
        })
}



export async function fetchAllCentersAPI(){
  const dbRef = ref(getDatabase())
  return get(child(dbRef, CENTERS)).then((snapshot) => {
      if (snapshot.exists()) {
                 
        var tempAllCentersList = []
        snapshot.forEach((jobSnapshot) => {

          var obj = jobSnapshot.val()
          const tempItem = { centerKey: jobSnapshot.key,
                             centerCity: obj.centerCity,
                             centerDefaultMessage: obj.centerDefaultMessage,
                             centerKeywords: obj.centerKeywords,
                             centerLogoUrl: obj.centerLogoUrl,
                             centerManagers: obj.centerManagers,
                             centerName: obj.centerName }

          tempAllCentersList = tempAllCentersList.concat(tempItem)
          
        })
        return { data: tempAllCentersList, error: null }
      }
      }).catch((error) => {
  
          return { data: null, error: error }
      })
}



export async function fetchAdminMessagesAPI(){
  const dbRef = ref(getDatabase())
  return get(child(dbRef, CENTER_MESSAGES + "ADMIN/"))
    .then((snapshot) => {
      if (snapshot.exists()) {

        var tempList = []
        snapshot.forEach((jobSnapshot) => {
          var obj = jobSnapshot.val()
          const tempItem = { messageStatus: obj.messageStatus,
                             messageDate: jobSnapshot.key,
                             messageText: obj.messageText,
                             messageUsersCounter: obj.users }

          tempList = tempList.concat(tempItem)
        })
        return { data: tempList, error: null }
      }
    
    }).catch((error) => {
        return { data: null, error: error }
    }
  )
}