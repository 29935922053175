import React from 'react'
import styles from './UsersListItem.module.css'
import generalStyle from '../../styles/General.module.css'


const UsersListItem = props => {
  
    return (        
        <div className={`${generalStyle.whiteViewWithShadowStyle} ${styles.sectionItemStyle}`} style={props.isSelected ? { background: '#C2DAEE' } : { background: '#FFFFFF' }} onClick={() => props.onItemClick()}>
            
            <div className={styles.textBackStyle}>
                <text className={`${generalStyle.text20} ${styles.textStyle}`}>{props.children}</text>
            </div>

            <div className={`${styles.userStatusViewStyle} ${props.approved ? styles.userStatusApprovedStyle : styles.userStatusNotApprovedStyle}`}>

            </div>
        </div>
  );
};


export default UsersListItem