import generalStyles from '../../styles/General.module.css'
import styles from './SettingsPage.module.css'
import { sendPasswordResetAPI } from '../../managers/ServerAccountManager'
import { useState, useRef } from 'react'
import RingLoader from "react-spinners/RingLoader"
import strings from '../../assets/strings'
import { COLORS, DEFAULT_MESSAGE_DOWNLOAD_LINK } from '../../constants/constants'
import SureModal from '../../components/popups/GeneralPopups/SureModal'
import { showErrorMessage, showSuccessMessage } from '../SystemMessagesManager'
import { useSelector } from 'react-redux'
import { BURD_LOGO } from '../../assets/images'


const SettingsPage = (props) => {

  const center = useSelector((state) => state.center.center)
  const [loader, setLoader] = useState(false)
  const [sureResetPasswordPopup, setSureResetPasswordPopup] = useState(false)
  const emailInputRef = useRef()



  const resetPasswordEventHandler = async () => {
    if (props.auth){
      setLoader(true)
      const response = await sendPasswordResetAPI(props.auth, center.centerUserEmail)
      setLoader(false)

      if (response.data !== null){
        setSureResetPasswordPopup(false)
        showSuccessMessage(response.data)
      }else{
        showErrorMessage(response.error)
      }
    }else{
      setSureResetPasswordPopup(false)
    }
  }


  const createMessage = () => {
    var text = `${center.centerDefaultMessage}`
    text = text + strings.DEFAULT_DOWNLOAD_LINK_TEXT + DEFAULT_MESSAGE_DOWNLOAD_LINK
    return text
  }


  const renderKeywordsAndSentences = () => {
    return (
      center.centerKeywords.map((centerKeyword) => {
        return (
          <p>
            <text className={generalStyles.text20}>{centerKeyword[0]}{' '}</text>
              <text style={{textDecoration: 'underline'}} className={generalStyles.text20}>{centerKeyword[1]}</text>{' '}
              <text className={generalStyles.text20}>{centerKeyword[2]}</text>
          </p>
        )
      })
    )
  }


  return (
    <div className={styles.mainBackViewStyle}>
        <div className={`${generalStyles.blueViewWithShadowStyle} ${styles.sectionViewSide}`}>

              <tr>
                <img className={styles.logoImageStyle} src={center.centerLogoUrl} alt="Logo" />
              </tr>

              <tr style={{maxWidth: '50%'}}>
                <th style={{padding: 20 }}>
                  <input className={styles.controlStyle} disabled value={center.centerUserEmail} style={{  height: 40, width: 200, textAlign: 'right' }} ref={emailInputRef}/>
                </th>
                <th style={{width: 150}}>
                  <text className={generalStyles.text20}>{strings.SETTINGS_EMAIL}</text>
                </th>
              </tr>

              <tr>
                <th style={{padding: 20 }}>
                  <input className={styles.controlStyle} disabled value={center.centerCity} style={{  height: 40, width: 200, textAlign: 'right' }} ref={emailInputRef}/>
                </th>
                <th style={{width: 150}}>
                  <text className={generalStyles.text20}>{strings.SETTINGS_CITY}</text>
                </th>
              </tr>

              <tr>
                <th style={{padding: 20 }}>
                  <input className={styles.controlStyle} disabled value={center.centerName} style={{ height: 40, width: 200, textAlign: 'right' }} ref={emailInputRef}/>
                </th>
                <th style={{width: 150}}>
                  <text className={generalStyles.text20}>{strings.SETTINGS_NAME}</text>
                </th>
              </tr>

              <tr>
                <th style={{padding: 20 }}>
                  <textarea disabled value={createMessage()} style={{ width: 200, textAlign: 'right', backgroundColor: COLORS.WHITE }} placeholder={strings.SETTINGS_DEFAULT_MESSAGE} className={styles.defaultMessageControlStyle} type="textarea" />
                </th>
                <th style={{width: 150}}>
                  <text className={generalStyles.text20}>{strings.SETTINGS_DEFAULT_MESSAGE}</text>
                </th>
              </tr>

              <tr>
                <th style={{padding: 20 }}>
                  {renderKeywordsAndSentences()}
                </th>
                <th style={{width: 150}}>
                  <text className={generalStyles.text20}>{strings.SETTINGS_KEYWORDS_MESSAGE}</text>
                </th>
              </tr>
        </div>

        <div className={styles.poweredByStyle}>
            <text className={styles.poweredByTextStyle}>Designed & developed by </text>
            <img className={styles.poweredByIconStyle} src={BURD_LOGO} alt="Logo" />
        </div>

        <SureModal
          isOpen={sureResetPasswordPopup}
          yesActionEvent = {() => resetPasswordEventHandler()}
          noActionEvent = {() => setSureResetPasswordPopup(false)}
        />

        <div className={generalStyles.loaderBackView}>
            {loader && <RingLoader color="#176ED0" />}
        </div>
    </div>
  )
}



export default SettingsPage