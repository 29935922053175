import React from 'react'
import styles from './CalendarCell.module.css'
import generalStyle from '../../styles/General.module.css'
import moment from 'moment'


const CalendarCell = (props) => {

    const renderEvents = () => {
        if (props.events !== undefined){
            return props.events.map((event, index) => {
                if (index < 4){
                    return(
                        <div className={styles.eventViewStyle}>
                            <text className={styles.eventTextStyle}>{event.eventTitle}</text>
                        </div>
                    )
                }
            })
        }
    }


    const renderDay = () => {
        if (props.selectedDate !== null){
            var selectedDate = moment(props.selectedDate, 'DD/MM/YYYY').format('DD/MM/YYYY')
            var date = moment(props.date, 'DD/MM/YYYY').format('DD/MM/YYYY')
            if (selectedDate === date){
                return (
                    <text className={`${styles.selectedDateTextStyle} ${generalStyle.text20}`}>{props.day}</text>
                )
            }
        }
        return (
            <text className={`${styles.dateTextStyle} ${generalStyle.text20}`}>{props.day}</text>
        )
    }


    return (
        <div className={props.isToday ? styles.backTodayStyle : styles.backStyle} style={props.styles} onClick={() => props.onClick(props.date, props.events)}>
            {renderDay()}
            {renderEvents()}
        </div>
    )
}


export default CalendarCell
