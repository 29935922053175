import React from 'react'
import styles from './SectionsListItem.module.css'
import generalStyles from '../../styles/General.module.css'
import { SECTIONS_ITEMS, COLORS } from '../../constants/constants'


const SectionListItem = props => {
  
    const getBackgroundColor = () => {
        if (props.isSelected){
            if (props.selectedSection == SECTIONS_ITEMS.WORK){
                return { background: `${COLORS.JOBS}30` }
            }
            if (props.selectedSection == SECTIONS_ITEMS.STUDY){
                return { background: `${COLORS.STUDY}30` }
            }
            if (props.selectedSection == SECTIONS_ITEMS.RIGHTS){
                return { background: `${COLORS.RIGHTS}30` }
            }
            if (props.selectedSection == SECTIONS_ITEMS.MORE){
                return { background: `${COLORS.MORE}30` }
            }
        }
        return { background: `#FFFFFF` }
    }

    
    return (
        <li className={`${generalStyles.whiteViewWithShadowStyle} ${styles.sectionItem} ${generalStyles.text20}`} style={getBackgroundColor()} onClick={() => props.onItemClick()}>
            {props.children}
        </li>
  );
};


export default SectionListItem